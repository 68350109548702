import * as types from './types';

export const openModal = (value) => (dispatch) => (event) => {
  if (value) {
    return dispatch({ type: types.OPEN_MODAL, payload: value });
  }
  return dispatch({ type: types.OPEN_MODAL });
};

export const closeModal = () => (dispatch) => {
  return dispatch({ type: types.CLOSE_MODAL });
};

export const setLocale = (value) => (dispatch) => {
  dispatch({ type: types.SET_LOCALE, payload: value });
};
