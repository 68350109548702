import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCreatingCoupon: false,
  isDeletingCoupon: false,
  editCoupon: {},
};

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setIsCreatingCoupon: (state, { payload: dialogOpen = false }) => {
      state.isCreatingCoupon = dialogOpen;
    },
    setIsDeletingCoupon: (state, { payload: dialogOpen = false }) => {
      state.isDeletingCoupon = dialogOpen;
    },
    setEditCoupon: (state, { payload: coupon = {} }) => {
      state.editCoupon = coupon;
    },
  },
});

export const { setIsCreatingCoupon, setEditCoupon, setIsDeletingCoupon } =
  couponsSlice.actions;

export default couponsSlice.reducer;

export const selectIsCreatingCoupon = (state) => state.coupons.isCreatingCoupon;
export const selectIsDeletingCoupon = (state) => state.coupons.isDeletingCoupon;
export const selectEditCoupon = (state) => state.coupons.editCoupon;
