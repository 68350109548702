import { createSlice } from '@reduxjs/toolkit';

const initialState = { currentTab: 0, openInviteCodeDialog: false };

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    changeTabs: (state, { payload: { currentTab } }) => {
      state.currentTab = currentTab;
    },
    toggleOpenInviteCodeDialog: (state, { payload = false }) => {
      if (typeof payload === 'boolean') {
        state.openInviteCodeDialog = payload;
      } else {
        state.openInviteCodeDialog = !state.openInviteCodeDialog;
      }
    },
    selectPlaylistModule: (state, { payload: module }) => {
      state.selectedModule = module;
    },
  },
});

export const { actions } = studentSlice;
export const { changeTabs, selectPlaylistModule } = studentSlice.actions;

export default studentSlice.reducer;
