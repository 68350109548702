import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import api from 'app/api';
import useAuth from 'hooks/useAuth';
import { actions, selectPurchase } from 'features/courses/slice';
import CheckoutForm from './CheckoutForm';
import Success from './Success';

// ToDo: we need to make a generic component for Purchases a course
const stripePromise = loadStripe(
  process.env.REACT_APP_ENV === 'production'
    ? 'pk_live_DTWcAVXFBWI2dkHPuBp9ZXkQ'
    : 'pk_test_95A0CRrFKFNT7VhOYJEWHEUA'
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const shortCode = 'SASFO';
const courseName = 'Scrum At Scale Fundamentals';

export default function PurchaseRSASF() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultSeats = parseInt(params.get('seats'), 10) || 1;
  const companyId = params.get('companyId');

  const theme = useTheme();
  const dispatch = useDispatch();
  const { clientSecret, paymentIntentId, success } =
    useSelector(selectPurchase);
  const { user } = useAuth();

  const [createPaymentIntent, { isLoading: creatingPaymentIntent }] =
    api.useCreateCoursePaymentIntentMutation();
  const [cancelPaymentIntent] = api.useCancelCoursePaymentIntentMutation();
  const [updatePaymentIntent] = api.useUpdateCoursePaymentIntentMutation();

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: theme.palette.secondary.main,
        colorText: theme.palette.primary.dark,
        fontFamily: theme.typography.fontFamily,
        fontSizeBase: theme.typography.htmlFontSize,
        fontSizeSm: '12.48px',
      },
    },
  };

  React.useEffect(() => {
    async function handleCreatePaymentIntent() {
      try {
        const res = await createPaymentIntent({
          shortCode,
          seats: defaultSeats,
          companyId,
        }).unwrap();

        if (res.status === 'succeeded') {
          dispatch(actions.setPurchaseSuccess(true));
        } else {
          dispatch(actions.setPurchaseClientSecret(res.client_secret));
          dispatch(actions.setPurchasePaymentIntentId(res.id));
          dispatch(
            actions.setPurchaseAmounts({
              subtotal: res.subtotal / 100,
              discount: res.discount / 100,
              total: res.total / 100,
              seats: defaultSeats,
              basePrice: res.basePrice / 100,
            })
          );
        }

        if (res.hasCourse && !companyId) {
          toast.info('You already have access to this course.');
        }
      } catch (err) {
        Sentry.captureException(err);
        toast.error('Unable to initialize checkout. Please try again later.');
      }
    }
    if (!paymentIntentId) {
      handleCreatePaymentIntent();
    }

    return () => {
      if (paymentIntentId) {
        cancelPaymentIntent({ paymentIntentId });
        actions.resetPurchase();
      }
    };
  }, [
    createPaymentIntent,
    cancelPaymentIntent,
    dispatch,
    paymentIntentId,
    defaultSeats,
    companyId,
  ]);

  React.useEffect(() => {
    async function handleUpdatePaymentIntent() {
      const res = await updatePaymentIntent({
        shortCode,
        paymentIntentId,
        updates: {
          userId: user?._id,
          firstName: user?.first_name,
          lastName: user?.last_name,
          email: user?.username,
          seats: defaultSeats,
          companyId,
        },
      }).unwrap();
      dispatch(actions.setPurchaseClientSecret(res.client_secret));
      dispatch(actions.setPurchasePaymentIntentId(res.id));
      dispatch(
        actions.setPurchaseAmounts({
          subtotal: res.subtotal / 100,
          discount: res.discount / 100,
          total: res.total / 100,
          seats: defaultSeats,
          basePrice: res.basePrice / 100,
        })
      );
      console.log(res);
    }
    if (paymentIntentId && user) {
      handleUpdatePaymentIntent();
    }
  }, [
    user,
    paymentIntentId,
    updatePaymentIntent,
    defaultSeats,
    companyId,
    dispatch,
  ]);

  return (
    <Container fixed maxWidth="md">
      <Card>
        <CardContentStyled>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h4" gutterBottom>
                Purchase {courseName}
              </Typography>
            </Grid>
            <Grid item>
              {success ? (
                <Success />
              ) : clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    defaultSeats={defaultSeats}
                    shortCode={shortCode}
                    courseName={courseName}
                    companyId={companyId}
                  />
                </Elements>
              ) : (
                (!clientSecret || creatingPaymentIntent) && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ padding: theme.spacing(4) }}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </CardContentStyled>
      </Card>
    </Container>
  );
}
