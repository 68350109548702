import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
};

export const surveyResponsesSlice = createSlice({
  name: 'surveyResponses',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const actions = surveyResponsesSlice.actions;

export default surveyResponsesSlice.reducer;
