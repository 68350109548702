import { actions as authActions } from 'features/auth/slice';
import { actions as achievementsActions } from 'features/achievements/slice';

const authMiddleware = ({ dispatch }) => {
  return (next) => (action) => {
    if (authActions.logoutUser.match(action)) {
      // when a user logs out, make sure we empty the renewal cart
      dispatch(achievementsActions.emptyCart());
    }
    next(action);
  };
};

export default authMiddleware;
