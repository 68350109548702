import React, { useState, useEffect } from 'react';
import {
  Button,
  FormLabel,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import api from 'app/api';
import useAuth from 'hooks/useAuth';
import moneyFormatter from 'utils/moneyFormatter';
import { actions, selectPurchase } from 'features/courses/slice';
import { useDebounceFn } from 'ahooks';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(1, 'First Name must be at least 1 character')
    .required('First Name is a required field'),
  lastName: yup
    .string()
    .min(1, 'Last Name must be at least 1 character')
    .required('Last Name is a required field'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  discountCode: yup.string(),
  seats: yup
    .number()
    .min(1, 'Must be at least 1 seat')
    .required('Please select the number of seats'),
  hasCompanyAccount: yup.string().required(),
  companyName: yup.string().when(['hasCompanyAccount', 'seats'], {
    is: (hasCompanyAccount, seats) => hasCompanyAccount === 'no' && seats > 1,
    then: yup
      .string()
      .min(1, 'Company Name must be at least 1 character')
      .required('Company Name is a required field'),
    otherwise: yup.string(),
  }),
});

const StyledTextField = styled((props) => (
  <TextField {...props} variant="outlined" size="small" disableUnderline />
))(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition:
      'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
    border: '1px solid #e6e6e6',
    boxShadow:
      '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
    '&:focus': {
      outline: 0,
      borderColor: 'hsla(0, 100%, 40%, 50%)',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(0, 100%, 40%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
    },
  },
}));

export default function CheckoutForm({
  defaultSeats,
  shortCode,
  courseName,
  companyId,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation(['common', 'auth', 'courses']);

  const dispatch = useDispatch();
  const { amounts, paymentIntentId, discountDescription, processing } =
    useSelector(selectPurchase);
  const { user } = useAuth();
  const isLoggedIn = Boolean(user);
  const [contactUs, setContactUs] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const companyQuery = companyId
    ? api.useGetCompanyQuery(companyId)
    : { data: null, isFetching: false, isLoading: false };

  const { data: company, isFetching, isLoading: loadingCompany } = companyQuery;

  const [updatePaymentIntent, { isLoading: updatingPaymentIntent }] =
    api.useUpdateCoursePaymentIntentMutation();

  const [cancelPaymentIntent] = api.useCancelCoursePaymentIntentMutation();
  const {
    formState: { errors },
    control,
    watch,
    setError,
    setValue,
    handleSubmit,
    clearErrors,
    register,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      email: user?.username || '',
      hasCompanyAccount: 'no',
      discountCode: '',
      seats: defaultSeats,
      companyName: '',
      companyId,
    },
  });

  const discountCode = watch('discountCode');
  const watchSeats = watch('seats');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const hasCompanyAccount = watch('hasCompanyAccount') === 'yes';
  const [showPaymentElement, setShowPaymentElement] = useState(false);
  const [validEmail, setValidEmail] = useState(false);

  const { run: debouncedUpdatePaymentIntent } = useDebounceFn(
    async (targetName) => {
      try {
        if (targetName === 'email') {
          const valid = await trigger('email');
          if (!valid) {
            return;
          } else {
            setValidEmail(true);
          }
        }

        const values = getValues();

        if (values.seats >= 1000) {
          setContactUs(true);
          return;
        } else {
          setContactUs(false);
        }

        const res = await updatePaymentIntent({
          shortCode,
          paymentIntentId,
          updates: {
            ...values,
            userId: user?._id,
          },
        }).unwrap();

        dispatch(
          actions.setPurchaseAmounts({
            subtotal: res.subtotal / 100,
            discount: res.discount / 100,
            total: res.total / 100,
            seats: values.seats,
            basePrice: res.basePrice / 100,
          })
        );
        dispatch(
          actions.setPurchaseDiscountDescription(res.discountDescription)
        );
        setDisableSubmit(false);
      } catch (err) {
        console.log(err);
        toast.error(err.data.message);
      }
    },
    { wait: 1000 }
  );

  const updatePaymentIntentWithEmail = async () => {
    const emailValid = await trigger('email');
    if (emailValid) {
      const valid = await trigger('email');
      if (!valid) {
        return;
      } else {
        const values = getValues();

        if (values.seats >= 1000) {
          setContactUs(true);
          return;
        } else {
          setContactUs(false);
        }

        const res = await updatePaymentIntent({
          shortCode,
          paymentIntentId,
          updates: {
            ...values,
            userId: user?._id,
          },
        }).unwrap();
        dispatch(
          actions.setPurchaseAmounts({
            subtotal: res.subtotal / 100,
            discount: res.discount / 100,
            total: res.total / 100,
            seats: values.seats,
            basePrice: res.basePrice / 100,
          })
        );
        dispatch(
          actions.setPurchaseDiscountDescription(res.discountDescription)
        );
        setShowPaymentElement(true);
      }
    }
  };

  function handleTextInputChange(event, onChange) {
    onChange(event);
    debouncedUpdatePaymentIntent(event.target.name);
  }

  async function handleSeatChange(event) {
    setDisableSubmit(true);
    setValue('seats', event.target.valueAsNumber ?? 0);
    debouncedUpdatePaymentIntent(event.target.name);
  }

  async function handleApplyDiscountCodeClick(discountCode) {
    try {
      const res = await updatePaymentIntent({
        shortCode,
        paymentIntentId,
        updates: {
          userId: user?._id,
          discountCode,
          seats: watchSeats,
          companyId,
        },
      }).unwrap();

      dispatch(
        actions.setPurchaseAmounts({
          subtotal: res.subtotal / 100,
          discount: res.discount / 100,
          total: res.total / 100,
          seats: watchSeats,
          basePrice: res.basePrice / 100,
        })
      );
      dispatch(actions.setPurchaseDiscountDescription(res.discountDescription));

      clearErrors('discountCode');
    } catch (err) {
      if (err.status === 400) {
        setValue('discountCode', '');
        setError('discountCode', { type: 'custom', message: err.data.message });
      } else {
        toast.error(err.data.message);
      }
    }
  }

  async function onSubmit(data) {
    try {
      dispatch(actions.setPurchaseProcessing(true));
      const updatedPaymentIntent = await updatePaymentIntent({
        shortCode,
        paymentIntentId,
        updates: {
          userId: user?._id,
          ...data,
        },
      }).unwrap();

      dispatch(
        actions.setPurchaseAmounts({
          subtotal: updatedPaymentIntent.subtotal / 100,
          discount: updatedPaymentIntent.discount / 100,
          total: updatedPaymentIntent.total / 100,
          seats: watchSeats,
          basePrice: updatedPaymentIntent.basePrice / 100,
        })
      );
      dispatch(
        actions.setPurchaseDiscountDescription(
          updatedPaymentIntent.discountDescription
        )
      );

      if (updatedPaymentIntent.total === 0) {
        await updatePaymentIntent({
          shortCode,
          paymentIntentId,
          updates: {
            userId: user?._id,
            free: true,
            ...data,
            seats: watchSeats,
          },
        });
        cancelPaymentIntent({ paymentIntentId });
        dispatch(actions.resetPurchase());
        dispatch(actions.setPurchaseSuccess(true));
      } else {
        const { error } = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
        });

        if (error) {
          if (
            error?.type === 'card_error' ||
            error?.type === 'validation_error'
          ) {
            toast.error(error.message);
          } else {
            toast.error('An unexpected error occurred.');
          }
        } else {
          dispatch(actions.resetPurchase());
          dispatch(actions.setPurchaseSuccess(true));
        }
      }
    } catch (err) {
      if (err.status === 400 && err?.data?.message?.includes('enrolled')) {
        toast.error(
          'You are already enrolled in this course. Please contact support if you believe this is an error.'
        );
      } else {
        Sentry.captureException(err);
        toast.error('An unexpected error occurred.');
      }
    } finally {
      dispatch(actions.setPurchaseProcessing(false));
    }
  }

  useEffect(() => {
    if (hasCompanyAccount && watchSeats > 1 && discountCode.length === 0) {
      setContactUs(true);
    } else {
      setContactUs(false);
    }
  }, [hasCompanyAccount, watchSeats, discountCode]);

  useEffect(() => {
    if (company?.name) {
      setValue('companyName', company?.name);
    }
  }, [company?.name, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">{t('common:firstName')}</Typography>
          </Grid>
          <Grid item>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  onChange={(e) => handleTextInputChange(e, field.onChange)}
                  fullWidth
                  disabled={isLoggedIn}
                  InputProps={{
                    'data-cy': 'first-name-input',
                  }}
                />
              )}
            />
          </Grid>
          {errors.firstName && (
            <Grid item xs={12}>
              <Typography variant="caption" color="error">
                {errors.firstName.message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">{t('common:lastName')}</Typography>
          </Grid>
          <Grid item>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  onChange={(e) => handleTextInputChange(e, field.onChange)}
                  fullWidth
                  disabled={isLoggedIn}
                  InputProps={{
                    'data-cy': 'last-name-input',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        {errors.lastName && (
          <Grid item xs={12}>
            <Typography variant="caption" color="error">
              {errors.lastName.message}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">{t('auth:email')}</Typography>
          </Grid>
          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  onChange={(e) => handleTextInputChange(e, field.onChange)}
                  fullWidth
                  disabled={isLoggedIn}
                  InputProps={{
                    'data-cy': 'email-input',
                  }}
                />
              )}
            />
          </Grid>
          {errors.email && (
            <Grid item xs={12}>
              <Typography variant="caption" color="error">
                {errors.email.message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="flex-end" spacing={2}>
          {!companyId && (
            <Grid item>
              <FormLabel component="legend">
                <Typography variant="caption">
                  Does your company already have an account with us?
                </Typography>
              </FormLabel>
              <RadioGroup row defaultValue="no">
                <FormControlLabel
                  value="no"
                  control={<Radio color="secondary" />}
                  label={<Typography variant="caption">No</Typography>}
                  labelPlacement="top"
                  {...register('hasCompanyAccount')}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio color="secondary" />}
                  label={<Typography variant="caption">Yes</Typography>}
                  labelPlacement="top"
                  {...register('hasCompanyAccount')}
                />
              </RadioGroup>
              {hasCompanyAccount && (
                <Typography variant="caption">
                  <Link color="secondary">Contact us</Link> to add seats or use
                  your customer code below.
                </Typography>
              )}
            </Grid>
          )}
          {!hasCompanyAccount && (
            <Grid item container direction="column" xs>
              <Grid item>
                <Typography variant="caption">Company Name</Typography>
              </Grid>
              <Grid item>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      onChange={(e) => handleTextInputChange(e, field.onChange)}
                      disabled={isLoggedIn || loadingCompany || isFetching}
                      fullWidth
                      InputProps={{
                        'data-cy': 'companyName-input',
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {errors.companyName && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error">
                    {errors.companyName.message}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {showPaymentElement && (
        <Grid item xs={12}>
          <PaymentElement id="payment-element" />
        </Grid>
      )}
      {showPaymentElement && (
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="caption">
                {t('common:discountCode')}
              </Typography>
            </Grid>
            <Grid item>
              <Controller
                name="discountCode"
                control={control}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    InputProps={{
                      'data-cy': 'discount-input',
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                onClick={() => handleApplyDiscountCodeClick(discountCode)}
                disabled={updatingPaymentIntent}
                data-cy="apply-discount-code-button"
                sx={{
                  marginLeft: 1,
                }}
              >
                {t('common:button.applyCode')}
              </Button>
            </Grid>
            {errors.discountCode?.type && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  {errors.discountCode.message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {showPaymentElement && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell># of Seats</TableCell>
                <TableCell>{t('common:description')}</TableCell>
                <TableCell align="right">{t('common:amount')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Controller
                    name="seats"
                    control={control}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        type="number"
                        size="small"
                        onChange={(event) => handleSeatChange(event)}
                        InputProps={{
                          inputProps: { min: 1 },
                          disableUnderline: true,
                        }}
                        disabled={updatingPaymentIntent}
                      />
                    )}
                  />
                  {errors.seats && (
                    <Grid item xs={12}>
                      <Typography variant="caption" color="error">
                        {errors.seats?.message?.includes('NaN')
                          ? 'Please input a number'
                          : errors.seats?.message}
                      </Typography>
                    </Grid>
                  )}
                </TableCell>
                <TableCell>
                  {t('courses:label.seats', {
                    count: watchSeats.toString() === 'NaN' ? 0 : watchSeats,
                  })}
                </TableCell>
                <TableCell align="right">
                  {contactUs ? (
                    'N/A'
                  ) : (
                    <span>
                      {moneyFormatter.format(amounts.basePrice)} / seat
                    </span>
                  )}
                </TableCell>
              </TableRow>
              {(amounts.subtotal < amounts.basePrice ||
                amounts.discount > 0) && (
                <TableRow
                  style={{
                    borderTop: '2px solid #000',
                    borderBottom: '2px double #000',
                  }}
                >
                  <TableCell style={{ fontWeight: 'bold' }}>SUBTOTAL</TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {contactUs
                      ? 'N/A'
                      : moneyFormatter.format(
                          amounts.basePrice * amounts.seats
                        )}
                  </TableCell>
                </TableRow>
              )}
              {amounts.subtotal < amounts.basePrice && (
                <TableRow>
                  <TableCell style={{ fontStyle: 'italic' }}>
                    Multi-seat discount -{' '}
                    {moneyFormatter.format(amounts.subtotal)} / seat
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {contactUs ? (
                      'N/A'
                    ) : (
                      <i>
                        {moneyFormatter.format(
                          -(
                            amounts.basePrice * amounts.seats -
                            amounts.subtotal * amounts.seats
                          )
                        )}
                      </i>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {amounts.discount > 0 && (
                <TableRow>
                  <TableCell style={{ fontStyle: 'italic' }}>
                    {discountDescription}
                  </TableCell>
                  <TableCell />
                  <TableCell align="right">
                    {contactUs ? (
                      'N/A'
                    ) : (
                      <i>
                        {moneyFormatter.format(
                          -amounts.discount * amounts.seats
                        )}
                      </i>
                    )}
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                style={{
                  borderTop: '2px solid #000',
                  borderBottom: '2px solid #000',
                }}
              >
                <TableCell style={{ fontWeight: 'bold' }}>TOTAL</TableCell>
                <TableCell />
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  {contactUs ? 'N/A' : moneyFormatter.format(amounts.total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      )}
      {showPaymentElement && (
        <Grid item>
          <Typography variant="caption">
            By initiating this purchase you agree to our{' '}
            <Link
              color="secondary"
              href="https://www.scruminc.com/terms-of-use/"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              color="secondary"
              href="https://www.scruminc.com/privacy-policy/"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs />
          <Grid item>
            <Button
              data-cy="complete-purchase-button"
              variant="contained"
              color={showPaymentElement ? 'secondary' : 'primary'}
              component={contactUs ? 'a' : undefined}
              href={
                contactUs && showPaymentElement
                  ? 'https://www.scruminc.com/sqs-enterprise-purchase/'
                  : undefined
              }
              target={contactUs ? '_blank' : undefined}
              onClick={
                !contactUs
                  ? showPaymentElement
                    ? handleSubmit(onSubmit)
                    : updatePaymentIntentWithEmail
                  : undefined
              }
              disabled={
                (!validEmail && !user?.username) ||
                (!watchFirstName && !user?.first_name) ||
                (!watchLastName && !user?.last_name) ||
                updatingPaymentIntent ||
                disableSubmit ||
                processing ||
                (discountCode.length === 0 &&
                  hasCompanyAccount &&
                  watchSeats > 1)
              }
            >
              {!contactUs
                ? showPaymentElement
                  ? 'Complete Purchase'
                  : 'Continue Checkout'
                : 'Contact Us'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {contactUs && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs />
            <Grid item>
              <Typography style={{ fontStyle: 'italic' }} variant="caption">
                Please contact us for Enterprise pricing.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
