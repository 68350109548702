import providesTagsHelper from './helpers/providesTags';

export default function getOrganizationsEndpoints(builder) {
  return {
    getOrganization: builder.query({
      query: (organizationId) => `/organizations/${organizationId}`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'Organization', result }),
    }),
    createOrganization: builder.mutation({
      query: (body) => ({
        url: '/organizations',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Organization'],
    }),
    listOrganizations: builder.query({
      query: () => '/organizations',
      method: 'GET',
      providesTags: (result) =>
        providesTagsHelper({ type: 'Organization', result }),
    }),
    listOrganizationSessions: builder.query({
      query: (organizationId) => `/organizations/${organizationId}/sessions`,
      method: 'GET',
    }),
    getOrganizationCourses: builder.query({
      query: (organizationId) => `/organizations/${organizationId}/courses`,
      method: 'GET',
    }),
    listOrganizationUsers: builder.query({
      query: ({ organizationId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/organizations/${organizationId}/users?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
      providesTags: (result) =>
        providesTagsHelper({
          type: 'OrganizationUser',
          result: result?.docs || [],
        }),
    }),
    getOrganizationStudentProgress: builder.query({
      query: ({ organizationId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/organizations/${organizationId}/progress?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    getOrganizationAchievementStatus: builder.query({
      query: ({ organizationId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/organizations/${organizationId}/achievementStatus?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    getOrganizationAchievementType: builder.query({
      query: ({ organizationId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/organizations/${organizationId}/achievementType?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    addOrganizationAdmin: builder.mutation({
      query: ({ organizationId, userId, user }) => {
        let url = `/organizations/${organizationId}/user`;
        if (userId) {
          url += `/${userId}`;
        }

        return {
          url,
          method: 'POST',
          body: user,
        };
      },
      invalidatesTags: (result) => [{ type: 'Organization', id: result._id }],
    }),
    removeOrganizationAdmin: builder.mutation({
      query: ({ organizationId, userId }) => ({
        url: `/organizations/${organizationId}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'Organization', id: result._id }],
    }),
    getOrganizationExamAnalysis: builder.query({
      query: ({ organizationId, ...query }) => {
        const url = `/organizations/${organizationId}/examAnalysis`;
        const queryString = new URLSearchParams(query).toString();

        return `${url}?${queryString}`;
      },
    }),
    getOrganizationUsersExport: builder.query({
      query: ({ organizationId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/organizations/${organizationId}/users/export?${queryString}`;
      },
    }),
    updateOrganization: builder.mutation({
      query: ({ organizationId, ...body }) => ({
        url: `/organizations/${organizationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result) => [{ type: 'Organization', id: result._id }],
    }),
    deleteOrganization: builder.mutation({
      query: (organizationId) => ({
        url: `/organizations/${organizationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [{ type: 'Organization', id: result._id }],
    }),
    linkCompanies: builder.mutation({
      query: ({ organizationId, companyIds }) => ({
        url: `/organizations/${organizationId}/companies`,
        method: 'POST',
        body: { companyIds },
      }),
      invalidatesTags: (result) => [
        { type: 'Organization', id: result._id },
        'NonOrgCompany',
      ],
    }),
    unlinkCompany: builder.mutation({
      query: ({ organizationId, companyId }) => ({
        url: `/organizations/${organizationId}/companies/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) => [
        { type: 'Organization', id: result._id },
        'NonOrgCompany',
      ],
    }),
  };
}
