import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 'students',
  addAdminDialogOpen: false,
  confirmRemoveAdminDialog: {
    open: false,
    name: '',
    userId: null,
  },
  courseFilter: [],
  companyFilter: [],
  classes: {
    search: '',
    partner: null,
    classType: null,
  },
  users: {
    search: '',
    achievementType: null,
    achievementTypes: [],
    achievementStatus: null,
    order: 'asc',
    orderBy: 'user.full_name',
    page: 0,
    limit: 25,
  },
  deleteCompanyDialogOpen: {
    open: false,
    companyId: null,
  },
  unlinkCompanyDialogOpen: {
    open: false,
    companyId: null,
  },
  addCompanyDialogOpen: false,
  linkCompanyDialogOpen: false,
};

export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setActiveTab: (state, { payload: activeTab = 'students' }) => {
      state.activeTab = activeTab;
    },
    setAddAdminDialogOpen: (state, { payload: open = false }) => {
      state.addAdminDialogOpen = open;
    },
    setConfirmRemoveAdminDialog: (
      state,
      {
        payload: confirmRemoveAdminDialog = {
          open: false,
          name: '',
          userId: null,
        },
      }
    ) => {
      state.confirmRemoveAdminDialog = confirmRemoveAdminDialog;
    },
    setCourseFilter: (state, { payload: courseFilter }) => {
      state.courseFilter = courseFilter;
    },
    setCompanyFilter: (state, { payload: companyFilter = [] }) => {
      state.companyFilter = companyFilter;
    },
    setClassesSearch: (state, { payload }) => {
      state.classes.search = payload;
    },
    setClassesPartner: (state, { payload = [] }) => {
      state.classes.partner = payload;
    },
    setClassesType: (state, { payload = [] }) => {
      state.classes.classType = payload;
    },
    resetClassFilters: (state) => {
      state.classes.partner = null;
      state.classes.classType = null;
      state.classes.search = '';
    },
    setUsersSearch: (state, { payload }) => {
      state.users.search = payload;
    },
    setUsersAchievementType: (state, { payload }) => {
      state.users.achievementType = payload;
    },
    setUsersAchievementTypes: (state, { payload = [] }) => {
      state.users.achievementTypes = payload;
    },
    setUsersAchivementStatus: (state, { payload }) => {
      state.users.achievementStatus = payload;
    },
    setUsersOrder: (state, { payload }) => {
      state.users.order = payload;
    },
    setUsersOrderBy: (state, { payload }) => {
      state.users.orderBy = payload;
    },
    setUsersPage: (state, { payload }) => {
      state.users.page = payload;
    },
    setUsersLimit: (state, { payload }) => {
      state.users.limit = payload;
    },
    resetUsersFilters: (state) => {
      state.users.search = '';
      state.users.achievementStatus = null;
      state.users.order = 'asc';
      state.users.orderBy = 'full_name';
      state.users.page = 0;
      state.users.limit = 25;
    },
    setDeleteCompanyDialogOpen: (
      state,
      {
        payload: deleteCompanyDialogOpen = {
          open: false,
          companyId: null,
        },
      }
    ) => {
      state.deleteCompanyDialogOpen = deleteCompanyDialogOpen;
    },
    setUnlinkCompanyDialogOpen: (
      state,
      {
        payload: unlinkCompanyDialogOpen = {
          open: false,
          companyId: null,
        },
      }
    ) => {
      state.unlinkCompanyDialogOpen = unlinkCompanyDialogOpen;
    },
    setAddCompanyDialogOpen: (state, { payload: companyDialogOpen }) => {
      state.addCompanyDialogOpen = companyDialogOpen;
    },
    setLinkCompanyDialogOpen: (state, { payload: linkCompanyDialogOpen }) => {
      state.linkCompanyDialogOpen = linkCompanyDialogOpen;
    },
  },
});

export default organizationsSlice.reducer;
export const actions = organizationsSlice.actions;
export const {
  setCourseFilter,
  setCompanyFilter,
  setAddAdminDialogOpen,
  setConfirmRemoveAdminDialog,
  setDeleteCompanyDialogOpen,
  setAddCompanyDialogOpen,
  setLinkCompanyDialogOpen,
  setUnlinkCompanyDialogOpen,
} = organizationsSlice.actions;

export const selectSlice = (state) => state.organizations;
export const selectActiveTab = (state) => state.organizations.activeTab;
export const selectCourseFilter = (state) => state.organizations.courseFilter;
export const selectCompanyFilter = (state) => state.organizations.companyFilter;
export const selectClasses = (state) => state.organizations.classes;
export const selectUsersFilters = (state) => state.organizations.users;
export const selectAddAdminDialogOpen = (state) =>
  state.organizations.addAdminDialogOpen;
export const selectConfirmRemoveAdminDialog = (state) =>
  state.organizations.confirmRemoveAdminDialog;
export const selectDeleteCompanyDialogOpen = (state) =>
  state.organizations.deleteCompanyDialogOpen;
export const selectAddCompanyDialogOpen = (state) =>
  state.organizations.addCompanyDialogOpen;
export const selectLinkCompanyDialogOpen = (state) =>
  state.organizations.linkCompanyDialogOpen;
export const selectUnlinkCompanyDialogOpen = (state) =>
  state.organizations.unlinkCompanyDialogOpen;
