import React from 'react';
import { AppBar, Grid, CssBaseline, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../common/Header/logo.png';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const HeaderContent = styled(Grid)(({ theme }) => ({
  height: 50,
  padding: theme.spacing(0, 1),
}));

const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: 38,
  },
  [theme.breakpoints.up('sm')]: {
    height: 48,
  },
  paddingRight: theme.spacing(2),
}));

function MaintenanceHeader(_props, ref) {
  return (
    <>
      <AppBarStyled ref={ref} position="fixed">
        <CssBaseline />
        <Toolbar variant="dense" disableGutters>
          <Grid container direction="column">
            <HeaderContent item container alignItems="center">
              <Grid item>
                <Logo src={logo} alt="Scrum Inc. Logo" />
              </Grid>
            </HeaderContent>
          </Grid>
        </Toolbar>
      </AppBarStyled>
    </>
  );
}

export default React.forwardRef(MaintenanceHeader);
