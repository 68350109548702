import React from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  Home as HomeIcon,
  Group as GroupIcon,
  CalendarToday as SessionIcon,
  Person as PeopleIcon,
  Payment as PaymentIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function AssistantListItems() {
  const { t } = useTranslation(['common', 'poker']);

  return (
    <List>
      <ListSubheader disableSticky>{t('common:nav.trainers')}</ListSubheader>
      <ListItem button component={Link} to="/trainer" data-cy="home">
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.home')} />
      </ListItem>
      <ListItem button component={Link} to="/sessions" data-cy="sessions">
        <ListItemIcon>
          <SessionIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.sessions')} />
      </ListItem>
      <ListItem button component={Link} to="/groups" data-cy="groups">
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.clients')} />
      </ListItem>
      <ListItem button component={Link} to="/users" data-cy="users">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.students')} />
      </ListItem>
      <ListItem button component={Link} to="/billing" data-cy="billing">
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.billing')} />
      </ListItem>
    </List>
  );
}
