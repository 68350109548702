import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useAuth } from 'hooks/useAuth';
import { actions } from 'features/courses/slice';

export default function Success() {
  const dispatch = useDispatch();
  const { user } = useAuth();

  React.useEffect(() => {
    return () => {
      dispatch(actions.resetPurchase());
    };
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5" gutterBottom>
          Your payment was successful! 🎉
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          You will receive an email with your receipt along with confirmation
          that the{' '}
          {user
            ? 'course has been added to your account.'
            : 'an account has been created for you with access to the course.'}
        </Typography>
      </Grid>
    </Grid>
  );
}
