import { io } from 'socket.io-client';

import { pokerActions } from 'features/poker/slice';

const pokerMiddleware = ({ getState, dispatch }) => {
  let socket;

  return (next) => (action) => {
    const isConnected = socket && getState().poker.isConnected;
    const id = getState().poker.userId;
    const roomId = getState().poker.room?.code;

    if (pokerActions.connecting.match(action) && !isConnected) {
      socket = io(process.env.REACT_APP_ROOT_URL, { withCredentials: true });
      socket.auth = { id };

      // add the socket logger to dev env
      if (process.env.REACT_APP_ENV === 'dev') {
        socket.onAny((event, ...args) => {
          console.log(event, args);
        });
      }

      // this event fires on both connect and reconnect
      socket.on('connect', () => {
        const name = getState().poker.userName;
        dispatch(pokerActions.connected());
        // emits to the server to join the room and fetch fresh room data
        socket.emit('join', {
          ...action.payload,
          name,
        });
      });

      // listeners to listen for events from the server
      socket.on('disconnect', () => {
        dispatch(pokerActions.disconnected());
      });

      socket.on('updateRoom', (room) => {
        dispatch(pokerActions.updateRoom(room));
      });

      socket.on('votingEnded', (room) => {
        dispatch(pokerActions.votingEnded(room));
      });
    }

    // cleanup event listeners when a user leaves the room
    if (pokerActions.leave.match(action)) {
      socket.offAny();
      socket.off('connect');
      socket.off('disconnect');
      socket.off('updateRoom');
      socket.disconnect();
    }

    if (isConnected) {
      // client-side emitter actions
      if (pokerActions.updateUserName.match(action)) {
        socket.emit('updateUserName', action.payload);
      }
      if (pokerActions.startVoting.match(action)) {
        socket.emit('startVoting', action.payload);
      }
      if (pokerActions.userVoted.match(action)) {
        socket.emit('userVoted', action.payload);
      }
      if (pokerActions.endVoting.match(action)) {
        socket.emit('endVoting', action.payload);
      }
      if (pokerActions.makeScrumMaster.match(action)) {
        socket.emit('makeScrumMaster', action.payload);
      }
      if (pokerActions.randomizeAvatarSeed.match(action)) {
        socket.emit('updateAvatarSeed', {
          avatarSeed: action.payload,
          id,
          roomId,
        });
      }
    }

    next(action);
  };
};

export default pokerMiddleware;
