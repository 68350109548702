import { createSlice } from '@reduxjs/toolkit';

import { loadState } from 'utils/localStorage';

const localState = loadState('agilitySurvey');

const initialState = {
  questions: [],
  answers: localState?.answers || {},
  validations: {},
  currentQuestionIndex: -1,
  error: null,
  isQuestionAnswered: localState?.isQuestionAnswered || {},
  isInputValid: localState?.isInputValid || {},
  isSuccess: false,
  isSessionSurvey: false,
  sessionId: null,
};

export const agilitySurveySlice = createSlice({
  name: 'agilitySurvey',
  initialState,
  reducers: {
    setCurrentQuestionIndex: (state, { payload = -1 }) => {
      state.currentQuestionIndex = payload;
    },
    prevQuestion: (state) => {
      state.currentQuestionIndex -= 1;
    },
    nextQuestion: (state) => {
      state.currentQuestionIndex += 1;
    },
    setQuestions: (state, { payload }) => {
      state.questions = payload;
    },
    setAnswer: (
      state,
      { payload: { id, key, type, value, dimension, subDimension, facet } }
    ) => {
      state.answers[id] = {
        key,
        type,
        value,
        dimension,
        subDimension,
        facet,
      };
    },
    setIsQuestionAnswered: (state, { payload: { id, isAnswered } }) => {
      state.isQuestionAnswered[id] = isAnswered;
    },
    setIsValid: (state, { payload: { id, isValid } }) => {
      state.isInputValid[id] = isValid;
    },
    reset: (state) => {
      state.questions = [];
      state.answers = {};
      state.currentQuestionIndex = -1;
      state.error = null;
      state.isQuestionAnswered = {};
      state.isInputValid = {};
      state.isSessionSurvey = false;
      state.sessionId = null;
    },
    submitSuccess: (state, { payload }) => {
      state.isSuccess = true;
      state.answers = {};
      state.currentQuestionIndex = -1;
      state.isQuestionAnswered = {};
      state.isInputValid = {};
      state.isSessionSurvey = payload?.isSessionSurvey ?? false;
      if (payload?.isSessionSurvey) {
        state.sessionId = payload.sessionId;
      } else {
        state.sessionId = null;
      }
    },
  },
});

export default agilitySurveySlice.reducer;
export const actions = agilitySurveySlice.actions;

export const selectAgilitySurvey = (state) => state.agilitySurvey;
