import moment from 'moment';

import providesTagsHelper from './helpers/providesTags';

const sortInvoices = (invoices) => {
  const paid = [];
  let pastDue = [];
  const open = [];

  invoices.forEach((i) => {
    if (i.paid === true) {
      paid.push(i);
    }
    if (i.paid !== true && moment(i.due_date) < moment()) {
      pastDue.push(i);
    }
    if (i.paid !== true && moment(i.due_date) > moment()) {
      open.push(i);
    }
  });

  paid.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
  pastDue.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
  open.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));

  pastDue = pastDue.concat(open);
  pastDue = pastDue.concat(paid);

  return pastDue;
};

export default function invoices(builder) {
  return {
    getInvoice: builder.query({
      query: (id) => `/invoices/${id}`,
    }),
    getInvoices: builder.query({
      query: () => '/invoices',
      transformResponse: (invoices) => sortInvoices(invoices),
      providesTags: (result) => providesTagsHelper({ type: 'Invoice', result }),
    }),
    getInvoiceSignedUrl: builder.query({
      query: (id) => `/invoices/signedUrl/${id}`,
    }),
    getInvoiceToEdit: builder.query({
      query: (id) => `/invoices/edit/${id}`,
      providesTags: ['InvoiceEdit'],
    }),
    updateInvoice: builder.mutation({
      query: ({ id, updates }) => ({
        url: `/invoices/edit/${id}`,
        method: 'POST',
        body: updates,
      }),
      invalidatesTags: ['Invoice', 'InvoiceEdit'],
      // todo: maybe do optimistic updating to make things feel faster
    }),
    resendInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoices/resend/${id}`,
        method: 'POST',
      }),
    }),
    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: `/invoices/delete/${id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'Invoice',
          id: arg,
        },
      ],
    }),
    createInvoiceCheckoutSession: builder.mutation({
      query: (id) => ({
        url: `/invoices/generate_checkout_session/${id}`,
        method: 'POST',
      }),
    }),
  };
}
