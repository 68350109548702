import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { loadState } from 'utils/localStorage';

const cart = loadState('cart') || [];

const initialState = {
  linkedIn: {
    userId: null,
    accessToken: null,
    expiration: null,
  },
  share: {
    isOpen: false,
    hasConfirmed: false,
    toFeed: true,
    toProfile: true,
    message: '',
    achievement: null,
    popupBlocked: false,
  },
  renew: {
    clientSecret: null,
    paymentIntentId: null,
    paymentIntentStatus: null,
    checkingOut: false,
    success: false,
    processing: false,
    cart: Array.isArray(cart) ? cart : [],
    discountCode: '',
    discountDescription: null,
    amounts: {
      subtotal: 0,
      discount: 0,
      total: 0,
    },
    // achievement currently selected for renewal to select options
    achievement: null,
    option: 'fiveYear',
    optionDetail: null,
  },
};

export const achievementsSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    setLinkedInData: (
      state,
      {
        payload: {
          userId = null,
          access_token: accessToken = null,
          expires_in: expiresIn = null,
        } = {},
      }
    ) => {
      state.linkedIn.userId = userId;
      state.linkedIn.accessToken = accessToken;
      state.linkedIn.expiration = expiresIn;
    },

    setShare: (
      state,
      {
        payload: {
          isOpen = false,
          hasConfirmed = false,
          toFeed = true,
          toProfile = true,
          achievement = null,
          message = initialState.share.message,
        } = {},
      }
    ) => {
      state.share.isOpen = isOpen;
      state.share.hasConfirmed = hasConfirmed;
      state.share.toFeed = toFeed;
      state.share.toProfile = toProfile;
      state.share.achievement = achievement;
      state.share.message = message;
    },
    setShareAchievement: (state, { payload: achievement = null }) => {
      state.share.achievement = achievement;
    },
    setShareAchievementSession: (state, { payload: session = null }) => {
      if (state.share.achievement) {
        state.share.achievement.session = session;
      }
    },
    setShareMessage: (
      state,
      { payload: message = initialState.share.message }
    ) => {
      state.share.message = message;
    },
    setShareHasConfirmed: (state, { payload: hasConfirmed = false }) => {
      state.share.hasConfirmed = hasConfirmed;
    },
    setShareToFeed: (state, { payload: toFeed = true }) => {
      state.share.toFeed = toFeed;
    },
    setShareToProfile: (state, { payload: toProfile = true }) => {
      state.share.toProfile = toProfile;
    },
    setPopupBlocked: (state, { payload: popupBlocked }) => {
      state.share.popupBlocked = popupBlocked;
    },
    // renewal actions
    addToCart: (state, { payload: achievement }) => {
      const index = state.renew.cart.findIndex(
        ({ id }) => id === achievement.id
      );
      if (index >= 0) {
        state.renew.cart[index] = achievement;
      } else {
        state.renew.cart.push(achievement);
      }
    },
    removeFromCart: (state, { payload: achievementId }) => {
      state.renew.cart = state.renew.cart.filter(
        ({ id }) => id !== achievementId
      );
    },
    emptyCart: (state) => {
      state.renew.cart = [];
    },
    resetRenewal: (state) => {
      state.renew = {
        ...initialState.renew,
      };
    },
    setRenewSuccess: (state, { payload: success = false }) => {
      state.renew.success = success;
    },
    setRenewProcessing: (state, { payload: processing = false }) => {
      state.renew.processing = processing;
    },
    setRenew: (state, { payload: achievement = null }) => {
      state.renew.achievement = achievement;
      if (!achievement) {
        state.renew.isCheckingOut = false;
        state.renew.optionDetail = null;
      }
    },
    startRenewCheckout: (state, { payload = false }) => {
      state.renew.checkingOut = payload;
      if (!payload) {
        state.renew.optionDetail = null;
      }
    },
    setRenewClientSecret: (state, { payload = null }) => {
      state.renew.clientSecret = payload;
    },
    setRenewPaymentIntentId: (state, { payload = null }) => {
      state.renew.paymentIntentId = payload;
    },
    setRenewPaymentIntentStatus: (state, { payload = null }) => {
      state.renew.paymentIntentStatus = payload;
    },
    setRenewalOption: (
      state,
      { payload: { cadence = 'fiveYear', detail = null } = {} }
    ) => {
      state.renew.option = cadence;
      if (detail) {
        state.renew.optionDetail = detail;
      }
    },
    setRenewAmounts: (state, { payload: amounts }) => {
      state.renew.amounts = amounts;
    },
    setRenewDiscountCode: (state, { payload: discountCode = '' }) => {
      state.renew.discountCode = discountCode;
    },
    setRenewDiscountDescription: (state, { payload: discountDescription }) => {
      state.renew.discountDescription = discountDescription;
    },
  },
});

export const {
  setLinkedInData,
  setShare,
  setShareAchievement,
  setShareMessage,
  setShareHasConfirmed,
  setShareToFeed,
  setShareToProfile,
  setPopupBlocked,
  setRenew,
  startCheckout,
  setRenewalOption,
} = achievementsSlice.actions;

export const actions = achievementsSlice.actions;
export default achievementsSlice.reducer;

export const selectors = {
  selectShare: (state) => state.achievements.share,
  selectShareableAchievement: (state) => state.achievements.share.achievement,
  selectRenewableAchievement: (state) => state.achievements.renew.achievement,
  selectLinkedInUserId: (state) => state.achievements.linkedIn.userId,
  selectLinkedInAccessToken: (state) =>
    moment().isBefore(state.achievements.linkedIn.expiration)
      ? state.achievements.linkedIn.accessToken
      : null,
  selectRenew: (state) => state.achievements.renew,
  selectRenewalOption: (state) => state.achievements.renew.option,
  selectRenewalOptionDetail: (state) => state.achievements.renew.optionDetail,
  selectPopupBlocked: (state) => state.achievements.share.popupBlocked,
  selectCart: (state) => state.achievements.renew.cart,
  selectClientSecret: (state) => state.achievements.renew.clientSecret,
  selectPaymentIntentId: (state) => state.achievements.renew.paymentIntentId,
};
