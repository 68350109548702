import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  edit: {
    addingCategory: false,
    editingCategory: {
      open: false,
      id: null,
      name: '',
      icon: '',
    },
    addingModule: {
      open: false,
      categoryId: null,
      categoryName: '',
    },
    editingModule: {
      open: false,
      categoryId: null,
      moduleId: null,
      categoryName: '',
    },
    confirm: {
      open: false,
      title: '',
      prompt: '',
    },
  },
};

export const contentLibrarySlice = createSlice({
  name: 'contentLibrary',
  initialState,
  reducers: {
    toggleAddingCategory: (state, { payload }) => {
      state.edit.addingCategory =
        payload !== undefined ? payload : !state.edit.addingCategory;
    },
    setConfirmDialog: (state, { payload }) => {
      state.edit.confirm = {
        ...state.edit.confirm,
        ...payload,
      };
    },
    setEditingCategory: (state, { payload }) => {
      if (payload) {
        state.edit.editingCategory = {
          ...state.edit.editingCategory,
          ...payload,
        };
      } else {
        state.edit.editingCategory = {
          ...initialState,
        };
      }
    },
    setAddingModule: (state, { payload }) => {
      if (payload) {
        state.edit.addingModule = {
          ...state.edit.addingModule,
          ...payload,
        };
      } else {
        state.edit.addingModule = {
          ...initialState,
        };
      }
    },
    setEditingModule: (state, { payload }) => {
      if (payload) {
        state.edit.editingModule = {
          ...state.edit.editingModule,
          ...payload,
        };
      } else {
        state.edit.editingModule = {
          ...initialState,
        };
      }
    },
  },
});

export const actions = contentLibrarySlice.actions;
export default contentLibrarySlice.reducer;

export const selectEdit = (state) => state.contentLibrary.edit;
