import { api } from 'app/api';
import { createSlice } from '@reduxjs/toolkit';
import { createShortAchName } from 'utils/helpers/achievementHelpers';

export const apiWithCredentialLookup = api.injectEndpoints({
  addTagTypes: ['CredentialLookup'],
  endpoints: (builder) => ({
    getCredentialHolders: builder.query({
      query: ({
        firstName,
        lastName,
        country,
        credType,
        certId,
        page,
        includeExpired,
      }) => {
        if (certId?.length) {
          if (!/^[0-9]{7}$/.test(certId))
            throw new Error('ID# must be only 7 digits.');
          if (!credType) {
            throw new Error('Please select a credential type for ID# search.');
          }
          if (certId.includes('-')) certId = certId.split('-')[1];
          const credPrefix = createShortAchName(credType.value);
          certId = `${credPrefix}-${certId}`;
        }
        const params = new URLSearchParams({
          ...(firstName.length > 0 && { firstName }),
          ...(lastName.length > 0 && { lastName }),
          ...(certId.length > 0 && { certId }),
          ...(country && { country: country.value }),
          ...(credType && { credType: credType.value }),
          ...(page && { page }),
          ...(includeExpired && { includeExpired }),
        }).toString();
        return `/users/getCredentialHolders?${params}`;
      },
    }),
  }),
});

export const {
  useGetCredentialHoldersQuery,
  useLazyGetCredentialHoldersQuery,
} = apiWithCredentialLookup;

export const initialState = {
  credentialFilters: {
    firstName: '',
    lastName: '',
    country: null,
    credType: null,
    certId: '',
    includeExpired: false,
  },
  page: 1,
};

export const credentialLookupSlice = createSlice({
  name: 'credentialLookup',
  initialState,
  reducers: {
    setCredentialFilters: (state, { payload }) => {
      state.credentialFilters = { ...state.credentialFilters, ...payload };
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    resetCredentialLookup: (state) => {
      state.page = initialState.page;
      state.credentialFilters = initialState.credentialFilters;
    },
  },
});

export const {
  setCredentialFilters,
  setPaginationData,
  setPage,
  resetCredentialLookup,
} = credentialLookupSlice.actions;

export default credentialLookupSlice.reducer;

export const selectCredentialFilters = ({ credentialLookup }) =>
  credentialLookup.credentialFilters;

export const selectPageNumber = ({ credentialLookup }) => credentialLookup.page;
