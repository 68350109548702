module.exports =
      {
        language: "Español",
        username: "Usuario",
        password: "Clave",
        welcome: "Bienvenido",
        tos: 'Términos de Servicio',
        pp: 'Política de Privacidad',
        home: "Tablero",
        sessions: "Clases",
        newSession: 'Nueva Clase',
        courses: "Tipos de Cursos",
        people: "Estudiantes",
        groups: "Clientes",
        needAccount: "Necesita una cuenta?",
        registerHere: " Registrese Aqui",
        reports: "Reportes",
        login: "Login",
        loginMessage: "Por favor ingrese su correo y su clave para acceder a la Plataforma de Scrum Online",
        forgotPassword: "Olvidó su clave?",
        privacyPolicy: "Política de Privacidad",
        viewCourse: "Examen y Material del Curso ",
        recent: "Reciente",
        viewCredential: "Ver Credencial",
        modules: "Módulos",
        logout: "Logout",
        help: "Ayuda",
        firstName: "Nombre",
        lastName: "Apellido",
        yourName: "Tu Nombre",
        email: "Correo Electrónico",
        confirm: "Confirmar",
        register: "Registrar",
        myAchievements: "Mis Logros",
        settings: "Configuración",
        passwordRequirements: "Su clave debe contener por lo menos un caracter en mayúsculas y uno en minúsculas, por lo menos un número y entre 8 y 20 caracteres de longitud",
        passed: "Aprobado!",
        checkResultsBelow: "Puede ver sus resultados abajo",
        downloadCredential: "Descargar Credencial",
        returnToDashboard: "Regresar al Tablero",
        billingLink: "Facturación",
         resetInstructions: "Ingrese su nombre de usuario(típicamente su dirección de correo electrónico) y le enviaremos las instrucciones para restaurar su clave",
        resetSuccess: "Exito!Por favor revise su correo.Si hay una cuenta de usuario asociada a este correo electrónico, va a recibir las instrucciones de como restaurar su clave.",
       reset: "Restaurar",
        createAccount: "Para crear su cuenta, por favor ingrese sus detalles y utilice el código de invitación que le dió su instructor",
        selectCountry: "Seleccionar País",
        selectPreferredLanguage: "Seleccionar Lenguaje Preferido",
        inviteCode: "Código de invitación",
        acceptTerms1: "He leído y acepto el ",
        acceptTerms2: " asi como también el ",
        acceptTerms3: "Al iniciar sesión en ScrumLab, aceptas nuestros",
        and: "y",
       newsletterCheckbox: "Me gustaría recibir boletines y últimas ofertas de Scrum Inc.",
        alreadyHaveAccount: "Ya tiene una cuenta? Inicie sesión aquí",
        dashboard: "Tablero",
        visitMyAchievements: "Visite la Página de Mis Logros para descargar sus credenciales",
        registerWithCode: "Registrese con su código de curso",
        rateInstructor: "Califique a su instructor",
        rateExperience: "Cómo calificaría su experiencia de aprendizaje con[instructor name] ? ",
        instructorFeedback: "Qué fue lo que[instructor name] hizo bien ? Qué podría hacer[instructor name] para mejorar la experiencia de aprendizaje ? NOTA :Este feedback sera completamente anónimo para su instructor.",
        additionalFeedback: "Feedback Adicional(Opcional)",
        returnDashboard: "Regresar al Tablero",
        submit: "Enviar",
        lsmDescription: "Nuestro curso presencial de 2 días de Registered Scrum Master (RSM) es una introducción al Framework de Scrum y aborda el Rol del Scrum Master en detalle. Este componente online del curso contiene un examen de credencial. Luego de pasar el examen usted será Scrum Master y podrá descargar su credencial oficial.",
        lsmExam: "Examen de Scrum Master",
        files: "Archivos",
        noCertificates: "Usted no tiene ninguna credencial todavía! Una vez que complete y pase uno de nuestros exámenes de Scrum encontrará su credencial descargable aquí.",
        selectLanguage: "Seleccione su preferencia de Lenguaje",
        save: "Guardar",
        uploadImage: "Subir Imagen",
        profilePicture: "Imagen de Perfil",
        userInfo: "Información de Usuario",
        socialsBio: "Sociales y Biografía",
        website: "Website",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Biografía",
        changePassword: "Cambiar Clave",
        newPassword: "Nueva Clave",
        changeOptIn: "Cambiar Opt-Ins",
        congratulationsMessage: "Felicitaciones! Aprobó! Recibirá la credencial en su correo electrónico a la brevedad. Ahora puede revisar sus resultados.",
        directoryOptIn: "Opciones Opt-In",
        certificantDirectoryMessage: "Me gustaría ser añadido al Registro Internacional de Profesionales Ágiles",
        close: "Cerrar",
        correct: "Correcto",
        viewResults: "Puede ver sus resultados abajo",
        downloadCert: "Descargar Credencial",
        questionNumber: "Pregunta",
        currentPassword: "Clave Actual",
        certificantDirectory: "Registro Internacional de Profesionales Ágiles",
        validSince: "Valido Desde:",
        lastRenewed: "Última Renovación:",
        expires: "Expira:",
        renew: "Renovar",
        download: "Descargar",
        newsletter: "Newsletter - Scrum Newsletter - y notificaciones de correo electrónico",
renewCredential: "Renovar Credencial",
        renewalNotice: "Para renovar su credencial debe pagar la tarifa de renovación. Luego podrá tomar un examen de renovación y, al aprobar el examen, su credencial será renovada y válida por 1 año. Puede pagar su tarifa de renovación utilizando nuestro pago seguro a continuación.",
        registerClass: "Registrar una Clase",
              renewNow: "Renovar Ahora",
        secureCheckout: "Checkout seguro - Powered by Stripe",
enterInviteCode: "Por favor ingrese su código de invitación o la url de invitación para registrarse.",
        lspoDescription: "Nuestro curso presencial de 2 días de Registered Product Owner (RPO) es una introducción al Framework de Scrum y aborda el Rol del Product Owner en detalle. Este componente online del curso contiene un examen de credencial. Luego de pasar el examen usted será Registered Product Owner (RPO) y podrá descargar su credencial oficial.",
        attemptsLeft: "2 intentos restantes",
        reviewAnswers: "Por favor revise sus respuestas y realice cualquier cambio antes de enviar su exámen",
        didNotPass: "Usted no aprobó el examen.",
        didNotPassOneLeft: "Usted no aprobó el examen.Le queda un intento.",
        failed: "Falló",
errorMessage: " Algo salió mal. Por favor contáctenos online-learning@scruminc.com",
retakeExam: "Retomar el Examen",
        noMoreAttempts: "Se ha quedado sin intentos para realizar el examen. Por favor contáctese con su instructor para recibir más intentos.",
        contactUs: "Contactenos",
        subject: "Asunto",
        message: "Mensaje",
enterVirtualClassroom: "Ingresar a la Clase Virtual",
        myStudentView: "Mi Vista de Estudiante ",
        examAnalysis: " Analisis del Examen ",
        adminDashboard: "Tablero de Administración",
        partners:"Partners",
        supportSuccess: " Ticket de soporte creado con éxito. Nuestro horario de asistencia es de 10 a.m. a 6 p.m. EST. Le responderemos lo antes posible. ¡Gracias!",
        visitMyAchievementsTile: " Para ver o renovar sus credenciales, visite la página Mis logros",
        total: "total",
        dueDate: "Fecha Vencimiento",
        action: "Acción",
        description: "Descripción",
        status:"Estado",
        pay: 'Pagar',
        receipt: "Recibo",
        pastDue: "Atrasado",
        open: "Abrir",
        paid: "Pagado",
        warning: " Advertencia",
        pleaseBilling: " Complete su información de facturación en la página de configuración ",
        all: "Todo",
        thisWeek: "Esta semana ",
        nextWeek: "Siguiente semana ",
        laterThisMonth: " A finales de este mes ",
        futureClasses: "Clases futuras",
        recentClasses: "Clases recientes",
        back: "Anterior",
        next: "Siguiente",
        billingInformation: "Información de facturación",
        billingReceiptInfo: "Esta información se mostrará en las facturas.",
        billingReceiptInfo2: "Si necesita que se envíe la factura o el enlace de pago a cualquier otra dirección de correo electrónico puede agregarlos aquí. (Usted siempre la recibirá en el correo electrónico que tiene registrado). Si son múltiples mails deben estar separados por coma. Ej. Jane@doe.com, john@doe.com ",
        viewModule: "Ver Modulo",
        enterAValidEmail: "Por favor ingrese un mail válido",
        invalidResetLink: "Link de reseteo inválido.",
        passwordReset: "Reseteo de contraseña",
        returnToLogin: "Volver al Login",
        success: "Exito",
        confirmRemoveClass: "Confirmación de clase eliminada ",
        areYouSureRemoveClass: " ¿Estás seguro que quieres eliminar esta clase? Esto hará que el código de invitación sea inutilizable y eliminará el acceso de todos los estudiantes registrados actualmente.",
        cancel: "Cancelar",
        delete: 'Eliminar',
        associatedCourses: 'Cursos Asociados',
        content: 'Contenido',
        addContent: 'Agregar Contenido',
        name: 'Nombre',
        filesAndResources:'Archivos y Recursos',
        classStartDate: 'Fecha de inicio del curso',
        classEndDate: 'Fecha del fin del curso',
        clients: 'Clientes',
        selectClient: 'Seleccionar Cliente...',
        copy: 'Copiar',
        courseRegistrationUrl: 'URL Registración del Curso ',
        courseWebpageLink: "Ingrese el link de la página de su curso ",
        selfSignupUrl: "URL de Auto-registro",
        inviteCodeExpiry: " Fecha de vencimiento del código de invitación",
        instructors: 'Entrenadores',
        location: 'Lugar',
        maxUsers: 'Usuarios Máximos',
        privateSession: 'Clase privada',
        removeClass: 'Eliminar clase',
        viewUser: 'Ver Usuario',
        virtualSession: 'Sesión Virtual',
        virtualClassroomLink: 'Linkd del Aula Virtual',
        startExam: 'Iniciar Examen',
        incomplete: 'Incompleto',
        complete: 'Completo',
        completed: 'Completo',
    }
