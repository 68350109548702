import providesTagsHelper from './helpers/providesTags';

export default function getChangelogEndpoints(builder) {
  return {
    listChangelog: builder.query({
      query: () => '/changelog',
      method: 'GET',
      providesTags: (result) =>
        providesTagsHelper({ type: 'Changelog', result }),
    }),
    createChangelog: builder.mutation({
      query: (body) => ({
        url: '/changelog',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Changelog'],
    }),
    deleteChangelog: builder.mutation({
      query: (changeLogId) => ({
        url: `/changelog/${changeLogId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Changelog'],
    }),
  };
}
