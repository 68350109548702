module.exports =
    {
        language: "Türkçe",
        username: "Kullanıcı Adı",
        password: "Şifre",
        welcome: "Hoşgeldiniz",
        tos: 'Hizmet Şartları',
        pp: 'Gizlilik Politikası',
        home: "Ana Panel",
        sessions: "Sınıflar",
        newSession: 'Yeni Sınıf',
        courses: "Kurs Tipleri",
        people: "Öğrenciler",
        groups: "Müşteriler",
        needAccount: "Bir hesaba mı ihtiyacınız var?",
        registerHere: "Kayıt Olun",
        reports: "Raporlar",
        login: "Oturum aç",
        loginMessage: "ScrumLab platformuna erişmek için lütfen e-posta adresinizi ve şifrenizi girin",
        forgotPassword: "Parolanızı mı unuttunuz?",
        privacyPolicy: "Gizlilik Politikası",
        viewCourse: "Kurs Materyalleri ve Sınav",
        recent: "En Son",
        viewCredential: "Yeterlilik Belgesini Göster",
        modules: "Modüller",
        logout: "Çıkış Yap",
        help: "Yardım",
        firstName: "Ad",
        lastName: "Soyad",
        yourName: "Adınız",
        email: "E-posta adresi",
        confirm: "Onay",
        register: "Kayıt ol",
        myAchievements: "Başarılarım",
        settings: "Ayarlar",
        passwordRequirements: "Şifreniz en az bir büyük ve bir küçük harf, en az bir rakam içermeli ve uzunluğu 8 ile 20 karakter arasında olmalıdır",
        passed: "Geçtiniz!",
        checkResultsBelow: "Sonuçlarınızı aşağıda görüntüleyebilirsiniz",
        downloadCredential: "Yeterlilik Belgesini İndir",
        returnToDashboard: "Ana Panele Geri Dön",
        billingLink: "Fatura",
        resetInstructions: "Kullanıcı adınızı giriniz (genellikle e-postanızdır) size şifrenizi sıfırlamanız için yönlendirmeler göndereceğiz.",
        resetSuccess: "Başarılı! Lütfen e-postanızı kontrol edin Bu e-postayla ilişkili bir kullanıcı hesabı varsa, şifrenizi nasıl sıfırlayacağınıza dair yönlendirmeler alacaksınız.",
        reset: "Sıfırla",
        createAccount: "Hesabınızı oluşturmak için lütfen bilgilerinizi girin ve eğitmeninizin verdiği davet kodunu kullanın",
        selectCountry: "Ülke Seçin",
        selectPreferredLanguage: "Tercih Edilen Dili Seçin",
        inviteCode: "Davet Kodu",
        acceptTerms1: "Okudum ve kabul ediyorum: ",
        acceptTerms2: " yanı sıra ",
        newsletterCheckbox: "Scrum Inc. haber bültenini ve en son teklifleri almak istiyorum",
        alreadyHaveAccount: "Zaten hesabınız var mı? Giriş yapın",
        dashboard: "Ana Panel",
        visitMyAchievements: "Kurs materyalinizi görüntülemek ve / veya Scrum Inc. Scrum Sınavınıza girmek için, lütfen aşağıdaki ilgili sınıf kutucuklarına tıklayın. Daha önce kazandığınız yeterlilik belgelerinizi indirmek için Başarılarım sayfasını ziyaret edin.",
        registerWithCode: "Kurs kodunuzla kayıt olun",
        rateInstructor: "Eğitmeninize puan verin",
        rateExperience: "[instructor name] ile ilgili öğrenme deneyiminizi nasıl değerlendirirsiniz?",
        instructorFeedback: "[instructor name] neyi iyi yaptı? [instructor name], öğrenme deneyimini iyileştirmek için ne yapabilir? NOT: Bu geri bildirim, eğitmeniniz için tamamen anonim olacaktır.",
        additionalFeedback: "Ek Geri Bildirim (İsteğe Bağlı)",
        returnDashboard: "Ana Panele Geri Dön",
        submit: "Gönder",
        lsmDescription: "2 günlük yüz yüze Scrum Inc. Lisanslı Scrum Master kursumuz, Scrum çerçevesine giriş niteliğindedir. Scrum Master rolüne ayrıntılı olarak bakar. Kursun bu çevrimiçi bileşeni bir sertifika testi içerir. Testinizi geçtikten sonra bir Scrum Inc. Lisanslı Scrum Master olacaksınız ve resmi sertifikanızı indirebileceksiniz.",
        lsmExam: "Scrum Inc. Lisanslı Scrum Master Sınavı",
        files: "Dosyalar",
        noCertificates: "Henüz herhangi bir sertifikanız yok! Scrum Inc. Scrum sınavlarımızdan birini tamamlayıp geçtikten sonra indirilebilir yeterlilik belgelerinizi burada bulacaksınız.",
        selectLanguage: "Dil Tercihinizi Seçiniz",
        save: "Kaydet",
        uploadImage: "Fotoğraf Yükle",
        profilePicture: "Profil Fotoğrafı",
        userInfo: "Kullanıcı Bilgileri",
        socialsBio: "Sosyal Medya ve Biyografi",
        website: "Web Sitesi",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Biyografi",
        changePassword: "Şifre Değiştir",
        newPassword: "Yeni Şifre",
        changeOptIn: "Etkinlikleri Değiştir",
        confirmNew:"Yeni Şifreyi Onayla",
        congratulationsMessage: "Tebrikler! Geçtiniz! Kısa süre içinde e-postanıza bir yeterlilik belgesi alacaksınız. Sonuçlarınızı şimdi inceleyebilirsiniz.",
        directoryOptIn: "Dizin Kaydı",
        certificantDirectoryMessage: "Uluslararası Çevik Profesyoneller Siciline eklenmek istiyorum",
        close: "Kapat",
        correct: "Doğru",
        viewResults: "Sonuçlarınızı aşağıda görüntüleyebilirsiniz",
        downloadCert: "Yeterlilik Belgesini İndirin",
        questionNumber: "Soru",
        currentPassword: "Mevcut Şifre",
        certificantDirectory: "Çevik Profesyonellerin Uluslararası Kaydı",
        validSince: "Geçerlilik Başlangıcı:",
        lastRenewed: "Son Yenileme:",
        expires: "Bitiş Tarihi:",
        renew: "Yenile",
        download: "İndir",
        newsletter: "Scrum Inc. Lisanslı Scrum Bülteni ve e-posta güncellemeleri",
        renewCredential: "Yeterlilik Belgesini Yenile",
        renewalNotice: "Kimlik bilgilerinizi yenilemek için yenileme ücretini ödemelisiniz. Daha sonra bir yenileme sınavına girebileceksiniz ve sınavı geçtikten sonra kimlik bilgileriniz yenilenecek ve 1 yıl geçerli olacaktır. Yenileme ücretinizi aşağıdaki güvenli ödeme sayfamızı kullanarak ödeyebilirsiniz.",
        registerClass: "Bir Sınıfa Kaydol",
        renewNow: "Şimdi Yenile",
        secureCheckout: "Stripe tarafından desteklenen güvenli ödeme",
        enterInviteCode: "Kaydolmak için lütfen sınıf davet kodunuzu veya davet URL'ini girin.",
        lspoDescription: "2 günlük yüz yüze Scrum Inc. Lisanslı Scrum Product Owner kursumuz, Product Owner rolünü ayrıntılı olarak ele almaktadır. Kursun bu çevrimiçi bileşeni bir sertifika testi içerir. Testinizi geçtikten sonra bir Scrum Inc. Lisanslı Scrum Product Owner olacaksınız ve resmi sertifikanızı indirebileceksiniz.",
        attemptsLeft: "denemeniz kaldı",
        reviewAnswers: "Lütfen sınavınızı göndermeden önce yanıtlarınızı gözden geçirin ve gerekli değişiklikleri yapın",
        didNotPass: "Sınavı geçemediniz.",
        didNotPassOneLeft: "Sınavı geçemediniz. Bir deneme hakkınız kaldı.",
        failed: "Başarısız oldu",
        errorMessage: "Bir şeyler yanlış gitti. Lütfen online-learning@scruminc.com adresinden bizimle iletişime geçin.",
        retakeExam: "Sınava Yeniden Gir",
        noMoreAttempts: "Bu sınava girmek için hakkınız kalmadı. Daha fazla deneme almak için lütfen eğitmeninizle iletişime geçin.",
        contactUs: "Bizimle iletişime geçin",
        subject: "Konu",
        message: "Mesaj",
        enterVirtualClassroom: "Sanal Sınıfa Girin",
        myStudentView: "Öğrenim Sayfam",
        examAnalysis: "Sınav Analizi",
        adminDashboard: "Yönetici Ana Panel",
        partners:"Partnerler",
        supportSuccess: "Destek kaydı başarıyla oluşturuldu. Destek saatlerimiz 10: 00-18: 00 EST'dir. En kısa sürede cevap vereceğiz. Teşekkür ederiz!",
        visitMyAchievementsTile: "Yeterlilik belgelerinizi görmek veya yenilemek için Başarılarım sayfasını ziyaret edin",
        total: "toplam",
        dueDate: "Ödeme tarihi",
        action: "Aksiyon",
        description: "Açıklama",
        status:"Statü",
        pay: 'Öde',
        receipt: "Fiş",
        pastDue: "Geçmiş Vade",
        open: "Aç",
        paid: "Ödendi",
        warning: "Uyarı",
        pleaseBilling: "Lütfen ayarlar sayfasında fatura bilgilerinizi doldurun",
        all: "Hepsi",
        thisWeek: "Bu hafta",
        nextWeek: "Gelecek Hafta",
        laterThisMonth: "Bu Ay Daha Sonra",
        futureClasses: "Gelecek Sınıflar",
        recentClasses: "Yeni Sınıflar ",
        back: "Önceki",
        next: "Sonraki",
        billingInformation: "Fatura bilgileri",
        billingReceiptInfo: "Bu bilgiler, herhangi bir fatura makbuzunda görüntülenecektir.",
        billingReceiptInfo2: "Fatura makbuzunun veya ödeme bağlantısının başka bir e-posta adresine gönderilmesine ihtiyacınız varsa (her zaman kendi kayıtlı e-postanıza bir makbuz alacaksınız) bunları buraya ekleyebilirsiniz. Katlar virgülle ayrılmalıdır. Örn. jane @ doe.com, john @ doe.com",
        viewModule: "Modülü Görüntüle",
        enterAValidEmail: "Lütfen geçerli bir eposta adresi giriniz",
        invalidResetLink: "Geçersiz Sıfırlama Bağlantısı.",
        passwordReset: "Şifre Sıfırlama",
        returnToLogin: "Giriş Sayfasına Dön",
        success: "Başarılı",
        confirmRemoveClass: "Sınıf Kaldırmayı Onaylayın",
        areYouSureRemoveClass: "Bu sınıfı kaldırmak istediğinizden emin misiniz? Bu, davet kodunu kullanılamaz hale getirecek ve şu anda kayıtlı tüm öğrencilerin erişimini kaldıracaktır.",
        cancel: "İptal",
        delete: 'Sil',
        associatedCourses: 'İlişkili Kurslar',
        content: 'İçerik',
        addContent: 'İçerik Ekle',
        name: 'İsim',
        filesAndResources:'Dosyalar ve Kaynaklar',
        classStartDate: 'Sınıf Başlama Tarihi',
        classEndDate: 'Sınıf Bitiş Tarihi',
        clients: 'Müşteriler',
        selectClient: 'Müşteri Seçin...',
        copy: 'kopyala',
        courseRegistrationUrl: 'Kurs Kayıt URLi',
        courseWebpageLink: "Kurs İnternet Web Sayfası Linkini Giriniz",
        selfSignupUrl: "SKendi Kendine Kayıt Url'si",
        inviteCodeExpiry: "Davet Kodu Son Kullanma Tarihi",
        instructors: 'Eğitmenler',
        location: 'Konum',
        maxUsers: 'Maksimum Kullanıcı',
        privateSession: 'Özel (Kapalı) Sınıf',
        removeClass: 'Sınıfı Kaldır',
        viewUser: 'Kullanıcıyı Göster',
        virtualSession: 'Sanal Oturum',
        virtualClassroomLink: 'Sanal Sınıf Bağlantısı',
    }
