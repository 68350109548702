import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AppBar, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import withLocalizedStrings from '../utils/withLocalizedStrings';

const FooterLink = styled('a')({
  textDecoration: 'none',
  color: 'gainsboro',
  cursor: 'pointer',
});

const Footer = ({ strings }) => {
  const isProd = process.env.NODE_ENV === 'production';

  return (
    <AppBar position="relative" sx={{ top: 'auto', bottom: 0 }}>
      <Box sx={{ display: 'block', padding: 1 }}>
        <Typography style={{ color: 'gainsboro' }}>
          © Scrum Inc. &nbsp;| &nbsp;
          <FooterLink
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.scruminc.com/terms-of-use/"
          >
            {strings.tos}
          </FooterLink>{' '}
          &nbsp;| &nbsp;
          <FooterLink
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.scruminc.com/privacy-policy/"
          >
            {strings.pp}
          </FooterLink>
          &nbsp;| &nbsp;
          {isProd ? null : (
            <>
              <FooterLink
                target="_blank"
                rel="noreferrer noopener"
                href={`/changelog`}
              >
                Changelog
              </FooterLink>
              &nbsp;| &nbsp;
            </>
          )}
          <FooterLink
            target="_blank"
            rel="noreferrer noopener"
            href={`${process.env.REACT_APP_AEP_DOMAIN}/contact-us/`}
          >
            {strings.contactUs}
          </FooterLink>
        </Typography>
      </Box>
    </AppBar>
  );
};

Footer.propTypes = {
  strings: PropTypes.object,
};

const mapState = (state) => {
  return { visible: state.ui.footer };
};

const FooterWithProps = connect(mapState)(Footer);

export default withLocalizedStrings(FooterWithProps);
