import * as types from '../actions/users/types';
import { RESET_QUIZ } from '../actions/sessions/types';

const initialState = {
  users: {
    docs: [],
    page: 1,
    totalDocs: 0,
  },
  user: null,
  userProgress: null,
  loading: false,
  error: null,
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case types.SET_USER_PROGRESS:
      return {
        ...state,
        userProgress: action.payload,
      };
    case RESET_QUIZ:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return { ...initialState, ...state };
  }
};

export default reducer;
