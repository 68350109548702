import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addGroupDialogOpen: false,
};

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setAddGroupDialogOpen: (state, { payload: addGroupDialogOpen }) => {
      state.addGroupDialogOpen = addGroupDialogOpen;
    },
  },
});

export default groupsSlice.reducer;
export const actions = groupsSlice.actions;
export const { setAddGroupDialogOpen } = groupsSlice.actions;

export const selectAddGroupDialogOpen = (state) =>
  state.groups.addGroupDialogOpen;
