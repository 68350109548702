import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ROOT_URL } from 'App';

import { logoutUser, setCredentials } from 'features/auth/slice';
import providesTagsHelper from './helpers/providesTags';
import admin from './admin';
import agilityAssessment from './agilityAssessment';
import auth from './auth';
import changelog from './changelog';
import companies from './companies';
import contentLibrary from './contentLibrary';
import coupons from './coupons';
import courses from './courses';
import credentials from './credentials';
import fileModules from './fileModules';
import groups from './groups';
import htmlModules from './htmlModules';
import invoices from './invoices';
import organizations from './organizations';
import partners from './partners';
import playlists from './playlists';
import quizModules from './quizModules';
import quizzes from './quiz';
import ratings from './ratings';
import renewals from './renewals';
import sessions from './sessions';
import surveyModules from './surveyModules';
import teams from './teams';
import users from './users';
import videoModules from './videoModules';
import subscriptionProducts from './subscriptionProducts';
import ai from './ai';

// re-exporting to lazily handle places where we are importing from here
export { providesTagsHelper };

const baseQuery = fetchBaseQuery({
  baseUrl: ROOT_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;
    if (accessToken) {
      headers.set('authorization', `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 401) {
    const refreshToken = api.getState().auth.refreshToken;

    // If we don't have a refresh token, we can't refresh the token, log the user out completely to clear up state
    if (!refreshToken) {
      api.dispatch(logoutUser());
      return result;
    }

    // Try to refresh the access token and user using the refresh token
    const refreshResult = await baseQuery(
      {
        url: '/auth/refresh',
        method: 'POST',
        body: { refreshToken },
      },
      api,
      extraOptions
    );

    if (refreshResult?.data) {
      // Update the credentials in the store
      api.dispatch(setCredentials(refreshResult.data));

      // Retry the initial query npw that auth is fresh
      return baseQuery(args, api, extraOptions);
    }

    // If we got here, we should log the user out to clear up the state
    api.dispatch(logoutUser());
  }
  return result;
};

const getEndpoints = (builder) => {
  const endpointImports = [
    ai,
    admin,
    agilityAssessment,
    auth,
    changelog,
    companies,
    contentLibrary,
    courses,
    coupons,
    credentials,
    fileModules,
    groups,
    htmlModules,
    invoices,
    organizations,
    partners,
    playlists,
    quizModules,
    quizzes,
    ratings,
    renewals,
    sessions,
    surveyModules,
    teams,
    users,
    videoModules,
    contentLibrary,
    subscriptionProducts,
  ];
  return endpointImports.reduce(
    (endpoints, fn) => ({ ...endpoints, ...fn(builder) }),
    {}
  );
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Achievement',
    'AgilityAssessmentQuestionnaire',
    'AgilityAssessmentStatistics',
    'AgilityAssessmentAnalysis',
    'AssessmentId',
    'AuthUser',
    'Company',
    'CompanySetting',
    'ContentLibrary',
    'Coupon',
    'CompanyTeam',
    'CompanyUser',
    'CompanySubscription',
    'Course',
    'FileModule',
    'Group',
    'HTMLModule',
    'InviteCode',
    'Invoice',
    'InvoiceEdit',
    'NonOrgCompany',
    'Organization',
    'OrganizationUser',
    'Partner',
    'PartnerTrainers',
    'PokerRoom',
    'Playlist',
    'Quiz',
    'QuizModule',
    'QuizModuleQuestion',
    'QuizModuleQuestionGroup',
    'Report',
    'Session',
    'SessionAgileSurvey',
    'SessionKpiReport',
    'SurveyModule',
    'SurveyResponse',
    'SubscriptionProduct',
    'Team',
    'Trainer',
    'User',
    'UserCourse',
    'UserCourseProgress',
    'UserProgress',
    'VideoModule',
  ],
  endpoints: getEndpoints,
});

export const {
  endpoints,
  // auth endpoints
  useImpersonateUserMutation,
  useLazyGetAuthUserQuery,
  useLazyValidateRecaptchaQuery,
  useLoginMutation,
  useAdminResetPasswordMutation,
  useResetPasswordMutation,
  useUpdateUserPasswordMutation,
  useGetResetTokenMutation,
  useUpdateUserInfoMutation,
  useUpdateUserPhotoMutation,
  useUpdateUserFieldMutation,
  useSignUpMutation,
  useUpdateUserLanguageMutation,
  // session endpoints
  useGetSessionsQuery,
  useLazyGetSessionsQuery,
  useCreateSessionMutation,
  useGetSessionQuery,
  useUpdateSessionMutation,
  useRemoveSessionMutation,
  useAddSessionContentMutation,
  useRemoveSessionContentMutation,
  useUnlockSessionContentMutation,
  useRemoveUserFromSessionMutation,
  useAddUserToSessionMutation,
  useSetSessionCompleteMutation,
  // client/group endpoints
  useGetGroupsQuery,
  useCreateGroupMutation,
  useGetGroupQuery,
  useListGroupsQuery,
  // trainer endpoints
  useAddTrainerMutation,
  // course endpoints
  useGetCoursesByTrainersQuery,
  useGetCoursesQuery,
  useGetCoursesForLookupQuery,
  useGetCourseQuery,
  useAddCourseMutation,
  useAddCourseModuleMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseSectionModulesMutation,
  // file module endpoints
  useGetFileModuleQuery,
  // survey module endpoints
  useGetSurveyModuleQuery,
  useUpdateSurveyModuleQuestionMutation,
  useAddSurveyModuleQuestionMutation,
  useEditSurveyModuleQuestionMutation,
  // rating endpoint
  useCreateInstructorRatingMutation,
  useMergeUsersMutation,
  // credential endpoint
  useGetCredentialTypesQuery,
  // quiz endpoints
  useGetQuizQuery,
  useSelectAnswerMutation,
  useSubmitQuizMutation,
  useUpdateQuizQuestionMutation,
  // user endpoints
  useRegisterCourseMutation,
  // team endpoints
  useAssignTeamCourseQuery,
  useListTeamUsersQuery,
  useGetTeamQuery,
  useGetTeamsQuery,
  useLazyListTeamsQuery,
  useLazyGetTeamExportQuery,
  useAssignUsersCourseMutation,
  useUnassignUsersCourseMutation,
  // company endpoints
  useListCompaniesQuery,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useUpdateCompanyCourseMutation,
  useDeleteCompanyMutation,
  useGetCompanyTeamsQuery,
  useListCompanyUsersQuery,
  useLazyGetCompanyReportQuery,
  useAddCompanyTeamMutation,
  useAddTeamCoursesMutation,
  useDeleteCompanyTeamMutation,
  useUpdateTeamCourseSeatsMutation,
  useAddCompanyUserMutation,
  useRemoveCompanyUserMutation,
  useGetCompanyUserQuery,
  useUpdateCompanyUserMutation,
  useGetExamAnalyisQuery,
  useLazyGetExamAnalyisQuery,
  useTransferUsersTeamMutation,
  useAddCompanyCourseMutation,
  useGetCoursesListQuery,
  // coupon endpoints
  useListCouponsQuery,
  useCreateCouponMutation,
  useDeleteCouponMutation,
  useUpdateCouponMutation,
} = api;

export default api;
