import axios from 'axios';

import providesTags from './helpers/providesTags';

export default function videoModules(builder) {
  return {
    getVideoModule: builder.query({
      query: (id) => ({
        url: `/VideoModule/${id}`,
      }),
      providesTags: (result) => providesTags({ type: 'VideoModule', result }),
    }),
    updateVideoModule: builder.mutation({
      query: ({ _id: moduleId, ...updates }) => ({
        url: `/VideoModule/${moduleId}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['VideoModule'],
    }),
    addFilesToVideoModule: builder.mutation({
      query: (videoModule) => ({
        url: `/VideoModule/addFiles/${videoModule}`,
      }),
      invalidatesTags: ['VideoModule'],
    }),
    updateVideoProgress: builder.mutation({
      query: ({ videoModule, session, progress }) => {
        const { total, start } = progress;
        const percent = (start / total).toFixed(2) * 100;
        if (percent > 90) {
          progress.complete = true;
        }

        return {
          url: `/VideoModule/update_progress/${videoModule}/${session}`,
          method: 'POST',
          body: progress,
        };
      },
    }),
    updateVideoThumbnail: builder.mutation({
      query: (moduleId) => ({
        url: `/VideoModule/${moduleId}/thumbnail`,
        method: 'PUT',
      }),
      invalidatesTags: ['VideoModule', 'ContentLibrary'],
    }),
    uploadVideoFile: builder.mutation({
      async queryFn(
        { moduleId, file, textTrack, onUploadProgress = () => {} },
        _api,
        _extraOptions,
        fetchWithBQ
      ) {
        const extension = file.name.split('.').pop();

        let name;
        if (textTrack && file.type === 'text/vtt') {
          name = `${moduleId}.${textTrack.language}.${extension}`;
        } else {
          name = `${moduleId}.${extension}`;
        }

        const response = await fetchWithBQ({
          url: `/VideoModule/${moduleId}/upload/start`,
          method: 'POST',
          body: {
            name,
            type: file.type,
          },
        });

        const axiosInstance = axios.create({ headers: {} });

        await axiosInstance.put(response.data.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress,
        });

        const finishResponse = await fetchWithBQ({
          url: `/VideoModule/${moduleId}/upload/finish`,
          method: 'POST',
          body: {
            url: response.data.url,
            moduleId,
            contentType: file.type,
            ...(textTrack && { textTrack }),
          },
        });

        if (finishResponse) {
          return { data: finishResponse.data };
        } else {
          return { error: response.error };
        }
      },
      invalidatesTags: ['VideoModule'],
    }),
  };
}
