import providesTagsHelper from './helpers/providesTags';

export default function surveyModules(builder) {
  return {
    getSurveyModule: builder.query({
      query: (id) => `/SurveyModule/${id}`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'SurveyModule', result }),
    }),
    updateSurveyModule: builder.mutation({
      query: ({ id, ...updates }) => ({
        url: `/SurveyModule/${id}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['SurveyModule'],
    }),
    updateSurveyModuleQuestionOrder: builder.mutation({
      query: ({ id, questionId, index }) => ({
        url: `/SurveyModule/${id}/questions/${questionId}/order`,
        method: 'PUT',
        body: {
          index,
        },
      }),
      invalidatesTags: ['SurveyModule'],
    }),
    addSurveyModuleQuestion: builder.mutation({
      query: ({ id, questionType, questionText }) => ({
        url: `/SurveyModule/${id}/questions`,
        method: 'POST',
        body: {
          questionType,
          questionText,
        },
      }),
      invalidatesTags: ['SurveyModule'],
    }),
    editSurveyModuleQuestion: builder.mutation({
      query: ({ id, questionId, questionType, questionText }) => ({
        url: `/SurveyModule/${id}/questions/${questionId}`,
        method: 'PUT',
        body: {
          questionType,
          questionText,
        },
      }),
      invalidatesTags: ['SurveyModule'],
    }),
    deleteSurveyModuleQuestion: builder.mutation({
      query: ({ id, questionId }) => ({
        url: `/SurveyModule/${id}/questions/${questionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SurveyModule'],
    }),
  };
}
