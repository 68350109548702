import React from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {
  Group as GroupIcon,
  CalendarToday as SessionIcon,
  QuestionAnswer as FeedbackIcon,
  Assessment as ReportIcon,
  Person as PeopleIcon,
  Payment as PaymentIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function TrainerListItems() {
  const { t } = useTranslation(['common']);

  return (
    <List>
      <ListSubheader disableSticky>{t('common:nav.trainers')}</ListSubheader>
      <ListItem
        button
        component={Link}
        to="/trainer"
        data-cy="trainer-dashboard"
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.trainerDashboard')} />
      </ListItem>
      <ListItem button component={Link} to="/sessions" data-cy="sessions">
        <ListItemIcon>
          <SessionIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.sessions')} />
      </ListItem>
      <ListItem button component={Link} to="/my-feedback" data-cy="feedback">
        <ListItemIcon>
          <FeedbackIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.feedback')} />
      </ListItem>
      <ListItem button component={Link} to="/groups" data-cy="groups">
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.clients')} />
      </ListItem>
      <ListItem button component={Link} to="/exam-analysis" data-cy="reports">
        <ListItemIcon>
          <ReportIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.examAnalysis')} />
      </ListItem>
      <ListItem button component={Link} to="/users" data-cy="users">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.students')} />
      </ListItem>
      <ListItem button component={Link} to="/billing" data-cy="billing">
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.billing')} />
      </ListItem>
    </List>
  );
}
