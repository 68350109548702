const calculateProgress = ({
  user,
  modules,
  inProgress = false,
  sessionName = '',
}) => {
  let { total, complete } = modules.reduce(
    (acc, m) => {
      if (m.module_id?.required) {
        acc.total += 1;
        if (
          user?.progress[m.module_id._id]?.complete ||
          user?.progress[m.module_id._id]?.completed
        ) {
          acc.complete += 1;
        }
      }
      return acc;
    },
    { total: 0, complete: 0 }
  );

  // report any legacy classes as complete
  const isLegacy = sessionName.includes('Legacy');
  if (isLegacy) {
    return {
      total: 0,
      complete: 0,
      percentage: 100,
    };
  }

  // make sure to keep upcoming classes in progress
  if (inProgress && total === 0) {
    total += 1;
  }

  const percentage = total === 0 ? 0 : (complete / total).toFixed(2) * 100;
  return { total, complete, percentage };
};

export default calculateProgress;
