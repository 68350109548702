import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasAccess: false,
  dialogOpen: false,
  introMessage: null,
  firstMessage: null,
  threadId: null,
  dimension: null,
  surveyId: null,
};

export const AISlice = createSlice({
  name: 'ai',
  initialState,
  reducers: {
    setAccess: (state, { payload = false }) => {
      state.hasAccess = payload;
    },
    setDialogOpen: (state, { payload = false }) => {
      state.dialogOpen = payload;
    },
    setIntroMessage: (state, { payload = null }) => {
      state.introMessage = payload;
    },
    setFirstMessage: (state, { payload = null }) => {
      state.firstMessage = payload;
    },
    setThreadId: (state, { payload = null }) => {
      state.threadId = payload;
    },
    setDimension: (state, { payload = null }) => {
      state.dimension = payload;
    },
    setSurveyId: (state, { payload = null }) => {
      state.surveyId = payload;
    },
  },
});

export const { setAccess, setDialogOpen } = AISlice.actions;
export const actions = AISlice.actions;
export default AISlice.reducer;

export const selectHasAccess = (state) => state.ai.hasAccess;
export const selectDialogOpen = (state) => state.ai.dialogOpen;
export const selectIntroMessage = (state) => state.ai.introMessage;
export const selectFirstMessage = (state) => state.ai.firstMessage;
export const selectThreadId = (state) => state.ai.threadId;
export const selectDimension = (state) => state.ai.dimension;
export const selectSurveyId = (state) => state.ai.surveyId;
