import React, { useEffect } from 'react';
import { Backdrop, Box, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import './App.css';
import theme from 'app/theme';
import { useLazyGetAuthUserQuery } from 'app/api';
import { setUser } from 'features/auth/slice';
import useAnalytics from 'hooks/useAnalytics';
import Loading from './components/Loading';
import Router from './router';

import { setLocale } from 'actions/interface';
import MaintenanceScreen from 'features/maintenance/MaintenanceScreen';

const isProduction = process.env.REACT_APP_ENV === 'production';
const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE === 'true';
export const ROOT_URL = process.env.REACT_APP_ROOT_URL;

if (isProduction) {
  ReactGA.initialize('G-TQZRT05WYL');
}

const App = () => {
  useAnalytics();
  const dispatch = useDispatch();
  const { accessToken, user, isImpersonating } = useSelector(
    (state) => state.auth
  );
  const locale = useSelector((state) => state.ui.locale);
  const [getAuthUser, { data, isFetching }] = useLazyGetAuthUserQuery({
    refetchOnReconnect: true,
    polling: 1000 * 60,
  });
  const { i18n } = useTranslation();
  const location = useLocation();

  // on load, fetch the user using the token
  useEffect(() => {
    if (!user && accessToken) getAuthUser();
  }, [getAuthUser, accessToken, user]);

  // once the user data loaded, set the user in redux
  useEffect(() => {
    if (!isFetching && data) {
      dispatch(setUser({ user: data }));
    }
  }, [data, isFetching, dispatch]);

  useEffect(() => {
    // exception for tw because of our mix of scrumlab-translations using 'tw' but i18next needing it to be 'zh-TW'
    // updates the locale in redux if it's not the same as  the user's preferred language
    const language = user?.language === 'tw' ? 'zh-TW' : user?.language;
    if (user && locale !== language) {
      dispatch(setLocale(user.language));
      i18n.changeLanguage(language);
    }
    // set or clear the user in the Sentry context/scope so we can identify the user in issues
    if (isProduction) {
      if (user) {
        Sentry.setUser({ id: user._id, username: user.username });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    }
  }, [dispatch, i18n, locale, user, isImpersonating]);

  useEffect(() => {
    // Ensure Intercom is loaded and available
    if (typeof window?.Intercom === 'function') {
      // Regular expression to match the dynamic pathname segment
      const pattern = /^\/agility-assessment\/survey\/.+$/;

      let hideLauncher = false;

      // Show or hide the Intercom bubble based on the route
      if (pattern.test(location.pathname)) {
        hideLauncher = true;
        window.Intercom('hide');
      }

      // Handle user authentication and impersonation logic
      if (user && !isImpersonating) {
        window.Intercom('boot', {
          app_id: 'nbkd2of5',
          name: user?.full_name, // Assuming 'full_name' is the correct property
          email: user?.username, // Assuming 'username' contains the email
          user_id: user?._id,
          created_at: user?.created_at,
          user_hash: user?.hash,
          hide_default_launcher: hideLauncher,
        });
      } else {
        window.Intercom('boot', {
          app_id: 'nbkd2of5',
          hide_default_launcher: hideLauncher,
        });
      }
    }
  }, [location, user, isImpersonating]);

  if (isFetching) {
    return (
      <Backdrop
        open
        sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Loading />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor:
          window.self === window.top ? theme.palette.gray.light : '#FFFFFF',
      }}
    >
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {isUnderMaintenance ? <MaintenanceScreen /> : <Router />}
          <ToastContainer
            position="top-right"
            autoClose={2000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            limit={1}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
};

// elias is cool
export default App;
