export const UPDATE_QUIZ_MODULE = 'UPDATE_QUIZ_MODULE';
export const UNSET_QUIZ_MODULE = 'UNSET_QUIZ_MODULE';
export const UPDATE_QUIZ_QUESTIONS = 'UPDATE_QUIZ_QUESTIONS';
export const SET_QUIZ_QUESTION = 'SET_QUIZ_QUESTION';
export const UPDATE_QUIZ_QUESTION = 'UPDATE_QUIZ_QUESTION';
export const UPDATE_QUIZ_QUESTION_ANSWERS = 'UPDATE_QUIZ_QUESTION_ANSWERS';
export const UPDATE_QUIZ_QUESTION_BODY = 'UPDATE_QUIZ_QUESTION_BODY';
export const UPDATE_QUIZ_QUESTION_ACTIVE = 'UPDATE_QUIZ_QUESTION_ACTIVE';
export const UPDATE_CORRECT_ANSWER = 'UPDATE_CORRECT_ANSWER';
export const EDIT_ANSWER_CONTENT = 'EDIT_ANSWER_CONTENT';
export const SET_USER_QUIZ = 'SET_USER_QUIZ';
export const UNSET_USER_QUIZ = 'UNSET_USER_QUIZ';
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const CHANGE_CURRENT_QUESTION = 'CHANGE_CURRENT_QUESTION';
export const SHOW_QUIZ_RESULTS = 'SHOW_QUIZ_RESULTS';
export const SUBMITTING_QUIZ = 'SUBMITTING_QUIZ';
export const UPDATE_QUIZ_LOCALE = 'UPDATE_QUIZ_LOCALE';
