/* eslint-disable import/no-anonymous-default-export */
import * as types from '../actions/reporting/types';

export default (state = {}, action) => {
  switch (action.type) {
    case types.SET_REPORT: {
      return {
        ...state,
        trainers: action.payload.trainers,
        trainer_data: action.payload.trainer_data,
        session_data: action.payload.session_data,
        student_data: action.payload.student_data,
        renewal_data: action.payload.renewal_data,
      };
    }
    default:
      return { ...state };
  }
};
