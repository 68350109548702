import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  joyride: {
    run: false,
    stepIndex: 0,
  },
  activeTab: 'students',
  achievementStatus: null,
  achievementType: null,
  achievementTypes: [],
  search: '',
  order: 'asc',
  orderBy: 'user.full_name',
  page: 0,
  limit: 25,
  courseFilter: 'all',
  selectedStudents: [],
  addAdminDialogOpen: false,
  courseAdminDialogOpen: false,
  confirmRemoveAdminDialog: {
    open: false,
    name: '',
    userId: null,
  },
  confirmInviteCodeDialog: {
    open: false,
    inviteCodeId: null,
    teamId: null,
    companyId: null,
  },
  addStudent: {
    open: false,
  },
  manageSubscriptionDialog: {
    open: false,
  },
  enableSubscriptions: false,
  subscriptionFilter: null,
  playlists: {
    edit: {
      open: false,
      playlistId: null,
      playlistHash: null, // used to compare for unsaved changes
      playlist: {
        name: '',
        modules: [],
        assigned_to: [],
      },
    },
  },
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    reset: (state) => {
      for (const key in initialState) {
        if ({}.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key];
        }
      }
    },
    setJoyrideStepIndex: (state, { payload: stepIndex = 0 }) => {
      state.joyride.stepIndex = stepIndex;
    },
    setJoyrideRun: (state, { payload: run = false }) => {
      state.joyride.run = false;
    },
    setActiveTab: (state, { payload: activeTab = 'students' }) => {
      state.activeTab = activeTab;
    },
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      } else {
        state.search = '';
      }
      state.page = 0;
    },
    setOrder: (state, { payload: order }) => {
      state.order = order;
    },
    setOrderBy: (state, { payload: orderBy }) => {
      state.orderBy = orderBy;
    },
    setPage: (state, { payload: page }) => {
      state.page = page;
    },
    setLimit: (state, { payload: limit = 100 }) => {
      state.limit = limit;
    },
    setCourseFilter: (state, { payload: courseFilter = 'all' }) => {
      state.courseFilter = courseFilter;
    },
    setSelectedStudents: (state, { payload: userId }) => {
      if (userId === 'clear') {
        state.selectedStudents = [];
      } else {
        const selectedIndex = state.selectedStudents.findIndex(
          (id) => id === userId
        );
        if (selectedIndex === -1) {
          state.selectedStudents.push(userId);
        } else if (selectedIndex >= 0) {
          state.selectedStudents.splice(selectedIndex, 1);
        }
      }
    },
    addSelectedStudent: (state, { payload: { id, totalProgress } }) => {
      const selectedIndex = state.selectedStudents.findIndex(
        ({ id: userId }) => userId === id
      );
      if (selectedIndex === -1) {
        state.selectedStudents.push({ id, totalProgress });
      }
    },
    removeSelectedStudent: (state, { payload: userId }) => {
      state.selectedStudents = state.selectedStudents.filter(
        ({ id }) => id !== userId
      );
    },
    setSelectedAll: (state, { payload = [] }) => {
      state.selectedStudents = payload;
    },
    setAddAdminDialogOpen: (state, { payload: open = false }) => {
      state.addAdminDialogOpen = open;
    },
    setCourseAdminDialogOpen: (state, { payload: open = false }) => {
      state.courseAdminDialogOpen = open;
    },
    toggleConfirmInviteCodeDialog: (state, { payload = false }) => {
      state.confirmInviteCodeDialog.open = payload;
      // reset the inviteCodeId when the dialog closes
      if (!payload) {
        state.confirmInviteCodeDialog.inviteCodeId = null;
        state.confirmInviteCodeDialog.teamId = null;
        state.confirmInviteCodeDialog.companyId = null;
      }
    },
    setInviteCodeId: (
      state,
      { payload: { inviteCodeId = null, teamId = null, companyId = null } = {} }
    ) => {
      state.confirmInviteCodeDialog.inviteCodeId = inviteCodeId;
      state.confirmInviteCodeDialog.teamId = teamId;
      state.confirmInviteCodeDialog.companyId = companyId;
      // opens the dialog if the inviteCodeId is being set
      if (inviteCodeId) {
        state.confirmInviteCodeDialog.open = true;
      }
    },
    setAchievementStatus: (state, { payload }) => {
      state.achievementStatus = payload;
    },
    setAchievementType: (state, { payload }) => {
      state.achievementType = payload;
    },
    setAchievementTypes: (state, { payload = [] }) => {
      state.achievementTypes = payload;
    },
    resetStudentsFilters: (state) => {
      state.achievementStatus = null;
      state.achievementType = null;
      state.courseFilter = 'all';
    },
    openAddStudentDialog: (state) => {
      state.addStudent.open = true;
    },
    closeAddStudentDialog: (state) => {
      state.addStudent.open = false;
    },
    setManageSubscriptionDialog: (
      state,
      { payload = initialState.manageSubscriptionDialog }
    ) => {
      state.manageSubscriptionDialog = payload;
    },
    setEnableSubscriptions: (state, { payload = false }) => {
      state.enableSubscriptions = payload;
    },
    setSubscriptionFilter: (state, { payload = null }) => {
      state.subscriptionFilter = payload;
    },
    setPlaylistEditDialog: (state, { payload }) => {
      if (!payload) {
        // reset to initial state if no payload is passed
        state.playlists.edit = initialState.playlists.edit;
      } else {
        // otherwise update just the properties passed through the payload
        state.playlists.edit = {
          ...state.playlists.edit,
          ...payload,
        };
      }
    },
  },
});

export default teamsSlice.reducer;

export const actions = teamsSlice.actions;
export const {
  reset,
  setSearch,
  setOrder,
  setOrderBy,
  setPage,
  setLimit,
  setCourseFilter,
  setSelectedStudents,
  setAddAdminDialogOpen,
  setCourseAdminDialogOpen,
  toggleConfirmInviteCodeDialog,
  setInviteCodeId,
} = actions;

export const selectSlice = (state) => state.teams;
export const selectCourseFilter = (state) => selectSlice(state).courseFilter;
export const selectSelectedStudents = (state) =>
  selectSlice(state).selectedStudents;
export const selectAddAdminDialogOpen = (state) =>
  selectSlice(state).addAdminDialogOpen;
export const selectCourseAdminDialogOpen = (state) =>
  selectSlice(state).courseAdminDialogOpen;
export const selectConfirmInviteCodeDialog = (state) =>
  state.teams.confirmInviteCodeDialog;
export const selectJoyride = (state) => state.teams.joyride;
export const selectActiveTab = (state) => state.teams.activeTab;
export const selectPlaylistEditDialog = (state) => state.teams.playlists.edit;
