module.exports =
    {
        language: "Português-br",
        username: "Usuário",
        password: "Senha",
        welcome: "Bem vindo(a)",
        home: "Dashboard",
        sessions: "Aulas",
        courses: "Tipos de Cursos",
        people: "Alunos",
        groups: "Clientes",
        reports: "Relatórios",
        login: "Login",
        loginMessage: "Digite seu email e senha para acessar a plataforma online Scrum",
        forgotPassword: "Esqueceu sua senha?",
        privacyPolicy: "Política de Privacidade",
        viewCourse: "Ver Curso",
        recent: "Recentes",
        viewCredential: "Ver credencial",
        modules: "Módulos",
        logout: "Logout",
        help: "Ajuda",
        firstName: "Primeiro Nome",
        lastName: "Último nome",
        email: "Email",
        confirm: "Confirmar",
        register: "Registrar",
        myAchievements: "Minhas realizações",
        settings: "Configurações",
        passwordRequirements: "Sua senha deve conter pelo menos um caractere maiúsculo e um minúsculo, pelo menos um número e ter entre 8 e 20 caracteres",
        passed: "Aprovado(a)!",
        checkResultsBelow: "Você pode ver seus resultados abaixo",
        downloadCredential: "Baixar credencial",
        returnToDashboard: "Voltar para Dashboard",
        billingLink: "Faturamento",
        resetInstructions: "Digite seu nome de usuário (normalmente seu email) e nós lhe enviaremos instruções para redefinir sua senha.",
        resetSuccess: "Sucesso! Verifique seu e-mail. Se houver uma conta de usuário associada a este e-mail, você receberá instruções sobre como redefinir sua senha.",
        enterNew: "Insira a nova senha",
        confirmNew: "Confirme a nova senha",
        reset: "Redefinir",
        createAccount: "Para criar sua conta, insira seus dados e use o código de convite que seu instrutor forneceu",
        selectCountry: "Selecione o pais",
        inviteCode: "Código de convite",
        acceptTerms: "Li e aceito os Termos de Serviço e a Política de Privacidade",
        newsletterCheckbox: "Gostaria de receber o boletim informativo da Scrum Inc. e as ofertas mais recentes",
        alreadyHaveAccount: "já tem uma conta? Entre aqui",
        dashboard: "Dashboard",
        visitMyAchievements: "Visite a página Minhas realizações para baixar suas credenciais",
        registerWithCode: "Registre-se com o código do seu curso",
        rateInstructor: "Avalie o seu instrutor(a)",
        rateExperience: "Como você classificaria sua experiência de aprendizado com [instructor name]",
        instructorFeedback: "O que [instructor name] fez bem? O que [instructor name] poderia fazer para melhorar a experiência de aprendizado? NOTA: Esse feedback será completamente anônimo para o seu instrutor.",
        additionalFeedback: "Comentários adicionais (opcional)",
        returnDashboard: "Voltar para Dashboard",
        submit: "Enviar",
        lsmDescription: "Nosso curso de 2 dias Scrum Master é uma introdução à estrutura do Scrum. Ele analisa a função Scrum Master em detalhes. Este componente online do curso contém um teste de certificação. Ao passar no teste, você será um Scrum Master licenciado e poderá fazer o download do seu certificado oficial.",
        lsmExam: "Exame Scrum Master",
        files: "Arquivos",
        noCertificates: "Você não possui nenhum certificado... ainda! Depois de concluir e passar em um dos nossos exames, você encontrará suas credenciais para download aqui.",
        selectLanguage: "Selecione seu idioma de preferência",
        save: "Salvar",
        uploadImage: "Enviar Imagem",
        profilePicture: "Foto do perfil",
        userInfo: "Informação do usuário",
        socialsBio: "Sociais e Bio",
        website: "Website",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Bio",
        changePassword: "Mudar senha",
        changeOptIn: "Alterar Opt-Ins",
        congratulationsMessage: "Parabéns! Você passou! Você receberá uma credencial em seu email em breve. Você pode revisar seus resultados agora.",
        directoryOptIn: "Diretório Opt-In",
        certificantDirectoryMessage: "Eu gostaria de ser adicionado ao Registro Internacional de Profissionais Ágeis",
        close: "Fechar",
        correct: "Correto",
        viewResults: "Você pode ver seus resultados abaixo",
        downloadCert: "Download Credential",
        questionNumber: "Pergunta [1] de [30]",
        currentPassword: "senha atual",
        certificantDirectory: "Registro Internacional de Profissionais Ágeis",
        validSince: "Válido desde:",
        lastRenewed: "renovado pela última vez:",
        expires: "Expira:",
        newsletter: "Scrum Newsletter & email",
        registerClass: "Registrar uma turma",
        enterInviteCode: "Digite o código de convite da turma ou o URL do convite para se registrar.",
        lspoDescription: "Nosso curso de Scrum Product Owner, de 2 dias, cobre detalhadamente a função do Product Owner. Este componente online do curso contém um teste de certificação. Ao passar no teste, você será um Scrum Product Owner e poderá fazer o download do seu certificado oficial.",
        attemptsLeft: "2 tentativas restantes",
        reviewAnswers: "Revise suas respostas e faça as alterações antes de enviar seu exame",
        didNotPass: "Você não passou no exame.",
        didNotPassOneLeft: "Você não passou no exame. Você tem uma tentativa restante.",
        failed: "Falhou",
        retakeExam: "Retomar o exame",
        noMoreAttempts: "Você ficou sem tentativas para fazer esse exame. Entre em contato com seu instrutor para receber mais tentativas.",
        contactUs: "Contate-Nos",
        subject: "Assunto",
        message: "mensagem",
        enterVirtualClassroom: "Entrar na sala de aula virtual",
        startExam: "Iniciar Exame",
        incomplete: "Incompleto",
        complete: "Completo",
        completed: "Completo",
    }
