import { api } from './';
import providesTagsHelper from './helpers/providesTags';

export default function partners(builder) {
  return {
    getPartners: builder.query({
      query: () => `/partners`,
      providesTags: (result) => providesTagsHelper({ type: 'Partner', result }),
    }),
    getPartnersProviders: builder.query({
      query: ({ partnerType = 'All Partners', fullName, country, region }) => {
        const params = new URLSearchParams({
          partnerType,
          ...(fullName && fullName.length && { fullName }),
          ...(country && { country }),
          ...(region && { region }),
        });
        return `/partners/providers?${params.toString()}`;
      },
    }),
    getPartnersTrainers: builder.query({
      query: ({ partnerType = 'All Partners', fullName, country }) => {
        const params = new URLSearchParams({
          partnerType,
          ...(fullName && fullName.length && { fullName }),
          ...(country && { country }),
        });
        return `/partners/trainers?${params.toString()}`;
      },
      providesTagsHelper: (result) =>
        providesTagsHelper({ type: 'PartnerTrainers', result }),
    }),
    getPartner: builder.query({
      query: (id) => `/partners/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'Partner', id: result._id }] : [],
    }),
    createPartner: builder.mutation({
      query: (partner) => ({
        url: '/partners',
        method: 'POST',
        body: partner,
      }),
      invalidatesTags: ['Partner'],
    }),
    updatePartner: builder.mutation({
      query: ({ id, updates }) => ({
        url: `/partners/${id}`,
        method: 'POST',
        body: updates,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Partner', id: arg.id },
      ],
      async onQueryStarted({ id, updates }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('getPartner', id, (draft) => {
            Object.assign(draft, updates);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    deletePartner: builder.mutation({
      query: (id) => ({
        url: `/partners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Partner'],
    }),
    transferPartnerUser: builder.mutation({
      query: ({ userId, partnerId, transferClasses, partnerAdmin }) => ({
        url: `/partners/${partnerId}/transfer`,
        method: 'POST',
        body: {
          userId,
          transferClasses,
          partnerAdmin,
        },
      }),
      invalidatesTags: ['Partner', 'User', 'Trainer'],
    }),
  };
}
