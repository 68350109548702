import providesTagsHelper from './helpers/providesTags';

export default function getCouponsEndpoints(builder) {
  return {
    listCoupons: builder.query({
      query: () => '/coupons',
      providesTags: (result) => providesTagsHelper({ type: 'Coupon', result }),
    }),
    createCoupon: builder.mutation({
      query: (body) => ({ url: '/coupons', method: 'POST', body }),
      invalidatesTags: ['Coupon'],
    }),
    deleteCoupon: builder.mutation({
      query: ({ couponId }) => ({
        url: `/coupons/${couponId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coupon'],
    }),
    updateCoupon: builder.mutation({
      query: (body) => ({ url: '/coupons', method: 'PUT', body }),
      invalidatesTags: ['Coupon'],
    }),
  };
}
