import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { selectors, actions } from 'features/achievements/slice';

export default function ShoppingCartButton() {
  const dispatch = useDispatch();
  const { cart } = useSelector(selectors.selectRenew);

  function handleCartClick() {
    dispatch(actions.startRenewCheckout(true));
  }

  if (cart.length === 0) return null;

  return (
    <IconButton
      aria-label="show cart"
      color="inherit"
      disabled={cart.length === 0}
      onClick={handleCartClick}
      size="large"
    >
      <Badge badgeContent={cart.length} color="secondary" overlap="rectangular">
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
}
