import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 'credentialing',
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export default adminSlice.reducer;

export const actions = adminSlice.actions;
export const { setActiveTab } = actions;

export const selectActiveTab = (state) => state.admin.activeTab;
