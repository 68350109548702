import React from 'react';

import api from 'app/api';

export function useListAchievementsWithPolling() {
  const [pollingInterval, setPollingInterval] = React.useState(0);
  const results = api.useListAchievementsQuery(null, {
    pollingInterval,
  });

  // Polling interval is set to 2 seconds if there is an achievement with a processing payment
  React.useEffect(() => {
    if (
      results.data?.length &&
      results.data.some(
        ({ stripe_payment_status }) => stripe_payment_status === 'processing'
      )
    ) {
      setPollingInterval(2000);
    } else {
      setPollingInterval(0);
    }
  }, [setPollingInterval, results.data]);

  return results;
}

export default useListAchievementsWithPolling;
