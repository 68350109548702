import quizModuleReducer from './quizModuleReducer';
import sessionReducer from './sessionReducer';
import userReducer from './userReducer';
import groupReducer from './groupReducer';
import uiReducer from './uiReducer';
import reportingReducer from './reportReducer';

export default {
  ui: uiReducer,
  sessions: sessionReducer,
  groups: groupReducer,
  usersLegacy: userReducer,
  quizModule: quizModuleReducer,
  reports: reportingReducer,
};
