import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: '',
  canListClasses: false,
};

export const trainersSlice = createSlice({
  name: 'trainers',
  initialState,
  reducers: {
    setSearch: (state, { payload: search }) => {
      if (typeof search === 'string') {
        state.search = search;
      }
    },
    setCanListClasses: (state, { payload: canListClasses = false }) => {
      state.canListClasses = canListClasses;
    },
  },
});

export const actions = trainersSlice.actions;

export const { setSearch } = trainersSlice.actions;

export default trainersSlice.reducer;

export const selectSearch = (state) => state.trainers.search;
export const selectCanListClasses = (state) => state.trainers.canListClasses;
