import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(({ theme }) => ({
  backgroundColor: '#fff',
  color: 'rgba(0, 0, 0, 0.87)',
  minWidth: 220,
  maxWidth: 220,
  fontSize: theme.typography.pxToRem(10),
  border: '1px solid #dadde9',
}));

const TooltipContent = styled('div')({
  padding: 1,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 2,
  display: 'block',
});

const ScrumToolTip = ({ tooltipMessage, hidden, infoIconStyles, dataCy }) => {
  if (hidden) return null;
  return (
    <CustomTooltip
      data-cy={dataCy}
      placement="bottom-start"
      title={<TooltipContent>{tooltipMessage}</TooltipContent>}
    >
      <InfoIcon
        sx={{
          color: infoIconStyles?.color ?? '#fff',
          fontSize: infoIconStyles?.fontSize ?? 25,
          margin: (theme) => infoIconStyles?.margin ?? theme.spacing(0, 1),
        }}
      />
    </CustomTooltip>
  );
};

ScrumToolTip.propTypes = {
  dataCy: PropTypes.string,
  infoIconStyles: PropTypes.object,
  tooltipMessage: PropTypes.string,
  hidden: PropTypes.bool,
};

export default ScrumToolTip;
