import providesTags from './helpers/providesTags';

export default function getSubscriptionProductsEndpoints(builder) {
  return {
    listSubscriptionProducts: builder.query({
      query: () => `/subscription-products`,
      providesTags: (result) =>
        providesTags({ type: 'SubscriptionProduct', result }),
    }),
  };
}
