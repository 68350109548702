import providesTagsHelper from './helpers/providesTags';

export default function getTeamsEndpoints(builder) {
  return {
    createPlaylist: builder.mutation({
      query: ({ companyId, teamId, name }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/playlists`,
        body: { name },
      }),
      invalidatesTags: ['Playlist'],
    }),
    getPlaylists: builder.query({
      query: ({ companyId, teamId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/playlists`,
      }),
      providesTags: (result) =>
        providesTagsHelper({ type: 'Playlist', result }),
    }),
    getPlaylist: builder.query({
      query: ({ companyId, teamId, playlistId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/playlists/${playlistId}`,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        providesTagsHelper({ type: 'Playlist', result }),
    }),
    deletePlaylist: builder.mutation({
      query: ({ companyId, teamId, playlistId }) => ({
        method: 'DELETE',
        url: `/companies/${companyId}/teams/${teamId}/playlists/${playlistId}`,
      }),
      invalidatesTags: ['Playlist'],
    }),
    assignUsersToPlaylist: builder.mutation({
      query: ({ companyId, teamId, playlistId, userIds }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/playlists/${playlistId}/assign`,
        body: { userIds },
      }),
      invalidatesTags: ['Playlist'],
    }),
    unassignUsersFromPlaylist: builder.mutation({
      query: ({ companyId, teamId, playlistId, userIds }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/playlists/${playlistId}/unassign`,
        body: { userIds },
      }),
      invalidatesTags: ['Playlist'],
    }),
    updatePlaylist: builder.mutation({
      query: ({
        companyId,
        teamId,
        playlistId,
        name,
        modules,
        assigned_to,
      }) => ({
        method: 'PUT',
        url: `/companies/${companyId}/teams/${teamId}/playlists/${playlistId}`,
        body: { name, modules, assigned_to },
      }),
      invalidatesTags: ['Playlist'],
    }),
  };
}
