module.exports =
    {
        language: "Italiano",
        username: "Username",
        password: "Password",
        welcome: "Benvenuti",
        tos: 'Termini del Servizio',
        pp: 'Privacy Policy',
        home: "Dashboard",
        sessions: "Classi",
        courses: "Tipo di Corso",
        people: "Studenti",
        groups: "Clienti",
        reports: "Report",
        login: "Login",
        loginMessage: "Inserire la vostra email e password per accedere alla piattaforma online di Scrum",
        forgotPassword: "Password dimenticata?",
        privacyPolicy: "Privacy Policy",
        viewCourse: "Visualizza Corso",
        recent: "Recenti",
        viewCredential: "Visualizza Credenziali",
        modules: "Moduli",
        logout: "Logout",
        help: "Aiuto",
        firstName: "Nome",
        lastName: "Cognome",
        yourName: "Il tuo nome",
        email: "Email",
        confirm: "Confirma",
        register: "Registra",
        myAchievements: "I miei risultati",
        settings: "Settaggi",
        passwordRequirements: "La password deve contenere almeno una lettera maiuscola, una minuscola, almeno un numero, e lunga tra 8 e 20 caratteri",
        passed: "Promosso!",
        checkResultsBelow: "I risultati sono visibili di seguito",
        downloadCredential: "Download Certificato",
        returnToDashboard: "Returna alla Dashboard",
        billingLink: "Fatturazione",
        resetInstructions: "Inserire il vostro username (di solito la vostra email) e vi verranno spedite le istruzioni per resettare la password.",
        resetSuccess: "Completato! Controllate la vostra email, se un utente è associato con questa email riceverete le istruzioni su come resettare la password.",
        reset: "Reset",
        createAccount: "Per creare un account, inserite le informazioni ed il codice d'invito fornito dal vostro trainer",
        selectCountry: "Seleziona nazione",
        inviteCode: "Codice d'invito",
        acceptTerms1: "Ho letto ed accettato ",
        acceptTerms2: " così come ",
        newsletterCheckbox: "Desidero ricevere da Scrum Inc. la newsletter e le ultime offerte",
        alreadyHaveAccount: "Avete già un account? Login",
        dashboard: "Dashboard",
        visitMyAchievements: "Visitate la pagina i Miei Risultati per scaricare i vostri certificati",
        registerWithCode: "Registrazione con codice corso",
        rateInstructor: "Valuta il tuo trainer",
        rateExperience: "Come valuteresti l'esperienza di apprendimento con [instructor name]",
        instructorFeedback: "Cosa [instructor name] ha fatto bene? In che modo [instructor name] può migliorare l'esperienza del corso? NOTA: Questo feedback sarà fornito anonimamente al tuo trainer.",
        additionalFeedback: "Feedback ulteriori (Opzionale)",
        returnDashboard: "Ritorna alla Dashboard",
        submit: "Invia",
        lsmDescription: "Il nostro corso di 2 giorni Scrum Master è una introduzione al framework Scrum. Si focalizzato al ruolo dello Scrum Master in dettaglio. Questo componente  online del corso contiene il test di certificazione. Una volta superato il test diventerete un Scrum Master e potrete scaricare il certificato ufficiale.",
        lsmExam: "Scrum Master Exam",
        files: "File",
        noCertificates: "Non avete alcun certificato... ancora! Una volta superato uno degli esami Scrum troverete qui i certificati da scaricare",
        selectLanguage: "Selezionate la lingua",
        save: "Salva",
        uploadImage: "Upload Immagine",
        profilePicture: "Foto Profilo",
        userInfo: "Informazioni utenti",
        socialsBio: "Biografia e Social Network",
        website: "Sito Web",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Biografia",
        changePassword: "Cambia Password",
        newPassword: "Nuova Password",
        changeOptIn: "Cambia consenso all'elenco",
        confirmNew:"Conferma Nuova Password",
        congratulationsMessage: "Congratulazioni! Sei passato! Riceverai il tuo certificato nell'email a breve. Puoi revisionare il risultato di seguito.",
        directoryOptIn: "Consenso all'elenco",
        certificantDirectoryMessage: "Desidero essere aggiunto all'elenco Registro Internazionale dei Professionisti Agile",
        close: "Chiudi",
        correct: "Corretto",
        viewResults: "E' possibile vedere i risultati di seguito",
        downloadCert: "Download Certificato",
        questionNumber: "Domanda",
        currentPassword: "Password Corrente",
        certificantDirectory: "Registro Internazionale dei Professionisti Agile",
        validSince: "Valide Da:",
        lastRenewed: "Ultimo Rinnovo:",
        expires: "Scade:",
        renew: "Rinnova",
        download: "Download",
        newsletter: "Scrum Newsletter ed aggioramenti via email",
        renewCredential: "Rinnovo Certificati",
        renewalNotice: "Per rinnovare il vostro certificato occorre pagare la tassa di rinnovo. Questo vi permetterà di sostenere l'esame di rinnovo e, una volta superato, il certificato sarà valido per 1 anno. Per pagare il rinnovo utilizzate il riquadro sicuro in basso.",
        registerClass: "Registra una Classe",
        renewNow: "Rinnova Adesso",
        secureCheckout: "Pagamento Sicuro, powered by Stripe",
        enterInviteCode: "Inserire il codice o l'url invito per registrarsi.",
        lspoDescription: "Il nostro corso di 2 giorni Product Owner si focalizzato sul ruolo del Product Owner in dettaglio. Questo componente online del corso contiene il test di certificazione. Una volta superato il test diventerete un Scrum Product Owner e potrete scaricare il certificato ufficiale.",
        attemptsLeft: "tentativi residui",
        reviewAnswers: "Controllate le domande ed apportate ogni cambiamento prima di inviare test",
        didNotPass: "Non avete superato l'esame.",
        didNotPassOneLeft: "Non avete superato l'esame. Avete ancora un tentativo residuo.",
        failed: "Fallito",
        errorMessage: "Qualcosa non ha funzionato. Scrivete al trainer ed online-learning@scruminc.com",
        retakeExam: "Ripeti Esame",
        noMoreAttempts: "Avete terminato i tentativi di esame. Contattate il vostro trainer per ricevere istruzioni.",
        contactUs: "Contattac",
        subject: "Oggetto",
        message: "Messaggio",
        enterVirtualClassroom: "Entra nella Classe Virtuale",
        myStudentView: "Pagina Studente",
        examAnalysis: "Analisi Esami",
        adminDashboard: "Dashboard Amministratore",
        partners:"Partner",
        supportSuccess: "Richiesta supportp creata con successo. Risponderemo il prima possibile. Grazie!",
        visitMyAchievementsTile: "Per vedere e rinnovare i vostri certificati, visitate la pagina I Miei Risultati",
        total: "totale",
        dueDate: "Entro la Data",
        action: "Azione",
        description: "Descrizione",
        status:"Status",
        pay: 'Paga',
        receipt: "Ricevuta",
        pastDue: "Scaduto",
        open: "Aperto",
        paid: "Pagato",
        warning: "Attenzione",
        pleaseBilling: "Compilate le informazioni di fatturazione nella pagina impostazioni",
        all: "Tutti",
        thisWeek: "Questa Settimana",
        nextWeek: "Prossima Settimana",
        laterThisMonth: "Questo Mese",
        futureClasses: "Classi future",
        recentClasses: "Classi recenti",
        back: "Precendente",
        next: "Successivo",
        billingInformation: "Informazioni di fatturazione",
        billingReceiptInfo: "Queste informazioni verranno mostrate in ogni ricevuta e fattura.",
        billingReceiptInfo2: "Se necessitate che le fatture/ricevute e che il link di pagamento venga inviato ad altre email (VOI le riceverete sempre anche all'email principale) potete aggiungerle qui. Indirizzi multipli possono essere separati con virgola. Esempio: giovanna@doe.com,gigi@doe.com",
        viewModule: "Visualizza Modulo",
        enterAValidEmail: "Inserire una email valida",
        invalidResetLink: "Link di rest non valido.",
        passwordReset: "Password Reset",
        returnToLogin: "Ritorna al Login",
        success: "Successo",
    }
