import * as types from '../actions/groups/types';

const initialState = {
  groups: [],
  group: null,
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_GROUPS:
      return {
        ...state,
        groups: state.groups.concat(action.payload),
      };
    case types.UPDATE_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case types.UNSET_GROUP:
      return {
        ...state,
        group: null,
      };
    default:
      return { ...initialState, ...state };
  }
};
