import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const apiKey = process.env.REACT_APP_I18NEXUS_APIKEY;
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['default', 'faq'],
    defaultNS: 'default',
    supportedLngs: [
      'en', // English
      'es', // Spanish
      'fr', // French
      'it', // Italian
      'zh-TW', // Chinese (Taiwan)
      'pt', // Portuguese
      'ja', // Japanese
      'tr', // Turkish
      'zh', // Chinese
      'de', // German
      'el', // Greek
      'ru', // Russian
    ],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: loadPath,
    },
  });
