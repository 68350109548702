import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { api } from 'app/api';
import { loadState } from 'utils/localStorage';

export const apiWithPoker = api.injectEndpoints({
  endpoints: (builder) => ({
    getRoom: builder.query({
      query: (code) => `/rooms/${code}`,
      providesTags: (result) =>
        result ? [{ type: 'PokerRoom', id: result._id }] : [],
    }),
    createRoom: builder.mutation({
      query: () => ({
        url: '/rooms',
        method: 'POST',
      }),
      invalidatesTags: ['PokerRoom'],
    }),
  }),
});

export const { useGetRoomQuery, useLazyGetRoomQuery, useCreateRoomMutation } =
  apiWithPoker;

const initialState = {
  userId: loadState('pokerUserId') || uuid(),
  userName: loadState('pokerUserName') || '',
  room: null,
  isConnecting: false,
  isConnected: false,
  isVoting: false,
  changingState: false,
  pristine: true,
  isEditingName: true,
  isSharingCode: false,
};

export const pokerSlice = createSlice({
  name: 'poker',
  initialState,
  reducers: {
    connecting: (state) => {
      state.isConnecting = true;
    },
    connected: (state) => {
      state.isConnecting = false;
      state.isConnected = true;
    },
    disconnected: (state) => {
      state.isConnected = false;
    },
    updateRoom: (state, { payload }) => {
      state.room = payload;
    },
    updateUserList: (state, { payload }) => {
      state.room = {
        ...state.room,
        vote_list: payload,
      };
    },
    updateUserName: (state, { payload: { id, name } }) => {
      if (id && name) {
        state.userName = name;
        state.room = {
          ...state.room,
          vote_list: {
            ...state.room.vote_list,
            [id]: {
              vote: state.room?.vote_list[id]?.vote || -1,
              name,
            },
          },
        };
      }
    },
    // this is just here so we can trigger the socket update via middleware
    randomizeAvatarSeed: (state) => {},
    // sets the username without messing with the room
    // used by the store subscribe to set the username to auth.user.first_name if value isn't set
    setUserName: (state, { payload }) => {
      state.userName = payload;
    },
    leave: () => initialState,
    startVoting: (state) => {
      Object.keys(state.room.vote_list).forEach((userId) => {
        state.room.vote_list[userId].vote = -1;
      });
      state.isVoting = true;
      state.room.voting = true;
      state.pristine = false;
    },
    endVoting: (state) => {
      state.isVoting = false;
      state.pristine = false;
    },
    votingEnded: (state, { payload }) => {
      state.isVoting = false;
      state.room = payload;
      state.pristine = false;
    },
    userVoted: (state) => {
      state.pristine = false;
    },
    updateScrumMaster: (state, { payload }) => {
      state.room = {
        ...state.room,
        scrum_master: payload,
      };
    },
    openOutOfRange: (state) => {
      state.room.outOfRange = true;
    },
    closeOutOfRange: (state) => {
      state.room.outOfRange = false;
    },
    makeScrumMaster: (state, { payload: { userId } }) => {
      state.room.scrum_master = userId;
    },
    editingName: (state, { payload: isEditingName }) => {
      state.isEditingName = isEditingName;
    },
    shareCode: (state, { payload: isSharingCode = false }) => {
      state.isSharingCode = isSharingCode;
    },
  },
});

export const {
  closeOutOfRange,
  connected,
  connecting,
  disconnected,
  editingName,
  endVoting,
  leave,
  makeScrumMaster,
  openOutOfRange,
  randomizeAvatarSeed,
  shareCode,
  startVoting,
  updateRoom,
  updateScrumMaster,
  updateUserList,
  updateUserName,
  userVoted,
  votingEnded,
} = pokerSlice.actions;

export const pokerActions = pokerSlice.actions;

export default pokerSlice.reducer;

export const selectPoker = (state) => state.poker;
export const selectRoom = (state) => state.poker.room;
export const selectHasVoted = (state) => {
  const userId = state.poker.userId;
  return state.poker.room?.vote_list[userId]?.vote > -1;
};
