import axios from 'axios';

import providesTagsHelper from './helpers/providesTags';

export default function fileModules(builder) {
  return {
    getFileModule: builder.query({
      query: ({ fileId, sessionId }) =>
        `/FileModule/${fileId}${sessionId ? `/${sessionId}` : ''}`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'FileModule', result }),
    }),
    updateFileModule: builder.mutation({
      query: (fileModule) => ({
        url: `/FileModule/${fileModule._id}`,
        method: 'PUT',
        body: fileModule,
      }),
      invalidatesTags: ['FileModule', 'Session'],
    }),
    uploadFileModuleFile: builder.mutation({
      async queryFn(
        { fileModule, file, progressCallback, fileOnly = false },
        _api,
        _extraOptions,
        fetchWithBQ
      ) {
        const response = await fetchWithBQ({
          url: '/FileModule/upload',
          method: 'POST',
          body: {
            name: file.name,
            type: file.type,
          },
        });

        const axiosInstance = axios.create({ headers: {} });
        // delete axiosInstance.defaults.headers.common['Authorization'];

        await axiosInstance.put(response.data.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progress) => progressCallback(progress),
        });

        if (!fileOnly) {
          await fetchWithBQ({
            url: `/FileModule/uploaded_file`,
            method: 'POST',
            body: {
              url: response.data.filePath,
              name: response.data.name,
              fileModule,
            },
          });
          progressCallback(false);
        }

        if (response?.data) {
          return {
            data: {
              url: response.data.filePath,
              name: response.data.name,
              fileModule,
            },
          };
        } else {
          return { error: response.error };
        }
      },
      invalidatesTags: ['FileModule'],
    }),
    addFileModuleFileLink: builder.mutation({
      query: ({ url, name, fileModule }) => ({
        url: `/FileModule/uploaded_file`,
        method: 'POST',
        body: {
          url,
          name,
          fileModule,
        },
      }),
      invalidatesTags: ['FileModule'],
    }),
    updateFileModuleFile: builder.mutation({
      query: ({ fileId, name }) => ({
        url: `/FileModule/uploaded_file/${fileId}`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['FileModule'],
    }),
  };
}
