import { actions as authActions } from 'features/auth/slice';

export default function renewalEndpoints(builder) {
  return {
    getRenewal: builder.query({
      query: ({ userId, achievementId }) =>
        `/users/${userId}/achievements/${achievementId}/price`,
    }),
    purchaseRenewal: builder.mutation({
      async queryFn(
        { paymentIntentId },
        { dispatch },
        _extraOptions,
        fetchWithBQ
      ) {
        const response = await fetchWithBQ({
          url: `/renewals/purchase_renewal`,
          method: 'POST',
          body: {
            paymentIntentId,
          },
        });

        // when the response comes back, update the auth user modules
        if (response?.data?.modules) {
          dispatch(authActions.updateModules(response.data.modules));
          return { data: response.data };
        } else {
          return { error: response?.error };
        }
      },
      invalidatesTags: ['Achievement'],
    }),
    createRenewalPaymentIntent: builder.mutation({
      query: ({ cart }) => ({
        url: '/renewals/paymentIntent',
        method: 'POST',
        body: { cart },
      }),
    }),
    updateRenewalPaymentIntent: builder.mutation({
      query: ({ paymentIntentId, ...updates }) => ({
        url: `/renewals/paymentIntent/${paymentIntentId}`,
        method: 'PUT',
        body: updates,
      }),
    }),
    cancelRenewalPaymentIntent: builder.mutation({
      query: (paymentIntentId) => ({
        url: `/renewals/paymentIntent/${paymentIntentId}`,
        method: 'DELETE',
      }),
    }),
    createRenewalCycle: builder.mutation({
      query: (body) => ({
        url: '/renewals/create_renewal_cycle',
        method: 'POST',
        body,
      }),
    }),
    updateRenewalCycle: builder.mutation({
      query: (body) => ({
        url: '/renewals/update_renewal_cycle',
        method: 'POST',
        body,
      }),
    }),
    submitCoTrainingFeedback: builder.mutation({
      query: (body) => ({
        url: `/renewals/renewal_feedback`,
        method: 'POST',
        body,
      }),
    }),
    getRenewalTrainerFeedback: builder.mutation({
      query: (body) => ({
        url: `/renewals/renewal_trainer_feedback`,
        method: 'GET',
      }),
    }),
    submitExperienceReport: builder.mutation({
      query: (body) => ({
        url: `/renewals/xp_report`,
        method: 'POST',
        body,
      }),
    }),
    requestTrainerRenewal: builder.mutation({
      query: (body) => ({
        url: `/renewals/request_trainer_renewal`,
        method: 'POST',
        body,
      }),
    }),
    listRenewals: builder.query({
      query: () => '/renewals',
    }),
  };
}
