import * as types from '../actions/sessions/types';

const initialState = {
  sessions: [],
  session: null,
  loading: true,
  users: [],
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.GET_SESSIONS:
      return {
        ...state,
      };
    case types.SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
        loading: false,
      };
    case types.UPDATE_SESSIONS:
      return {
        ...state,
        sessions: [action.payload, ...state.sessions],
      };
    case types.SET_SESSION:
      return {
        ...state,
        session: action.payload,
        users: action.payload.users,
      };
    case types.UPDATE_SESSION:
      return {
        ...state,
        session: { ...state.session, ...action.payload },
      };
    case types.UNSET_SESSION:
      return {
        ...state,
        session: null,
      };
    case types.REMOVE_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter(
          (session) => session._id !== action.payload._id
        ),
      };
    case types.RESET_QUIZ:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user._id === action.payload._id) {
            return action.payload;
          }
          return user;
        }),
      };
    case types.ADD_USER_TO_SESSION:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case types.REMOVE_USER_FROM_SESSION:
      return {
        ...state,
        users: state.users.filter((u) => u._id !== action.payload._id),
      };
    case types.UPDATE_UNLOCKED_CONtENT:
      return {
        ...state,
        session: { ...state.session, unlocked_modules: action.payload },
      };
    default:
      return { ...initialState, ...state };
  }
};
