import api from './';
import providesTags from './helpers/providesTags';

export default function quizModules(builder) {
  return {
    getQuizModule: builder.query({
      query: ({ id, locale = 'en' }) => ({
        url: `/QuizModule/${id}?locale=${locale}`,
      }),
      providesTags: (result) => providesTags({ type: 'QuizModule', result }),
    }),
    updateQuizModule: builder.mutation({
      query: ({ id, ...updates }) => ({
        url: `/QuizModule/${id}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['QuizModule'],
    }),
    getQuestion: builder.query({
      query: ({ quizModuleId, questionId, locale }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}${
          locale !== 'en' ? `/${locale}` : ''
        }`,
      }),
      providesTags: (result) =>
        providesTags({ type: 'QuizModuleQuestion', result }),
    }),
    addQuestion: builder.mutation({
      query: ({ quizModuleId, groupId }) => {
        let url = `/QuizModule/${quizModuleId}`;
        if (groupId) url += `/groups/${groupId}/questions`;
        else url += '/questions';
        return {
          url,
          method: 'POST',
        };
      },
      invalidatesTags: ['QuizModule'],
    }),
    updateQuestion: builder.mutation({
      query: ({
        quizModuleId,
        questionId,
        question,
        questionType,
        locale,
      }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}`,
        method: 'PUT',
        body: { question, questionType, locale },
      }),
      invalidatesTags: ['QuizModuleQuestion', 'QuizModule'],
    }),
    removeQuestion: builder.mutation({
      query: ({ quizModuleId, questionId, questionPool }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}`,
        method: 'DELETE',
        body: questionPool,
      }),
      invalidatesTags: ['QuizModule'],
    }),
    addQuestionLocale: builder.mutation({
      query: ({ quizModuleId, questionId, locale }) => ({
        url: `/QuizModule/${quizModuleId}/questions/${questionId}/${locale}`,
        method: 'POST',
      }),
      invalidatesTags: ['QuizModuleQuestion'],
    }),
    getQuestionGroup: builder.query({
      query: ({ quizModuleId, groupId }) => ({
        url: `/QuizModule/${quizModuleId}/groups/${groupId}`,
      }),
      providesTags: (result) =>
        providesTags({ type: 'QuizModuleQuestionGroup', result }),
    }),
    createQuestionGroup: builder.mutation({
      query: ({ quizModuleId }) => ({
        url: `/QuizModule/${quizModuleId}/groups`,
        method: 'POST',
      }),
      invalidatesTags: ['QuizModule'],
    }),
    updateQuestionGroup: builder.mutation({
      query: ({ quizModuleId, groupId, ...updates }) => ({
        url: `/QuizModule/${quizModuleId}/groups/${groupId}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['QuizModuleQuestionGroup', 'QuizModule'],
    }),
    removeQuestionGroup: builder.mutation({
      query: ({ quizModuleId, groupId }) => ({
        url: `/QuizModule/${quizModuleId}/groups/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['QuizModule'],
      async onQueryStarted(
        { quizModuleId, locale, groupId },
        { dispatch, queryFulfilled }
      ) {
        const params = { id: quizModuleId, locale };
        const updateResult = dispatch(
          api.util.updateQueryData('getQuizModule', params, (draft) => {
            draft.question_groups = draft.question_groups.filter(
              ({ _id }) => _id !== groupId
            );
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    updateQuestionOrder: builder.mutation({
      query: ({ quizModuleId, groupId, orderedList }) => {
        let url = `/QuizModule/${quizModuleId}/`;
        if (groupId) {
          url += `groups/${groupId}/questions_order`;
        } else {
          url += 'questions_order';
        }

        return {
          url,
          method: 'PUT',
          body: orderedList,
        };
      },
      invalidatesTags: ['QuizModule'],
      async onQueryStarted(
        { quizModuleId, locale, groupId, orderedList },
        { dispatch, queryFulfilled }
      ) {
        const params = { id: quizModuleId, locale };
        const updateResult = dispatch(
          api.util.updateQueryData('getQuizModule', params, (draft) => {
            if (groupId) {
              const index = draft.question_groups.findIndex(
                ({ _id }) => _id === groupId
              );
              const group = draft.question_groups[index];
              group.questions.forEach((question) => {
                const orderedQuestion = orderedList.find(
                  ({ _id }) => _id === question._id
                );
                if (orderedQuestion) {
                  question.order = orderedQuestion.order;
                }
              });
              draft.question_groups.splice(index, 1, group);
            } else {
              draft.question_pool.forEach((question) => {
                const orderedQuestion = orderedList.find(
                  ({ _id }) => _id === question._id
                );
                if (orderedQuestion) {
                  question.order = orderedQuestion.order;
                }
              });
              draft.question_groups.forEach((group) => {
                const orderedGroup = orderedList.find(
                  ({ _id }) => _id === group._id
                );
                if (orderedGroup) {
                  group.order = orderedGroup.order;
                }
              });
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
  };
}
