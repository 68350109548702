import { loadState } from 'utils/localStorage';
import * as types from '../actions/interface/types';

const initialState = {
  modalOpen: false,
  locale: loadState('18nextLng') || 'en',
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        modalOpen: action.payload ? action.payload : true,
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    case types.SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return { ...initialState, ...state };
  }
};
