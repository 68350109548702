const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
// this is to handle chunk load errors that can happen when someone's browser caches the page or accesses it while we pushed a new version
// track if the page has been reloaded, if the client gets a chunk load error, it will reload the page
// if they still get an error, throw it so it can be logged
export default async function importRetry(
  importFn,
  retries = 3,
  interval = 1000
) {
  const hasCookiesEnabled = !!navigator?.cookieEnabled;

  // if the user has cookies enabled, try tracking if the page has been forced refresh
  // so if the component or chunk fails to load, we'll reload the page once
  if (hasCookiesEnabled) {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );
    try {
      const component = await importFn();
      window.localStorage.setItem('page-has-been-force-refreshed', 'false');
      return component;
    } catch (err) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }
      throw new Error(err);
    }
  } else {
    // if the user does not have cookies enabled, just retry loading the component 3 times with some delay
    try {
      return await importFn();
    } catch (err) {
      if (retries) {
        await wait(interval);
        return importRetry(importFn, retries - 1, interval);
      } else {
        throw new Error(err);
      }
    }
  }
}
