import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'features/auth/slice';

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);
  const features = user ? user.features : {};

  return { user, features };
};

export default useAuth;
