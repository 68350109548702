export const validateAchName = (achName, methodName = '') => {
  if (typeof achName !== 'string') {
    // achName default is null, throw error on undefined
    if (achName === null) {
      achName = '';
    } else {
      throw new Error(`${methodName} argument must be a string`);
    }
  }
  return achName;
};

export const sanitizeAchName = (achName) => {
  try {
    achName = validateAchName(achName, 'sanitizeAchName');
    if (achName.includes('Registered')) return achName;
    const sanitizedName = achName
      .replace(' (CSASP)', '')
      .replace('Licensed ', '')
      .replace('Certified ', '')
      .replace('Scrum Product Owner', 'Product Owner')
      .replace(
        'Scrum Master and Product Owner',
        'Scrum Master and Registered Product Owner'
      )
      .replace('Scrum Inc.', 'Scrum');
    return `Registered ${sanitizedName}`;
  } catch (error) {
    throw new Error(error);
  }
};

export const createShortAchName = (achName) => {
  try {
    achName = validateAchName(achName, 'createShortAchName');
    const splitAchNameString = achName.split(' ');
    const credName = splitAchNameString.reduce((acc, each) => {
      if (each.includes('Scrum@Scale')) return acc + 'SAS';
      if (each.includes('Owner@Scale')) return acc + 'OAS';
      if (each.includes('Master@Scale')) return acc + 'MAS';
      if (each.includes('Leader@Scale')) return acc + 'LAS';
      const shortName = acc + each[0];
      return shortName === 'RSMaRPO' ? 'RSMPO' : shortName;
    }, '');
    return credName[0] === 'R' ? credName : `R${credName}`;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCertPathFromAchName = async (achName) => {
  try {
    achName = validateAchName(achName, 'getCertPathFromAchName');
    achName = sanitizeAchName(achName);
    return `${createShortAchName(achName).toLowerCase()}.docx`;
  } catch (error) {
    throw new Error(error);
  }
};

export const findUserLanguage = (user) => {
  return user.language && user.language !== '' ? user.language : 'en';
};
