module.exports =
    {
        language: "繁體中文",
        username: "用戶名稱",
        password: "密碼",
        welcome: "歡迎",
        tos: "服務條款",
        pp: "隱私權條款",
        home: "信息中心",
        sessions: "課程",
        newSession: "新課程",
        courses: "課程類型",
        people: "學生",
        groups: "客戶",
        needAccount: "需要一個帳戶嗎?",
        registerHere: "在這裡註冊",
        reports: "報告",
        login: "登入",
        loginMessage: "請輸入您的電子郵件和密碼以進入Scrum線上平台",
        forgotPassword: "忘記密碼了?",
        privacyPolicy: "隱私權條款",
        viewCourse: "課程資料和考試",
        recent: "最近",
        viewCredential: "查看證書",
        modules: "模組",
        logout: "登出",
        help: "幫助",
        firstName: "名字",
        lastName: "姓氏",
        yourName: "您的姓名",
        email: "電子郵件",
        confirm: "確認",
        register: "註冊",
        myAchievements: "我的成就",
        settings: "設定",
        passwordRequirements: "您的密碼必須至少包含一個大寫字母和一個小寫字母，至少包含一個數字，並且長度在8到20個字母之間",
        passed: "通過!",
        checkResultsBelow: "您可以在下面查看結果",
        downloadCredential: "下載證書",
        returnToDashboard: "返回到信息中心",
        billingLink: "帳單",
        resetInstructions: "輸入您的用戶名稱（通常是您的電子郵件），我們將會向您發送重設密碼的說明。",
        resetSuccess: "成功！請檢查您的電子郵件。如果用戶帳戶與此電子郵件相同，您將收到如何重設密碼的說明。",
        reset: "重設",
        createAccount: "要建立您的帳戶，請輸入您的詳細資料並使用您的講師所提供的邀請代碼",
        selectCountry: "選擇國家",
        selectPreferredLanguage: "選擇首選語言",
        inviteCode: "邀約代碼",
        acceptTerms1: "我已閱讀並接受 ",
        acceptTerms2: " 以及 ",
        newsletterCheckbox: "我想收到Scrum Inc.電子報和最新通知",
        alreadyHaveAccount: "已經有帳號了？ 在此登入",
        dashboard: "信息中心",
        visitMyAchievements: "要查看您的課程資料和/或參加 Scrum的考試，請點擊下面的相關課程。 參訪我的成就頁面來下載您之前獲得的證書。",
        registerWithCode: "註冊您的課程代碼",
        rateInstructor: "評讚你的講師",
        rateExperience: "您如何評讚您的學習經歷與[講師姓名]",
        instructorFeedback: "[講師姓名]那裡做得好？ [講師姓名]可以做什麼來改善學習經驗？ 注釋：此反饋對您的講師是完全匿名的。",
        additionalFeedback: "額外的反饋（選修）",
        returnDashboard: "返回到信息中心",
        submit: "提交",
        lsmDescription: "我們為期2天的 Scrum Master大師課程是對Scrum構架的介紹。 它詳細介紹了Scrum Master角色。 該課程的線上部分包含認證考試。 通過考試後，您將成為認證的 Scrum Master，並且可以下載官方證書。",
        lsmExam: "Scrum Master 考試",
        files: "檔案",
        noCertificates: "您還沒有任何證書...！ 當您完成並通過我們的 Scrum考試之後，您將可以在此處找到可下載的證書",
        selectLanguage: "選擇首選語言",
        save: "儲存",
        uploadImage: "上傳圖片",
        profilePicture: "個人資料照片",
        userInfo: "用戶信息",
        socialsBio: "社交自傳",
        website: "網站",
        linkedin: "LinkedIn",
        twitter: "推特",
        youtube: "YouTube",
        bio: "自傳",
        changePassword: "更改密碼",
        newPassword: "新密碼",
        changeOptIn: "選擇加入",
        confirmNew:"確認新密碼",
        congratulationsMessage: "恭喜！ 您通過考試！ 您很快就會在電子郵件中收到證書。 您可以立即查看考試結果。",
        directoryOptIn: "選擇加入名錄",
        certificantDirectoryMessage: "我想加入敏捷專業人士國際註冊中心",
        close: "關閉",
        correct: "正確",
        viewResults: "您可以在下面查看結果",
        downloadCert: "下載證書",
        questionNumber: "問題",
        currentPassword: "現在的密碼",
        certificantDirectory: "敏捷專業人員國際註冊處",
        validSince: "有效期自:",
        lastRenewed: "最後更新:",
        expires: "到期:",
        renew: "更新",
        download: "下載",
        newsletter: "Scrum 電子報和電子郵件通知",
        renewCredential: "更新證書",
        renewalNotice: "要更新您的證書，您必須支付更新費。 然後您將可以參加更新考試，並且在通過考試後，您的證書將得到有效期為1年的延續。 您可以使用下面的安全付款方式來支付更新費。",
        registerClass: "註冊一堂課程",
        renewNow: "現在就更新",
        secureCheckout: "由Stripe提供的安全付款方式",
        enterInviteCode: "請輸入您的課程邀請代碼或邀請網址來進行註冊。",
        lspoDescription: "我們為期2天的 Scrum Product Owner大師課程詳細介紹了Product Owner角色。 該課程的線上部分包含認證考試。 通過考試後，您將成為認證的 Scrum Product Owner，並且可以下載官方證書。",
        attemptsLeft: "剩下幾次重考",
        reviewAnswers: "在提交之前，請查看您的答案並進行任何更改",
        didNotPass: "您沒有通過考試。",
        didNotPassOneLeft: "您沒有通過考試。 您可以再試一次。",
        failed: "失敗",
        errorMessage: "出了些問題。 請通過online-learning@scruminc.com與我們聯絡。",
        retakeExam: "重考",
        noMoreAttempts: "您已經不能再重考了。 請與您的講師聯繫以進行更多重考。",
        contactUs: "與我們聯絡",
        subject: "主題",
        message: "信息",
        enterVirtualClassroom: "進入線上教室",
        myStudentView: "我的學生一覽",
        examAnalysis: "考試分析",
        adminDashboard: "管理員控制台",
        partners:"合作夥伴",
        supportSuccess: "請求支援的要求已成功送出。 我們的支援時間是美國東岸上午10點至下午6點。 我們會盡快回复。 謝謝！",
        visitMyAchievementsTile: "要查看或更新您的證書，請訪問我的成就頁面",
        total: "總額",
        dueDate: "截止日期",
        action: "行動",
        description: "說明",
        status:"狀態",
        pay: "付款",
        receipt: "收據",
        pastDue: "逾期金額",
        open: "未付金額",
        paid: "已付",
        warning: "警告",
        pleaseBilling: "請在設定頁面上填寫您的帳單資料",
        all: "全部",
        thisWeek: "這個星期",
        nextWeek: "下個星期",
        laterThisMonth: "本月晚些時候",
        futureClasses: "未來課程",
        recentClasses: "最近課程",
        back: " 往前",
        next: "往後",
        billingInformation: "賬單信息",
        billingReceiptInfo: "此信息將顯示在任何發票收據上。",
        billingReceiptInfo2: "如果您需要將發票收據或付款連接發送到其他電子郵件地址（您自己的註冊電子郵件會預設收到收據），您可以在此處添加它們。請用逗號分隔每一個電子郵件。例如jane@doe.com,john@doe.com",
        viewModule: "查看模組",
        enterAValidEmail: "請輸入有效的電子郵件",
        invalidResetLink: "無效的重置連接",
        passwordReset: "重設密碼",
        returnToLogin: "返回登入",
        success: "成功",
        confirmRemoveClass: "確認刪除課程",
        areYouSureRemoveClass: "您確定要刪除這個課程嗎？ 這將使邀請代碼無法使用，並將移除所有當前己經註冊學生的訪問權限。",
        cancel: "取消",
        delete: "刪除",
        associatedCourses: "相關課程",
        content: "內容",
        addContent: "添加內容",
        name: "名稱",
        filesAndResources:"文件檔案和資源",
        classStartDate: "上課開始日期",
        classEndDate: "課程結束日期",
        clients: "客戶",
        selectClient: "選擇客戶...",
        copy: "複製",
        courseRegistrationUrl: "課程註冊網址",
        courseWebpageLink: "輸入您的課程網頁鏈接",
        selfSignupUrl: "自助註冊網址",
        inviteCodeExpiry: "邀請代碼到期日期",
        instructors: "講師",
        location: "地點",
        maxUsers: "人數上限",
        privateSession: "私人課程",
        removeClass: "刪除課程",
        viewUser: "查看用戶",
        virtualSession: "線上課程",
        virtualClassroomLink: "線上課程連結",
    }
