import { addRating } from 'features/auth/slice';

export default function ratings(builder) {
  return {
    createInstructorRating: builder.mutation({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: '/ratings/instructor',
          method: 'POST',
          body,
        });

        if (response.data) {
          dispatch(addRating(response.data));
          return { data: response.data };
        } else {
          return { error: response?.error || 'Unknown error' };
        }
      },
    }),
    createRenewalFeedback: builder.mutation({
      async queryFn(body, { dispatch }, _extraOptions, fetchWithBQ) {
        const response = await fetchWithBQ({
          url: '/ratings/renewal_feedback',
          method: 'POST',
          body,
        });

        if (response.data) {
          dispatch(addRating(response.data));
          return { data: response.data };
        } else {
          return { error: response?.error || 'Unknown error' };
        }
      },
    }),
  };
}
