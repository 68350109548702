import providesTagsHelper from './helpers/providesTags';

export default function getTeamsEndpoints(builder) {
  return {
    getTeam: builder.query({
      query: ({ teamId, companyId }) =>
        `/companies/${companyId}/teams/${teamId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Team', result }),
    }),
    getTeams: builder.query({
      query: ({ companyId, userId }) =>
        `/companies/${companyId}/teams/admin/${userId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Team', result }),
    }),
    updateTeam: builder.mutation({
      query: ({ teamId, companyId, body }) => ({
        url: `/companies/${companyId}/teams/${teamId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { teamId }) => ['Team', 'CompanyTeam'],
    }),
    listTeams: builder.query({
      query: (companyId) => `/companies/${companyId}/teams`,
      providesTags: (result) => providesTagsHelper({ type: 'Team', result }),
    }),
    getTeamReport: builder.query({
      query: ({ teamId, companyId, courseId, type = 'export' }) => {
        let url = `/companies/${companyId}/teams/${teamId}`;
        if (courseId && courseId !== 'all') {
          url += `/courses/${courseId}`;
        }
        url += `/report?type=${type}`;
        return url;
      },
    }),
    assignTeamCourse: builder.query({
      query: ({ teamId, companyId, courseId }) => ({
        url: `/teams/${teamId}/${companyId}`,
        method: 'POST',
        body: { courseId },
        responseHandler: (response) => response.text(),
      }),
    }),
    listTeamUsers: builder.query({
      query: ({ teamId, companyId, ...query }) => {
        const queryString = new URLSearchParams({ ...query }).toString();
        return `/companies/${companyId}/teams/${teamId}/users?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyUser', result: result?.docs || [] }),
    }),
    assignUsersCourse: builder.mutation({
      query: ({ companyId, teamId, courseId, userIds }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}/users`,
        method: 'POST',
        body: {
          userIds,
        },
      }),
      invalidatesTags: ['CompanyUser', 'Company', 'Team'],
    }),
    unassignUsersCourse: builder.mutation({
      query: ({ companyId, teamId, courseId, userIds }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}/users`,
        method: 'DELETE',
        body: {
          userIds,
        },
      }),
      invalidatesTags: ['CompanyUser', 'Company', 'Team'],
    }),
    listInviteCodes: builder.query({
      query: ({ companyId, teamId }) =>
        `/companies/${companyId}/teams/${teamId}/invites`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'InviteCode', result }),
    }),
    updateInviteCode: builder.mutation({
      query: ({ companyId, teamId, inviteCodeId, ...body }) => ({
        method: 'PUT',
        url: `/companies/${companyId}/teams/${teamId}/invites/${inviteCodeId}`,
        body,
      }),
      invalidatesTags: ['InviteCode'],
    }),
    createInviteCode: builder.mutation({
      query: ({ companyId, teamId, courseIds, subscriptionIds }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/invites`,
        body: { courseIds, subscriptionIds },
      }),
      invalidatesTags: ['InviteCode'],
    }),
    listTeamCourses: builder.query({
      query: ({ companyId, teamId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses`,
        method: 'GET',
      }),
    }),
    getTeamAchievementStatus: builder.query({
      query: ({ companyId, teamId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/teams/${teamId}/achievementStatus?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    getTeamAchievementType: builder.query({
      query: ({ companyId, teamId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/teams/${teamId}/achievementType?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    addTeamStudent: builder.mutation({
      query: ({ companyId, teamId, ...body }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/student`,
        body,
      }),
      invalidatesTags: ['CompanyUser', 'Company', 'Team'],
    }),
    // subscriptions
    assignTeamSubscription: builder.mutation({
      query: ({ companyId, teamId, ...body }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/subscriptions`,
        body,
      }),
      invalidatesTags: [
        'Team',
        'CompanyTeam',
        'Company',
        'CompanySubscription',
      ],
    }),
    unassignTeamSubscription: builder.mutation({
      query: ({ companyId, teamId, subscriptionId }) => ({
        method: 'DELETE',
        url: `/companies/${companyId}/teams/${teamId}/subscriptions/${subscriptionId}`,
      }),
      invalidatesTags: ['Team', 'CompanyTeam', 'Company'],
    }),
    assignTeamUserToSubscription: builder.mutation({
      query: ({ companyId, teamId, subscriptionId, ...body }) => ({
        method: 'POST',
        url: `/companies/${companyId}/teams/${teamId}/subscriptions/${subscriptionId}/users`,
        body,
      }),
      invalidatesTags: ['Team'],
    }),
    unassignTeamUserFromSubscription: builder.mutation({
      query: ({ companyId, teamId, subscriptionId, userId }) => ({
        method: 'DELETE',
        url: `/companies/${companyId}/teams/${teamId}/subscriptions/${subscriptionId}/users/${userId}`,
      }),
      invalidatesTags: ['Team'],
    }),
  };
}
