/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import strings from './localization';

const withLocalizedStrings = (Component) => (props) => {
  strings.setLanguage(props.locale);
  return <Component strings={strings} {...props} />;
};

withLocalizedStrings.propTypes = {
  locale: PropTypes.string,
};

const mapState = (state) => {
  return { locale: state.ui.locale };
};

export default compose(connect(mapState), withLocalizedStrings);
