import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
} from '@mui/material';
import {
  Settings as SettingsIcon,
  Help as HelpIcon,
  Business as BusinessIcon,
  Ballot as BallotIcon,
  Dashboard as DashboardIcon,
  LibraryBooks,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  selectCompanyId,
  selectCompanyRole,
  selectTeamId,
  selectOrganization,
  selectSubscription,
} from 'features/auth/slice';

export default function StudentListItems({ role, features = {} }) {
  const { t } = useTranslation(['common', 'poker']);

  const companyId = useSelector(selectCompanyId);
  const teamId = useSelector(selectTeamId);
  const companyRole = useSelector(selectCompanyRole);
  const organization = useSelector(selectOrganization);
  const hasSubscriptions = useSelector(selectSubscription);

  const canViewOrgDashboard = !!organization?._id;
  const canViewCompanyDashboard =
    ['companyAdmin', 'consultant'].includes(companyRole) && companyId;
  const canViewTeamDashboard =
    companyRole === 'teamAdmin' && companyId && teamId;
  const canViewClientDashboard = features?.clientDashboardEnabled;

  const studentDashboardLabel =
    canViewOrgDashboard || canViewCompanyDashboard || canViewTeamDashboard
      ? t('common:nav.studentDashboard')
      : t('common:nav.home');

  return (
    <List>
      {/* Show subheader only for non-student roles */}
      {role !== 'student' && (
        <ListSubheader disableSticky>{t('common:nav.students')}</ListSubheader>
      )}

      {canViewClientDashboard && (
        <ListItem
          button
          component={Link}
          to={`/clients/${companyId}/overview`}
          data-cy="client-dashboard"
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Client Dashboard" />
        </ListItem>
      )}

      {canViewOrgDashboard && (
        <ListItem
          button
          component={Link}
          to={`/organizations/${organization?._id}`}
          data-cy="organizationDashboard"
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:nav.orgDashboard')} />
        </ListItem>
      )}

      {canViewCompanyDashboard && (
        <ListItem
          button
          component={Link}
          to={`/companies/${companyId}`}
          data-cy="companyDashboard"
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:nav.companyDashboard')} />
        </ListItem>
      )}

      {canViewTeamDashboard && (
        <ListItem
          button
          component={Link}
          to={`/companies/${companyId}/teams/${teamId}`}
          data-cy="teamDashboard"
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={t('common:nav.teamDashboard')} />
        </ListItem>
      )}

      <ListItem button component={Link} to="/" data-cy="student-dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={studentDashboardLabel} />
      </ListItem>

      {hasSubscriptions && (
        <ListItem
          button
          component={Link}
          to="/content-library"
          data-cy="library"
        >
          <ListItemIcon>
            <LibraryBooks />
          </ListItemIcon>
          <ListItemText primary={t('common:nav.library')} />
        </ListItem>
      )}

      <ListSubheader disableSticky>{t('common:nav.tools')}</ListSubheader>

      <ListItem button component={Link} to="/poker" data-cy="poker">
        <ListItemIcon>
          <BallotIcon />
        </ListItemIcon>
        <ListItemText primary={t('poker:planningPoker')} />
      </ListItem>

      <Divider />

      <ListItem button component={Link} to="/settings" data-cy="settings">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.settings')} />
      </ListItem>

      <ListItem
        button
        component={Link}
        to="/frequently-asked-questions"
        data-cy="faq"
      >
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t('common:nav.support')} />
      </ListItem>
    </List>
  );
}
