import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'features/auth/slice';

export default function PrivateRoute({
  children,
  roles = [],
  featureFlag,
  ...rest
}) {
  const user = useSelector(selectCurrentUser);
  const features = user ? user.features : {};
  const location = useLocation();

  const checkOrgAdmin = (organizationId, companyId) => {
    if (!roles.includes('orgAdmin')) return false;
    if (organizationId || companyId) {
      return (
        user.organization?._id === organizationId ||
        user.organization?.companies?.includes(companyId)
      );
    }
    return !!user.organization?._id;
  };

  const checkCompanyAdmin = (companyId) => {
    if (!roles.includes('companyAdmin')) return false;
    if (companyId) {
      return !!user.companies?.find(
        (company) =>
          (company.company._id === companyId ||
            company.company === companyId) &&
          company.role === 'companyAdmin'
      );
    }
    return user?.companies?.some(({ role }) => role === 'companyAdmin');
  };

  const checkConsultant = (companyId) => {
    if (!roles.includes('consultant')) return false;
    if (companyId) {
      return !!user.companies?.find(
        (company) =>
          (company.company._id === companyId ||
            company.company === companyId) &&
          company.role === 'consultant'
      );
    }
    return user?.companies?.some(({ role }) => role === 'consultant');
  };

  const checkTeamAdmin = (companyId, teamId) => {
    if (!roles.includes('teamAdmin')) return false;
    if (companyId && teamId) {
      return !!user.companies?.find(
        ({ _id, team, role }) =>
          _id === companyId && team?._id === teamId && role === 'teamAdmin'
      );
    }
    return user?.companies?.some(({ role }) => role === 'teamAdmin');
  };

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (featureFlag && !features[featureFlag]) {
    return <Navigate to="/" replace />;
  }

  // If roles array is empty, grant access to any authenticated user
  if (roles.length === 0) {
    return children;
  }

  const { organizationId, companyId, teamId } = location.state || {};

  const isAppAdmin = user.role === 'admin';
  const isOrgAdmin = checkOrgAdmin(organizationId, companyId);
  const isCompanyAdmin = checkCompanyAdmin(companyId);
  const isConsultant = checkConsultant(companyId);
  const isTeamAdmin = checkTeamAdmin(companyId, teamId);

  const userRoles = [
    user.role,
    isOrgAdmin && 'orgAdmin',
    isCompanyAdmin && 'companyAdmin',
    isConsultant && 'consultant',
    isTeamAdmin && 'teamAdmin',
    user?.roles['partner_admin']?.length && 'partnerAdmin',
  ].filter(Boolean);

  if (isAppAdmin || roles.some((role) => userRoles.includes(role))) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}
