import React from 'react';
import { useSelector } from 'react-redux';

// import bifurcateBy from 'utils/helpers/bifurcateBy';
import { selectProgress } from 'features/auth/slice';
import { useListAchievementsWithPolling } from 'features/achievements/hooks/useListAchievementsWithPolling';

export function useListRenewalExams() {
  const { data: achievements = [] } = useListAchievementsWithPolling();
  const progress = useSelector(selectProgress);
  const user = useSelector((state) => state.auth.user);

  // Get the renewal modules from the achievements
  const renewalExams = React.useMemo(
    () =>
      achievements.reduce((acc, achievement) => {
        if (!achievement.current_renewal_quiz_module) return acc;

        // eslint-disable-next-line no-unused-vars
        const { current_renewal_quiz_module, ...achievementData } = achievement;

        return [
          ...acc,
          {
            ...achievement.current_renewal_quiz_module,
            // We need the achievement data for passing to renewal dialogs
            // But we don't want to create a circular reference
            achievement: {
              ...achievementData,
              current_renewal_quiz_module: current_renewal_quiz_module._id,
            },
          },
        ];
      }, []),
    [achievements]
  );

  // Get the completed renewal exams
  const completed = React.useMemo(() => {
    return user.modules
      .filter(({ module_id: renewalExam }) => {
        const examProgress = progress?.[renewalExam?._id];
        return examProgress ? !!examProgress?.passed : false;
      })
      .map((module) => ({
        ...module?.module_id,
        achievement: achievements.find((ach) => ach._id === module.achievement),
      }));
  }, [achievements, progress, user.modules]);

  // Get the pending exams
  const pending = React.useMemo(() => {
    return renewalExams.filter((renewalExam) => {
      const examProgress = progress?.[renewalExam._id];
      return examProgress ? !examProgress?.passed : true;
    });
  }, [renewalExams, progress]);

  // Get the unlocked renewal exam count
  const unlockedCount = React.useMemo(
    () =>
      pending.reduce((acc, renewalExam) => {
        if (renewalExam?.renewal_stripe_payment_id) {
          return acc + 1;
        }
        return acc;
      }, 0),
    [pending]
  );

  return {
    completed,
    pending,
    totalCount: renewalExams.length,
    unlockedCount,
  };
}

export default useListRenewalExams;
