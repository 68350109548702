module.exports =
    {
        language: "简体中文",
        username: "用户名",
        password: "密码",
        welcome: "欢迎",
        tos: 'Terms of Service',
        pp: 'Privacy Policy',
        home: "主页",
        sessions: "班级",
        newSession: '新班级',
        courses: "课程类型",
        people: "学生",
        groups: "客户",
        needAccount: "需要新账户?",
        registerHere: " 在这里注册",
        reports: "报告",
        login: "登录",
        loginMessage: "请输入你在Scrumlab线上平台注册的电子邮箱地址和密码",
        forgotPassword: "忘记密码?",
        privacyPolicy: "隐私政策",
        viewCourse: "课程材料和考试",
        recent: "最近的",
        viewCredential: "查看证书",
        modules: "模块",
        logout: "退出",
        help: "帮助",
        firstName: "名",
        lastName: "姓",
        yourName: "你的名字",
        email: "电子邮箱",
        confirm: "确认",
        register: "注册",
        myAchievements: "我的成就",
        settings: "设置",
        passwordRequirements: "密码必须至少包含1个大写字母，1个小写字母，1个数字。长度必须在8-20个字母之间",
        passed: "通过了!",
        checkResultsBelow: "你可以在下方查看你的结果",
        downloadCredential: "下载证书",
        returnToDashboard: "回到主页",
        billingLink: "账单",
        resetInstructions: "输入你的用户名（通常是你的电子邮件地址），我们会给你的邮箱发重置密码的说明。",
        resetSuccess: "成功了！请检查您与此用户账户关联的电子邮箱，您将收到有关如何重设密码的说明.",
        reset: "重置",
        createAccount: "要创建您的帐户，请输入您的详细信息并使用您的讲师提供的邀请代码.",
        selectCountry: "选择国家",
        selectPreferredLanguage: "选择期望的语言",
        inviteCode: "邀请码",
        acceptTerms1: "我已经阅读并且我接受这个条款 ",
        acceptTerms2: " 和它一样 ",
        newsletterCheckbox: "我想收到Scrum Inc.新闻简报和最新产品",
        alreadyHaveAccount: "已经有账户了？ 在这里登录",
        dashboard: "主页",
        visitMyAchievements: "要查看您的课程材料或参加ScrumInc.的考试，请单击下面的相关课程图块。 访问“我的成就”页面以下载您获得的所有凭证.",
        registerWithCode: "用你的课程码注册",
        rateInstructor: "请为你的培训师打分",
        rateExperience: "你如何评价你在[instructor name]课堂上的学习体验？",
        instructorFeedback: "[instructor name]哪里做的好? 为了提升学习体验，[instructor name]在哪些地方还可以做得更好？注意: 此反馈对你的培训师来说是完全匿名的。",
        additionalFeedback: "其他反馈 (可选)",
        returnDashboard: "回到主页",
        submit: "提交",
        lsmDescription: "我们的两天线下Scrum Master课程介绍了Scrum的框架以及详细说明了Scrum Master的角色和职责。该课程的在线部分包含一个认证考试。通过考试后，您将成为授权的ScrumInc. Scrum Master,并可以下载官方证书。",
        lsmExam: "LSM考试",
        files: "文件",
        noCertificates: "你暂时还没有任何证书... 但是，一旦你完成了ScrumInc.培训中的任何考试并且顺利通过，你就可以在这里下载你的证书了！",
        selectLanguage: "选择语言",
        save: "保存",
        uploadImage: "上传图片",
        profilePicture: "头像照片",
        userInfo: "用户信息",
        socialsBio: "社交和传记",
        website: "网站",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "传记",
        changePassword: "修改密码",
        newPassword: "新密码",
        changeOptIn: "修改 选择-加入",
        confirmNew:"确认你的新密码",
        congratulationsMessage: "祝贺你通过了考试！你稍后会在你的邮箱里收到你的证书。你现在可以查看结果了。",
        directoryOptIn: "目录 选择-加入",
        certificantDirectoryMessage: "我想加入敏捷专业人士国际注册中心",
        close: "关闭",
        correct: "正确",
        viewResults: "您可以在下面查看结果",
        downloadCert: "下载证书",
        questionNumber: "问题",
        currentPassword: "当前密码",
        certificantDirectory: "敏捷专业人员国际注册处",
        validSince: "有效期自:",
        lastRenewed: "最后更新:",
        expires: "到期日:",
        renew: "续订",
        download: "下载",
        newsletter: "LS新闻和邮件通知。",
        renewCredential: "续订证书",
        renewalNotice: "要续订证书，您必须支付续订费。 然后，您将可以参加续订考试，并且在通过考试后，您的证书将得到续订，有效期为1年。 您可以使用下面的安全付款方式支付续订费。",
        registerClass: "注册课程",
        renewNow: "现在续订",
        secureCheckout: "安全登出 （被Stripe支持）",
        enterInviteCode: "请输入你的邀请码或者通过邀请链接来注册。",
        lspoDescription: "我们的两天线下Scrum PO课程详细介绍了产品负责人的角色和职责。该课程的在线部分包含一个认证考试。通过考试后，您将成为正式的ScrumInc. PO，并可以下载官方证书。 ",
        attemptsLeft: "剩余尝试次数",
        reviewAnswers: "在提交考试之前，请查看您的答案并进行必要更改",
        didNotPass: "你没有通过考试。",
        didNotPassOneLeft: "你没有通过考试。你还有一次尝试的机会。",
        failed: "失败了",
        errorMessage: "出了些问题。 请通过online-learning@scruminc.com与我们联系。",
        retakeExam: "重新参加考试",
        noMoreAttempts: "您已经用光了你的考试次数。 请联系您的讲师以获取更多尝试机会。",
        contactUs: "联系我们",
        subject: "标题",
        message: "信息",
        enterVirtualClassroom: "进入你的虚拟教室",
        myStudentView: "我的学生视图",
        examAnalysis: "考试结果分析",
        adminDashboard: "管理员主页",
        partners:"合作伙伴",
        supportSuccess: "需要支持的请求已成功创建。 我们的支持时间是美国东部标准时间上午10点至下午6点。 我们会尽快回复。 谢谢！",
        visitMyAchievementsTile: "要查看或更新您的证书，请访问“我的成就”页面",
        total: "总计",
        dueDate: "截止日期",
        action: "行动",
        description: "说明",
        status:"状态",
        pay: '支付',
        receipt: "收据",
        pastDue: "逾期",
        open: "未支付",
        paid: "支付",
        warning: "警告",
        pleaseBilling: "请在设置页面上填写您的帐单信息",
        all: "全部",
        thisWeek: "这个星期",
        nextWeek: "下星期",
        laterThisMonth: "本月下旬",
        futureClasses: "将来的课程",
        recentClasses: "最近的课程",
        back: "上一页",
        next: "下一页",
        billingInformation: "账单信息",
        billingReceiptInfo: "此信息将显示在任何发票收据上。",
        billingReceiptInfo2: "如果您需要将发票收据或付款链接发送到任何其他电子邮件地址（您将始终在您自己的注册电子邮件中收到收据），则可以在此处添加它们。 多个邮箱应以逗号分隔。 例如 jane @ doe.com，john @ doe.com",
        viewModule: "查看模块",
        enterAValidEmail: "请输入一个有效的电子邮箱地址",
        invalidResetLink: "无效的重置链接.",
        passwordReset: "重置密码",
        returnToLogin: "返回登录界面",
        success: "成功",
        confirmRemoveClass: "确认删除课程",
        areYouSureRemoveClass: "您确定要删除这个课程吗？ 这将使邀请码无法使用，并将删除所有当前注册学生的访问权限.",
        cancel: "取消",
        delete: '删除',
        associatedCourses: '与之相关的课程',
        content: '内容',
        addContent: '增加内容',
        name: '名字',
        filesAndResources:'文件和资源',
        classStartDate: '课程开始日期',
        classEndDate: '课程结束日期',
        clients: '客户',
        selectClient: '选择客户...',
        copy: '复制',
        courseRegistrationUrl: '课程注册链接',
        courseWebpageLink: "请输入你的课程网页链接",
        selfSignupUrl: "自注册链接",
        inviteCodeExpiry: "无效的邀请码过期时间",
        instructors: '培训师',
        location: '地点',
        maxUsers: '最大用户数',
        privateSession: '私有课程',
        removeClass: '删除课程',
        viewUser: '查看用户',
        virtualSession: '线上课程',
        virtualClassroomLink: '线上课程链接',
    }
