/* eslint-disable import/no-anonymous-default-export */
import * as types from '../actions/quizModule/types';

const initialState = {
  quizModule: null,
  question: null,
  quiz: null,
  locale: 'en',
};

export default (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_QUIZ_MODULE:
      return {
        ...state,
        quizModule: action.payload,
      };
    case types.UNSET_QUIZ_MODULE:
      return {
        ...state,
        quizModule: null,
      };
    case types.UPDATE_QUIZ_QUESTIONS:
      return {
        ...state,
        quizModule: { ...state.quizModule, question_pool: action.payload },
      };
    case types.SET_QUIZ_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case types.UPDATE_QUIZ_QUESTION_BODY:
      return {
        ...state,
        question: { ...state.question, body: action.payload },
      };
    case types.UPDATE_QUIZ_QUESTION_ANSWERS:
      return {
        ...state,
        question: { ...state.question, answers: action.payload },
      };
    case types.UPDATE_QUIZ_QUESTION_ACTIVE:
      return {
        ...state,
        question: { ...state.question, active: action.payload },
      };
    case types.UPDATE_QUIZ_QUESTION: {
      return {
        ...state,
        question: action.payload,
      };
    }
    case types.UPDATE_CORRECT_ANSWER: {
      return {
        ...state,
        question: { ...state.question, correct: action.payload },
      };
    }
    case types.EDIT_ANSWER_CONTENT: {
      return {
        ...state,
        question: {
          ...state.question,
          answers: {
            ...state.question.answers,
            [action.payload.id]: action.payload.answer,
          },
        },
      };
    }
    case types.SET_USER_QUIZ: {
      return {
        ...state,
        quiz: action.payload,
      };
    }
    case types.SELECT_ANSWER: {
      return {
        ...state,
        quiz: action.payload,
      };
    }
    case types.CHANGE_CURRENT_QUESTION: {
      return {
        ...state,
        quiz: {
          ...state.quiz,
          current_question: state.quiz.current_question + action.payload,
        },
      };
    }
    case types.SUBMITTING_QUIZ: {
      return {
        ...state,
        quiz: { ...state.quiz, submitting: action.payload },
      };
    }
    case types.UNSET_USER_QUIZ: {
      return {
        ...state,
        quiz: null,
      };
    }
    case types.SHOW_QUIZ_RESULTS: {
      return {
        ...state,
        quiz: action.payload,
      };
    }
    case types.UPDATE_QUIZ_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }
    default:
      return { ...initialState, ...state };
  }
};
