import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { stopImpersonating } from 'features/auth/slice';

const AuthBannerContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '48px',
  margin: '0',
  padding: '0',
  backgroundColor: theme.palette.purple.main,
  [theme.breakpoints.down('md')]: {
    margin: '0',
  },
}));

const AuthBannerDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

const BannerText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingRight: '1rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}));

const AuthBannerButton = styled(Button)(({ theme }) => ({
  minWidth: '117px',
  padding: '.45rem 1rem',
  color: theme.palette.common.black,
  backgroundColor: theme.palette.button.yellow,
  '&:hover': {
    backgroundColor: theme.palette.button.yellowHover,
  },
}));

const AuthBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isImpersonating } = useSelector((state) => state.auth);

  const handleRevertToAdmin = () => dispatch(stopImpersonating());

  if (!isImpersonating || !user) return <></>;

  return (
    <AuthBannerContainer>
      <AuthBannerDiv>
        <BannerText>
          <strong>Viewing as:</strong> {user.username}
        </BannerText>
        <AuthBannerButton
          id="revert-to-admin"
          size="small"
          variant="contained"
          onClick={() => {
            handleRevertToAdmin();
            navigate(`/user/${user._id}`);
          }}
        >
          Revert to Admin
        </AuthBannerButton>
      </AuthBannerDiv>
    </AuthBannerContainer>
  );
};

export default AuthBanner;
