import providesTagsHelper from './helpers/providesTags';

export default function getCompaniesEndpoints(builder) {
  return {
    listCompanies: builder.query({
      query: () => '/companies',
      providesTags: (result) => providesTagsHelper({ type: 'Company', result }),
    }),
    getCompany: builder.query({
      query: (companyId) => `/companies/${companyId}`,
      providesTags: (result) => providesTagsHelper({ type: 'Company', result }),
    }),
    createCompany: builder.mutation({
      query: (body) => ({
        url: '/companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company', 'Organization'],
    }),
    updateCompany: builder.mutation({
      query: ({ companyId, body }) => ({
        url: `/companies/${companyId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'Company',
        'Organization',
        'CompanySetting',
        'AgilityAssessmentStatistics',
        'AgilityAssessmentAnalysis',
      ],
    }),
    updateCompanyCourse: builder.mutation({
      query: ({ companyId, courseId, totalSeats }) => ({
        url: `/companies/${companyId}/courses/${courseId}`,
        method: 'PUT',
        body: { totalSeats },
      }),
      invalidatesTags: ['Company'],
    }),
    deleteCompany: builder.mutation({
      query: (companyId) => ({
        url: `/companies/${companyId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company', 'Organization'],
    }),
    getCompanyTeams: builder.query({
      query: (companyId) => `/companies/${companyId}/teams`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyTeam', result }),
    }),
    listCompanyUsers: builder.query({
      query: ({ companyId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/users?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanyUser', result: result?.docs || [] }),
    }),
    getStudentProgress: builder.query({
      query: ({ companyId, teamId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();

        let url = `/companies/${companyId}`;
        if (teamId) url = `${url}/teams/${teamId}`;
        url = `${url}/progress?${queryString}`;

        return url;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    getCompanyReport: builder.query({
      // optionally takes a courseId and either gets the full report or by course if it's provided
      query: ({ companyId, courseId }) => ({
        url: `/companies/${companyId}/courses/${
          courseId && courseId !== 'all' ? `${courseId}/` : ''
        }report?type=export`,
        responseHandler: (response) => response.text(),
      }),
    }),
    getExamAnalyis: builder.query({
      query: ({ companyId, teamId, courseId }) => {
        let url = `/companies/${companyId}`;
        if (teamId) url = `${url}/teams/${teamId}`;
        url = courseId ? `${url}/courses/${courseId}` : `${url}/courses`;
        return `${url}/report?type=exam`;
      },
    }),
    addCompanyTeam: builder.mutation({
      query: ({ companyId, name }) => ({
        url: `/companies/${companyId}/teams`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    addTeamCourses: builder.mutation({
      query: ({ companyId, teamId, courseIds, totalSeats }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses`,
        method: 'POST',
        body: { courseIds, totalSeats },
      }),
      invalidatesTags: ['CompanyTeam', 'Company'],
    }),
    deleteCompanyTeam: builder.mutation({
      query: ({ teamId, companyId }) => ({
        url: `/companies/${companyId}/teams/${teamId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyTeam'],
    }),
    updateTeamCourseSeats: builder.mutation({
      query: ({ companyId, teamId, courseId, seatAdjustment, operation }) => ({
        url: `/companies/${companyId}/teams/${teamId}/courses/${courseId}`,
        method: 'PUT',
        body: { seatAdjustment, operation },
      }),
      invalidatesTags: ['CompanyTeam', 'Company', 'Team'],
    }),
    addCompanyUser: builder.mutation({
      query: ({ companyId, userId, teamId, role, user }) => ({
        url: `/companies/${companyId}/users`,
        method: 'POST',
        body: {
          userId,
          role,
          // teamId can be optional (for company admins)
          ...(teamId && {
            teamId,
          }),
          // a user with username, firstName, and lastName are required if the user doesn't exist
          ...(user && {
            user,
          }),
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser', 'Team', 'Playlist'],
    }),
    removeCompanyUser: builder.mutation({
      query: ({ companyId, userId, teamId = null }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
        body: { teamId },
      }),
      invalidatesTags: ['Company', 'CompanyUser', 'Team', 'Playlist'],
    }),
    getCompanyUser: builder.query({
      query: ({ companyId, userId }) =>
        `/companies/${companyId}/users/${userId}`,
    }),
    updateCompanyUser: builder.mutation({
      query: ({ companyId, userId, teamId, role }) => ({
        url: `/companies/${companyId}/users/${userId}`,
        method: 'PUT',
        body: {
          role,
          teamId,
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser', 'CompanyTeam', 'Team'],
    }),
    transferUsersTeam: builder.mutation({
      query: ({ users, companyId, teamId }) => ({
        url: `/companies/${companyId}/teams/${teamId}/transfer`,
        responseHandler: (response) => response.text(),
        method: 'PUT',
        body: {
          users,
        },
      }),
      invalidatesTags: ['CompanyUser', 'CompanyTeam', 'Company'],
    }),
    addCompanyCourse: builder.mutation({
      query: ({ companyId, courseId, totalSeats = 0 }) => ({
        url: `/companies/${companyId}/courses`,
        method: 'POST',
        body: {
          courseId,
          totalSeats,
        },
      }),
      invalidatesTags: ['Company', 'CompanyUser', 'CompanyTeam'],
    }),
    getCoursesList: builder.query({
      query: ({ type }) => ({
        url: `/courses?type=${type}`,
        method: 'GET',
      }),
      providesTags: (result) => providesTagsHelper({ type: 'Course', result }),
    }),
    listCompanyCourses: builder.query({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/courses`,
        method: 'GET',
      }),
    }),
    listSurveyResponses: builder.query({
      query: ({
        organizationId,
        companyId,
        teamId,
        courseId,
        dateStart,
        dateEnd,
        marketingOptIn,
        feedbackOnly,
        limit,
        page,
      }) => {
        const searchParams = new URLSearchParams({
          ...(courseId && { courseId }),
          ...(dateStart && { dateStart }),
          ...(dateEnd && { dateEnd }),
          ...(marketingOptIn && { marketingOptIn }),
          ...(feedbackOnly && { feedbackOnly }),
          ...(limit && { limit }),
          // offset page by one to account for 0 index
          ...(page && { page: page - 1 }),
        }).toString();

        let url;
        if (organizationId) {
          url = `/organizations/${organizationId}/surveyResponses?${searchParams}`;
        } else {
          url = `/companies/${companyId}`;
          if (teamId) url = `${url}/teams/${teamId}`;
          url = `${url}/surveyResponses?${searchParams}`;
        }

        return { url };
      },
      providesTags: (result) =>
        providesTagsHelper({ type: 'SurveyResponse', result }),
    }),
    listCompanySessions: builder.query({
      query: (companyId) => ({
        url: `/companies/${companyId}/sessions`,
        method: 'GET',
      }),
    }),
    getCompanyAchievementStatus: builder.query({
      query: ({ companyId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/achievementStatus?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    getCompanyAchievementType: builder.query({
      query: ({ companyId, ...query }) => {
        const queryString = new URLSearchParams(query).toString();
        return `/companies/${companyId}/achievementType?${queryString}`;
      },
      keepUnusedDataFor: 300, // 5 minutes
    }),
    listNonOrgCompanies: builder.query({
      query: () => ({
        url: '/companies/nonOrg',
        method: 'GET',
      }),
      providesTags: (result) =>
        providesTagsHelper({ type: 'NonOrgCompany', result }),
    }),
    listCompanySettings: builder.query({
      query: (companyId) => ({
        url: `/companies/${companyId}/settings`,
        method: 'GET',
      }),
      providesTags: (result) =>
        providesTagsHelper({ type: 'CompanySetting', result }),
    }),
    generateAssessmentId: builder.mutation({
      query: ({ companyId, assessmentId }) => ({
        url: `/companies/${companyId}/assessment/${assessmentId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Company'],
    }),
    mergeCompanies: builder.mutation({
      query: ({ companyId, companyIds }) => ({
        url: `/companies/${companyId}/merge`,
        method: 'POST',
        body: { companyIds },
      }),
      invalidatesTags: ['Company', 'Organization'],
    }),
    listSubscriptions: builder.query({
      query: ({ companyId }) => ({
        url: `/companies/${companyId}/subscriptions`,
        method: 'GET',
      }),
      providesTags: ['CompanySubscription'],
    }),
    manageSubscriptions: builder.mutation({
      query: ({ companyId, returnUrl }) => ({
        url: `/companies/${companyId}/subscriptions/manage`,
        method: 'POST',
        body: { returnUrl },
      }),
    }),
    subscribeToSubscription: builder.mutation({
      query: ({ companyId, priceId, productId, returnUrl }) => ({
        url: `/companies/${companyId}/subscriptions/subscribe`,
        method: 'POST',
        body: { priceId, productId, returnUrl },
      }),
    }),
    updateSubscriptionAdmin: builder.mutation({
      query: ({ companyId, adminId }) => ({
        url: `/companies/${companyId}/subscriptions/update-admin`,
        method: 'POST',
        body: { adminId },
      }),
      invalidatesTags: ['CompanyUser', 'Company'],
    }),
    getHasActiveSubscription: builder.query({
      query: (companyId) => ({
        url: `/companies/${companyId}/subscriptions/has-active`,
        method: 'GET',
      }),
    }),
    updateSurveySummary: builder.mutation({
      query: ({ companyId, surveyId, summary }) => ({
        url: `/companies/${companyId}/assessment/${surveyId}/summary`,
        method: 'PUT',
        body: {
          summary,
        },
      }),
      invalidatesTags: ['Company'],
    }),
    getTrainingMetrics: builder.query({
      query: (companyId) => ({
        url: `/companies/${companyId}/training-metrics`,
        method: 'GET',
      }),
    }),
  };
}
