import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, Button, Typography, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

import ScrumToolTip from 'components/ScrumToolTip';

const TOOLTIP_MESSSAGE = {
  trainer:
    'Your trainer credential is up for renewal. Please take the student exams to stay up to date with the student experience.',
  student:
    'Thank you for your renewal payment. Finish the renewal process by taking your exam now.',
};

const BannerContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  margin: 0,
  padding: 0,
  height: '100%',
}));

export default function RenewalExamBanner({ role }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const tooltipMessage = TOOLTIP_MESSSAGE[role] || '';

  return (
    <Toolbar
      disableGutters
      variant="dense"
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
      }}
    >
      <BannerContainer container alignItems="center" justifyContent="center">
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
          <ScrumToolTip tooltipMessage={tooltipMessage} />
        </Grid>
        <Grid item sx={{ mr: 1 }}>
          <Typography>
            <strong>Complete Your Renewal</strong>
            <Typography sx={{ display: { xs: 'none', md: 'inline' } }}>
              {': '}
              Your renewal exams are ready on your dashboard.
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {pathname !== '/student' && (
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate('/student')}
            >
              Go To Dashboard
            </Button>
          )}
        </Grid>
      </BannerContainer>
    </Toolbar>
  );
}
