const en = require('./en/interface/index')
const ja = require('./ja/interface/index')
const es = require('./es/interface/index')
const it = require('./it/interface/index')
const pt = require('./pt/interface/index')
const de = require('./de/interface/index')
const tw = require('./tw/interface/index')
const zh = require('./zh/interface/index')
const tr = require('./tr/interface/index')

module.exports = {
    en: en,
    ja: ja,
    es: es,
    de: de,
    it: it,
    pt: pt,
    tw: tw,
    zh: zh,
    tr: tr,
}
