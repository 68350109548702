module.exports =
    {
        language: "日本語",
        username: "ユーザ名（メールアドレス）",
        password: "パスワード",
        home: "ダッシュボード",
        welcome: "ようこそ",
        tos: 'Terms of Service',
        pp: 'Privacy Policy',
        sessions: "セッション",
        newSession: 'New Class',
        courses: "コース",
        people: "ユーザー",
        groups: "チーム",
        needAccount: "アカウントが必要ですか?",
        registerHere: " 登録はこちらから",
        reports: "レポート",
        login: "ログイン",
        loginMessage: "スクラムオンラインプラットフォームにアクセスするには、メールアドレスとパスワードを入力してください。",
        forgotPassword: "パスワードをお忘れですか？",
        privacyPolicy: "個人情報保護方針",
        viewCourse: "コースを見る",
        recent: "最近",
        viewCredential: "資格情報を表示",
        modules: "モジュール",
        logout: "ログアウト",
        help: "ヘルプ",
        firstName: "名前",
        lastName: "苗字",
        yourName: "あなたの名前",
        email: "メール",
        confirm: "確認",
        register: "登録",
        myAchievements: "達成実績",
        settings: "アカウント",
        passwordRequirements: "パスワードは、少なくとも1つの大文字アルファベット、小文字アルファベットおよび数字を含み、8文字以上、20文字以下である必要があります",
        passed: "合格！",
        checkResultsBelow: "結果は以下をご確認ください。",
        downloadCredential: "資格証明書をダウンロードする",
        returnToDashboard: "ダッシュボードに戻る",
        billingLink: "Billing",
        resetInstructions: "Enter your username (typically your email) and we will send you instructions to reset your password.",
        resetSuccess: "Success! Please check your email If there was a user account associated with this email you will receive instructions on how to reset your password.",
        reset: "Reset",
        createAccount: "アカウントを作成するために以下の情報を入力してください。Invaite Codeはインストラクターから提供されたコードを使用してください",
        selectCountry: "国の選択",
        selectPreferredLanguage: "言語の選択",
        inviteCode: "Invite Code",
        acceptTerms1: "以下について確認し、同意します",
        acceptTerms2: " および ",
        newsletterCheckbox: "Scrum Inc.のニュースレター配信を希望する",
        alreadyHaveAccount: "既にアカウントをお持ちの場合は、ここからログイン",
        dashboard: "Dashboard",
        visitMyAchievements: "To view your course material and/or take your Scrum Inc. Scrum Exam, please click on the relevant class tiles below. Visit the My Achievements page to download your previously earned credentials.",
        registerWithCode: "コースコードの登録",
        rateInstructor: "Rate your instructor",
        rateExperience: "How would you rate your learning experience with [instructor name]",
        instructorFeedback: "What did [instructor name] do well? What could [instructor name] do to improve the learning experience? NOTE: This feedback will be completely anonymous to your trainer.",
        additionalFeedback: "Additional Feedback (Optional)",
        returnDashboard: "Return to Dashboard",
        submit: "Submit",
        lsmDescription: "Our 2-day in-person Scrum Inc. Scrum Master course is an introduction to the Scrum framework. It looks at the Scrum Master role in detail. This online component of the course contains a certification test. Upon passing your test you will be a Scrum Inc. Scrum Master and you will be able to download your official credential.",
        lsmExam: "Scrum Inc. Scrum Master Exam",
        files: "Files",
        noCertificates: "You do not have any credentials... yet! Once you complete and pass one of our Scrum Inc. Scrum exams you'll find your downloadable credentials here.",
        selectLanguage: "Select Language Preference",
        save: "Save",
        uploadImage: "Upload Image",
        profilePicture: "Profile Picture",
        userInfo: "User Information",
        socialsBio: "Socials and Bio",
        website: "Website",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Biography",
        changePassword: "Change Password",
        newPassword: "New Password",
        changeOptIn: "Change Opt-Ins",
        confirmNew:"Confirm New Password",
        congratulationsMessage: "Congratulations! You passed! You will receive a credential in your email shortly. You can review your results now.",
        directoryOptIn: "Directory Opt-In",
        certificantDirectoryMessage: "I'd like to be added to the International Register of Agile Professionals",
        close: "閉じる",
        correct: "Correct",
        viewResults: "You can view your results below",
        downloadCert: "Download Credential",
        questionNumber: "Question",
        currentPassword: "Current Password",
        certificantDirectory: "International Register of Agile Professionals",
        validSince: "Valid Since:",
        lastRenewed: "Last Renewed:",
        expires: "Expires:",
        renew: "Renew",
        download: "Download",
        newsletter: "Scrum Inc. Scrum Newsletter & email updates",
        renewCredential: "Renew Credential",
        renewalNotice: "To renew your credential you must pay the renewal fee. You will then be able to take a renewal exam and, upon passing the exam, your credential will be renewed and valid for 1 year. You can pay your renewal fee using our secure checkout below.",
        registerClass: "Register a Class",
        renewNow: "Renew Now",
        secureCheckout: "Secure checkout powered by Stripe",
        enterInviteCode: "Please enter your class invite code or invite url to register.",
        lspoDescription: "Our 2-day in-person Scrum Inc. Scrum Product Owner course covers Product Owner role in detail. This online component of the course contains a certification test. Upon passing your test you will be a Scrum Inc. Scrum Product Owner and you will be able to download your official credential.",
        attemptsLeft: "attempts left",
        reviewAnswers: "Please review your answers and make any changes before you submit your exam",
        didNotPass: "You did not pass the exam.",
        didNotPassOneLeft: "You did not pass the exam. You have one attempt left.",
        failed: "Failed",
        errorMessage: "Something went wrong. Please contact us at online-learning@scruminc.com",
        retakeExam: "Retake Exam",
        noMoreAttempts: "You have run out of attempts to take this exam. Please contact your instructor to receive more attempts.",
        contactUs: "Contact Us",
        subject: "Subject",
        message: "Message",
        enterVirtualClassroom: "Enter Virtual Classroom",
        myStudentView: "My Student View",
        examAnalysis: "Exam Analysis",
        adminDashboard: "Admin Dashboard",
        partners:"Partners",
        supportSuccess: "Support ticket created successfully. Our support hours are 10AM-6PM EST. We will respond as soon as possible. Thank you!",
        visitMyAchievementsTile: "資格情報および更新（達成実績ページ）",
        total: "total",
        dueDate: "Due Date",
        action: "Action",
        description: "Description",
        status:"Status",
        pay: 'Pay',
        receipt: "Receipt",
        pastDue: "Past Due",
        open: "Open",
        paid: "Paid",
        warning: "Warning",
        pleaseBilling: "Please fill out your billing information on the settings page",
        all: "All",
        thisWeek: "This Week",
        nextWeek: "Next Week",
        laterThisMonth: "Later This Month",
        futureClasses: "Future Classes",
        recentClasses: "Recent Classes",
        back: "Previous",
        next: "Next",
        billingInformation: "Billing Information",
        billingReceiptInfo: "This information will be displayed on any invoice receipts.",
        billingReceiptInfo2: "If you need the invoice receipt or payment link to be sent to any other email address (YOU will always receive a receipt to your own registered email) you can add them here. Multiples should be separated by a comma. Ex. jane@doe.com,john@doe.com",
        viewModule: "モジュールを見る",
        enterAValidEmail: "Please enter a valid email",
        invalidResetLink: "Invalid reset link.",
        passwordReset: "Password Reset",
        returnToLogin: "Return to Login",
        success: "Success",
        confirmRemoveClass: "Confirm Remove Class",
        areYouSureRemoveClass: "Are you sure you want to remove this class? This will make the invite code unusable and will remove access from all the currently registered students.",
        cancel: "Cancel",
        delete: 'Delete',
        associatedCourses: 'Associated Courses',
        content: 'Content',
        addContent: 'Add Content',
        name: 'Name',
        filesAndResources:'Files and Resources',
        classStartDate: 'Class Start Date',
        classEndDate: 'Class End Date',
        clients: 'Clients',
        selectClient: 'Select Client...',
        copy: 'copy',
        courseRegistrationUrl: 'Course Registration URL',
        courseWebpageLink: "Enter your course webpage link",
        selfSignupUrl: "Self Signup Url",
        inviteCodeExpiry: "Invite Code Expiry Date",
        instructors: 'Instructors',
        location: 'Location',
        maxUsers: 'Student Count',
        privateSession: 'Private Session',
        removeClass: 'Remove Class',
        viewUser: 'View User',
        virtualSession: 'Virtual Session',
        virtualClassroomLink: 'Virtual Classroom Link',

    }
