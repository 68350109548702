import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Drawer,
  SwipeableDrawer,
  List,
  Toolbar,
  Hidden,
} from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';

import { AppBarContext } from 'router/navContext';
import { selectCurrentUser } from 'features/auth/slice';
import AdminListItems from './AdminListItems';
import TrainerListItems from './TrainerListItems';
import AssistantListItems from './AssistantListItems';
import StudentListItems from './StudentListItems';

const drawerWidth = 250;

const DrawerList = styled(Box)(({ theme, appbarheight }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    top: appbarheight,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default function UserDrawer({ open = false, onClose = () => {} }) {
  const location = useLocation();
  const user = useSelector(selectCurrentUser);
  const appBarHeight = React.useContext(AppBarContext);

  const handleClose = () => onClose();

  // Suppress transition on non-iOS devices to prevent lag
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Display the drawer as permanently open on the student page for non-mobile
  const variant =
    location?.pathname === '/student' && user.role === 'student'
      ? 'permanent'
      : 'temporary';

  const drawer = (
    <DrawerList
      role="presentation"
      onClick={onClose}
      appbarheight={appBarHeight}
    >
      {/* The drawer clips behind the top header nav, so we need to offset it properly */}
      <Toolbar sx={{ height: appBarHeight }} />
      <List>
        <AdminListItems />
        {['admin', 'trainer'].includes(user?.role) && <TrainerListItems />}
        {user?.role === 'assistant' && <AssistantListItems />}
        <StudentListItems role={user?.role} features={user?.features} />
      </List>
    </DrawerList>
  );

  return (
    <>
      <Hidden smUp implementation="js">
        {/* Always have Drawer be temporary for mobile devices */}
        <SwipeableDrawer
          anchor="left"
          open={open}
          onOpen={() => {}}
          onClose={handleClose}
          variant="temporary"
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="js">
        {/* For non-mobile, drawer is not swipeable and shows as permanent on student dashboard */}
        <Drawer
          anchor="left"
          open={open}
          onClose={handleClose}
          variant={variant}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}
