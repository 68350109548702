import {
  createSlice,
  // createSelector
} from '@reduxjs/toolkit';

const initialState = {
  deletePartner: {
    isOpen: false,
    partner: null,
  },
  directoryFilters: {
    country: null,
    region: null,
    name: '',
    partnerType: { value: 'All Partners', label: 'All Partners' },
  },
  transfer: {
    user: null,
    isOpen: false,
  },
  update: { user: null, isOpen: false },
};

export const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    toggleDeletePartner: (state, { payload: { isOpen, partner } }) => {
      if (isOpen) {
        state.deletePartner.isOpen = true;
        state.deletePartner.partner = partner;
      } else {
        state.deletePartner.isOpen = false;
        state.deletePartner.partner = null;
      }
    },
    setDirectoryFilterCountry: (state, { payload = null }) => {
      state.directoryFilters.country = payload;
    },
    setDirectoryFilterRegion: (state, { payload = null }) => {
      state.directoryFilters.region = payload;
    },
    setDirectoryFilterName: (state, { payload = '' }) => {
      state.directoryFilters.name = payload;
    },
    setDirectoryFilterPartnerType: (
      state,
      { payload = initialState.directoryFilters.partnerType }
    ) => {
      state.directoryFilters.partnerType = payload;
    },
    resetDirectoryFilters: (state) => {
      state.directoryFilters = initialState.directoryFilters;
    },
    toggleTransferDialog: (
      state,
      { payload: { isOpen = false, user = null } = {} }
    ) => {
      state.transfer.isOpen = isOpen;
      state.transfer.user = user;
    },
    toggleUpdateDialog: (
      state,
      { payload: { isOpen = false, user = null, partner = null } = {} }
    ) => {
      state.update.isOpen = isOpen;
      state.update.user = user;
      state.update.partner = partner;
    },
  },
});

export const {
  toggleDeletePartner,
  setDirectoryFilterCountry,
  setDirectoryFilterName,
  setDirectoryFilterPartnerType,
  setDirectoryFilterRegion,
  resetDirectoryFilters,
} = partnersSlice.actions;

export const actions = partnersSlice.actions;
export default partnersSlice.reducer;

export const selectDeletePartner = (state) => state.partners.deletePartner;
export const selectDirectoryFilters = (state) =>
  state.partners.directoryFilters;
export const selectTransfer = (state) => state.partners.transfer;
export const selectUpdate = (state) => state.partners.update;
