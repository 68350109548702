import React from 'react';
import { Typography, Container } from '@mui/material';
import MaintenanceIcon from '@mui/icons-material/Build';
import { styled } from '@mui/system';
import MaintenanceHeader from './MaintenanceHeader';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, selectCurrentUser } from 'features/auth/slice';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  textAlign: 'center',
}));

const StyledIcon = styled(MaintenanceIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.secondary.main,
}));

const MaintenanceScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const currentUser = useSelector(selectCurrentUser);

  React.useEffect(() => {
    if (currentUser) {
      dispatch(logoutUser());
    }
  }, [dispatch, currentUser]);
  return (
    <StyledContainer maxWidth="sm">
      <MaintenanceHeader />
      <StyledIcon />
      <Typography variant="h4" gutterBottom>
        {t('underMaintenance')}
      </Typography>
      <Typography variant="subtitle1">{t('maintenanceMessage')}</Typography>
    </StyledContainer>
  );
};

export default MaintenanceScreen;
