export const GET_SESSIONS = 'GET_SESSIONS';
export const SET_SESSIONS = 'SET_SESSIONS';
export const UPDATE_SESSIONS = 'UPDATE_SESSIONS';
export const SET_SESSION = 'SET_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UNSET_SESSION = 'UNSET_SESSION';
export const RESET_QUIZ = 'RESET_QUIZ';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const ADD_USER_TO_SESSION = 'ADD_USER_TO_SESSION';
export const REMOVE_USER_FROM_SESSION = 'REMOVE_USER_FROM_SESSION';
export const UPDATE_UNLOCKED_CONtENT = 'UPDATE_UNLOCKED_CONTENT';
