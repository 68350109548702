import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = (props) => {
  return (
    <Box {...props}>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#7e35CC" />
            <stop offset="100%" stopColor="#CC0000" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }}
      />
    </Box>
  );
};

export default Loading;
