import { api } from './';
import providesTagsHelper from './helpers/providesTags';

export default function sessionEndpoints(builder) {
  return {
    getSessions: builder.query({
      query: (query) => `/sessions${query ? `?${query}` : ''}`,
      providesTags: (result) => providesTagsHelper({ type: 'Session', result }),
    }),
    createSession: builder.mutation({
      query: (session) => ({
        url: '/sessions',
        method: 'POST',
        body: session,
      }),
      invalidatesTags: ['Session'],
    }),
    getSession: builder.query({
      query: (id) => `/sessions/${id}`,
      providesTags: (result) =>
        result ? [{ type: 'Session', id: result._id }] : [],
    }),
    updateSession: builder.mutation({
      query: (session) => ({
        url: `/sessions/${session._id}`,
        method: 'POST',
        body: session,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg._id },
      ],
    }),
    removeSession: builder.mutation({
      query: ({ id, cancellationReason = null }) => ({
        url: `/sessions/remove/${id}`,
        method: 'POST',
        body: {
          cancellationReason,
        },
      }),
      invalidatesTags: ['Session'],
    }),
    addSessionContent: builder.mutation({
      query: ({ id, moduleType = 'FileModule', sectionModuleId = null }) => ({
        url: `/sessions/add_content/${id}`,
        method: 'POST',
        body: {
          moduleType,
          sectionModuleId,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.id },
      ],
    }),
    removeSessionContent: builder.mutation({
      query: ({ sessionId, moduleType, moduleId, sectionModuleId }) => ({
        url: `/sessions/${sessionId}/${moduleType}/${moduleId}`,
        method: 'DELETE',
        body: { sectionModuleId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    unlockSessionContent: builder.mutation({
      query: ({ id, content: { unlocked, modules } }) => ({
        url: `/sessions/unlock_content/${id}`,
        method: 'POST',
        body: {
          unlocked,
          modules,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.id },
      ],
      async onQueryStarted(
        { id, content: { unlocked, modules } },
        { dispatch, queryFulfilled }
      ) {
        const updateResult = dispatch(
          api.util.updateQueryData('getSession', id, (draft) => {
            const unlockedModules = unlocked
              ? [...draft.unlocked_modules, ...modules]
              : draft.unlocked_modules.filter((m) => !modules.includes(m));
            Object.assign(draft, {
              unlocked_modules: unlockedModules,
            });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
    }),
    updateModulesOrder: builder.mutation({
      query: ({ id, modulesOrder }) => ({
        url: `/sessions/${id}/order`,
        method: 'PUT',
        body: modulesOrder,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.id },
      ],
    }),
    updateSectionOrder: builder.mutation({
      query: ({ sessionId, sectionId, order }) => ({
        url: `/sessions/${sessionId}/sections/${sectionId}/order`,
        method: 'PUT',
        body: order,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    removeUserFromSession: builder.mutation({
      query: ({ sessionId, userId, reason = 'unknown' }) => ({
        url: `/sessions/remove_user_from_session/${sessionId}/${userId}`,
        method: 'POST',
        body: { reason },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    setSessionComplete: builder.mutation({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}/complete`,
        method: 'POST',
      }),
      invalidatesTags: ['Achievement'],
    }),
    moveSessionModule: builder.mutation({
      query: ({ sessionId, moduleId, sourceId, destinationId, order }) => ({
        url: `/sessions/${sessionId}/modules/${moduleId}/move`,
        method: 'PUT',
        body: {
          sourceId,
          destinationId,
          order,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    renameSessionSection: builder.mutation({
      query: ({ sessionId, sectionId, name }) => ({
        url: `/sessions/${sessionId}/sections/${sectionId}/rename`,
        method: 'PUT',
        body: {
          name,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    createStudentCheckoutSession: builder.mutation({
      query: ({ sessionId, returnUrl }) => ({
        url: `/sessions/${sessionId}/student-checkout`,
        method: 'POST',
        body: {
          returnUrl,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Session', id: arg.sessionId },
      ],
    }),
    getUpcomingSessions: builder.query({
      query: (query) => `/sessions/upcoming${query ? `?${query}` : ''}`,
    }),
    getSessionAgileSurvey: builder.query({
      query: ({ sessionId, userId }) =>
        `/sessions/${sessionId}/agile-surveys/${userId}`,
      providesTags: ['SessionAgileSurvey'],
    }),
    listSessionAgileSurveys: builder.query({
      query: (sessionId) => `/sessions/${sessionId}/agile-surveys`,
    }),
    sendBulkReminderEmails: builder.mutation({
      query: ({ session, users, note }) => ({
        url: `/sessions/${session}/send-reminder-emails`,
        method: 'POST',
        body: {
          users,
          note,
        },
      }),
    }),
  };
}
