import axios from 'axios';

import { api } from './';
import providesTags from './helpers/providesTags';

export default function getContentLibraryEndpoints(builder) {
  return {
    listContentLibrary: builder.query({
      query: () => `/content-library`,
      providesTags: (result) =>
        providesTags({ type: 'ContentLibrary', result }),
    }),
    getContentLibrary: builder.query({
      query: (id) => `/content-library/${id}`,
      providesTags: (result) =>
        providesTags({ type: 'ContentLibrary', result }),
    }),
    createContentLibrary: builder.mutation({
      query: (body) => ({
        url: `/content-library`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContentLibrary'],
    }),
    updateContentLibrary: builder.mutation({
      query: ({ id, ...updates }) => ({
        url: `/content-library/${id}`,
        method: 'PUT',
        body: updates,
      }),
      invalidatesTags: ['ContentLibrary'],
    }),
    updateContentLibraryOrder: builder.mutation({
      query: (body) => ({
        url: `/content-library/order`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(order, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('listContentLibrary', undefined, (draft) => {
            draft.forEach((category) => {
              const newOrder = order[category._id];
              if (newOrder !== undefined) {
                category.order = newOrder;
              }
            });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
      invalidatesTags: ['ContentLibrary'],
    }),
    deleteContentLibrary: builder.mutation({
      query: (id) => ({
        url: `/content-library/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContentLibrary'],
    }),
    updateCategoryModulesOrder: builder.mutation({
      query: ({ id, order }) => ({
        url: `/content-library/${id}/order`,
        method: 'PUT',
        body: order,
      }),
      async onQueryStarted({ id, order }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('listContentLibrary', undefined, (draft) => {
            const category = draft.find((category) => category._id === id);
            category.items.forEach((item) => {
              const newOrder = order[item._id];
              if (newOrder !== undefined) {
                item.order = newOrder;
              }
            });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
      invalidatesTags: ['ContentLibrary'],
    }),
    removeCategoryModule: builder.mutation({
      query: ({ id, moduleId }) => ({
        url: `/content-library/${id}/modules/${moduleId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, moduleId }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          api.util.updateQueryData('listContentLibrary', undefined, (draft) => {
            const category = draft.find((category) => category._id === id);
            category.items = category.items.filter(
              (item) => item._id !== moduleId
            );
          })
        );
        try {
          await queryFulfilled;
        } catch {
          updateResult.undo();
        }
      },
      invalidatesTags: ['ContentLibrary'],
    }),
    searchModules: builder.query({
      query: ({ moduleType }) =>
        `/content-library/modules/search?moduleType=${moduleType}`,
    }),
    getCategoryModule: builder.query({
      query: ({ categoryId, moduleId }) =>
        `/content-library/${categoryId}/modules/${moduleId}`,
    }),
    addCategoryModule: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/content-library/${id}/modules`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContentLibrary'],
    }),
    updateCategoryModule: builder.mutation({
      query: ({ categoryId, moduleId, ...body }) => ({
        url: `/content-library/${categoryId}/modules/${moduleId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ContentLibrary'],
    }),
    uploadThumbnail: builder.mutation({
      async queryFn(
        { moduleId, moduleType, file, onUploadProgress = () => {} },
        _api,
        _extraOptions,
        fetchWithBQ
      ) {
        const extension = file.name.split('.').pop();
        const name = `${moduleId}.${extension}`;

        const response = await fetchWithBQ({
          url: '/content-library/modules/thumbnail/upload/start',
          method: 'POST',
          body: {
            name,
            type: file.type,
          },
        });

        const axiosInstance = axios.create({ headers: {} });

        await axiosInstance.put(response.data.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress,
        });

        const module = await fetchWithBQ({
          url: `/content-library/modules/thumbnail/upload/finish`,
          method: 'POST',
          body: {
            url: response.data.url,
            moduleId,
            moduleType,
          },
        });

        if (module) {
          return { data: module };
        } else {
          return { error: response.error };
        }
      },
      invalidatesTags: ['ContentLibrary'],
    }),
  };
}
