module.exports =
    {
        language: "Deutsch",
        username: "Benutzername",
        password: "Passwort",
        welcome: "Wilkommen",
        tos: 'Nutzungsbedingungen',
        pp: 'Datenschutzrichtlinie',
        home: "Dashboard",
        sessions: "Kurse",
        newSession: 'Neuer Kurs',
        courses: "Kursarten",
        people: "Schüler",
        groups: "Kunden",
        needAccount: "Zugang benötigt?",
        registerHere: " Hier registrieren",
        reports: "Berichte",
        login: "Login",
        loginMessage: "Bitte geben Sie Ihre E-Mail-Adresse ein, um auf die Scrum Onlineplattform zugreifen zu können.",
        forgotPassword: "Passwort vergessen?",
        privacyPolicy: "Datenschutzrichtlinie",
        viewCourse: "Kursunterlagen & Prüfung",
        recent: "In letzter Zeit",
        viewCredential: "Zertifikat ansehen",
        modules: "Module",
        logout: "Logout",
        help: "Hilfe",
        firstName: "Vorname",
        lastName: "Nachname",
        yourName: "Ihr Name",
        email: "E-Mail",
        confirm: "Bestätigen",
        register: "Registrieren",
        myAchievements: "Meine Errungenschaften",
        settings: "Einstellungen",
        passwordRequirements: "Ihr Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl beinhalten, sowie zwischen 8 und 20 Zeichen lang sein",
        passed: "Bestanden!",
        checkResultsBelow: "Sie können Ihr Ergebnis unten ansehen",
        downloadCredential: "Zertifikat herunterladen",
        returnToDashboard: "Zum Dashboard zurück",
        billingLink: "Rechnungserstellung",
        resetInstructions: "Bitte geben Sie Ihren Benutzernamen ein (normalerweise Ihre E-Mail) und wir schicken Ihnen eine Anleitung, wie Sie Ihr Passwort zurücksetzen können.",
        resetSuccess: "Erfolg! Bitte überprüfen Sie Ihre E-Mail. Wenn ein Nutzerkonto mit dieser E-Mail-Adresse verknüpft ist, erhalten Sie in Kürze eine Anleitung, wie Sie Ihr Passwort zurücksetzen können.",
        reset: "Zurücksetzen",
        createAccount: "Bitte geben Sie Ihre Informationen und den Zugangscode, den Sie von Ihrem Trainer erhalten haben, an, um ein Nutzerkonto anzulegen",
        selectCountry: "Land auswählen",
        selectPreferredLanguage: "Bevorzugte Sprache auswählen",
        inviteCode: "Zugangscode",
        acceptTerms1: "Ich habe die ",
        acceptTerms2: " gelesen und akzeptiert, wie auch die ",
        newsletterCheckbox: "Ich möchte gerne den Newsletter und die neuesten Angebote von Scrum Inc. erhalten.",
        alreadyHaveAccount: "Sie haben schon ein Konto? Hier einloggen",
        dashboard: "Dashboard",
        visitMyAchievements: "Klicken Sie bitte auf die entsprechende unten stehende Kachel, um Zugang zu Ihren Kursunterlagen zu erhalten oder eine Scrum Prüfung abzulegen. Besuchen Sie Meine Errungenschaften, um Ihre bereits errungenen Zertifikate herunterzuladen.",
        registerWithCode: "Mit Ihrem Zugangscode registrieren",
        rateInstructor: "Bewerten Sie Ihren Trainer",
        rateExperience: "Wie würden Sie Ihren Kurs mit [instructor name] bewerten?",
        instructorFeedback: "Was hat [instructor name] gut gemacht? Was könnte [instructor name] verbessern? HINWEIS: Diese Feedback ist komplett anonym.",
        additionalFeedback: "Zusätzliches Feedback (optional)",
        returnDashboard: "Zurück zum Dashboard",
        submit: "Abschicken",
        lsmDescription: "Unser zweitägiger live stattfindender Scrum Master Kurs ist eine Einführung in das Scrum Framework. Er betrachtet die Rolle des Scrum Masters im Detail. Dieser Teil des Kurses umfasst eine Online-Prüfung. Mit Bestehen der Prüfung sind Sie ein Scrum Master und können Ihr offizielles Zertifikat herunterladen.",
        lsmExam: "Scrum Master Prüfung",
        files: "Dateien",
        noCertificates: "Sie haben (noch) keine Zertifikate. Sobald Sie eine unserer Scrum Prüfungen bestehen, können Sie hier Ihr(e) Zertifikat(e) herunterladen.",
        selectLanguage: "Bevorzugte Sprache auswählen",
        save: "Speichern",
        uploadImage: "Bild hochladen",
        profilePicture: "Profilbild",
        userInfo: "Nutzerinformation",
        socialsBio: "Social Media und Biographie",
        website: "Webseite",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        bio: "Biographie",
        changePassword: "Passwort ändern",
        newPassword: "Neues Passwort",
        changeOptIn: "Einwilligungen ändern",
        confirmNew:"Neues Passwort bestätigen",
        congratulationsMessage: "Herzlichen Glückwunsch! Sie haben bestanden! Sie erhalten Ihr Zertifikat zeitnah per E-Mail. Sie können jetzt Ihre Ergebnisse einsehen.",
        directoryOptIn: "Einwilligung Absolventen-Verzeichnis",
        certificantDirectoryMessage: "Ich möchte ins öffentliche Internationales Register Agile Professionals aufgenommen werden",
        close: "Schließen",
        correct: "korrekt",
        viewResults: "Sie können die Ergebnisse unten einsehen",
        downloadCert: "Zertifikat herunterladen",
        questionNumber: "Frage",
        currentPassword: "Aktuelles Passwort",
        certificantDirectory: "Internationales Register Agile Professionals",
        validSince: "Gültig seit:",
        lastRenewed: "Zuletzt erneuert:",
        expires: "Läuft aus:",
        renew: "Erneuern",
        download: "Herunterladen",
        newsletter: "Scrum Newsletter & E-Mail Updates",
        renewCredential: "Zertifikat erneuern",
        renewalNotice: "Um das Zertifikat verlängern zu können, müssen Sie die Verlängerungsgebühr bezahlen. Sie können dann die Verlängerungs-Prüfung ablegen. Bei Bestehen wird die Zertifizierung erneuert und ist für ein Jahr gültig. Sie können die Verlängerungsgebühr unten sicher bezahlen.",
        registerClass: "Einen Kurs registrieren",
        renewNow: "Jetzt verlängern",
        secureCheckout: "Sichere Zahlung mit Stripe",
        enterInviteCode: "Bitte geben Sie Ihren Kurs-Zungangscode oder den Zugangscode-Link ein, um sich zu registrieren.",
        lspoDescription: "Unser zweitägiger live stattfindender Product Owner Kurs ist eine Einführung in das Scrum Framework. Er betrachtet die Rolle des Product Owners im Detail. Dieser Teil des Kurses umfasst eine Online-Prüfung. Mit Bestehen der Prüfung sind Sie ein Scrum Product Owner und können Ihr offizielles Zertifikat herunterladen.",
        attemptsLeft: "noch übrige Versuche",
        reviewAnswers: "Bitte überprüfen Sie Ihre Antworten nochmal und nehmen Sie ggf. Änderungen vor, bevor Sie Ihre Prüfung abschließen.",
        didNotPass: "Sie haben nicht bestanden.",
        didNotPassOneLeft: "Sie haben nicht bestanden. Sie haben noch einen Versuch übrig.",
        failed: "Nicht bestanden",
        errorMessage: "Etwas ist schiefgegangen. Bitte kontaktieren Sie uns unter online-learning@scruminc.com",
        retakeExam: "Prüfung wiederholen",
        noMoreAttempts: "Sie haben für diese Prüfung keine Versuche mehr übrig. Bitte kontaktieren Sie Ihren Trainer, um weitere Versuche zu erhalten.",
        contactUs: "Kontakt",
        subject: "Betreff",
        message: "Nachricht",
        enterVirtualClassroom: "Virtuelles Klasenzimmer betreten",
        myStudentView: "Aus Kundensicht",
        examAnalysis: "Prüfungsauswertung",
        adminDashboard: "Administratoren Dashboard",
        partners:"Partner",
        supportSuccess: "Support-Ticket erfolgreich erstellt. Unser Support ist von 10:00 bis 18:00 Uhr (US-Ostküstenzeit) für Sie da. Wir antworten so schnell, wie möglich. Danke!",
        visitMyAchievementsTile: "Um Ihre Zertifikate einzusehen oder zu erneuern, besuchen Sie Meine Errungenschaften.",
        total: "gesamt",
        dueDate: "Fälligkeit",
        action: "Maßnahmen",
        description: "Beschreibung",
        status:"Status",
        pay: 'bezahlen',
        receipt: "Beleg",
        pastDue: "überfällig",
        open: "offen",
        paid: "bezahlt",
        warning: "Warnung",
        pleaseBilling: "Bitte geben Sie Ihre Rechnugnsinformationen auf der Einstellungsseite ein.",
        all: "Alle",
        thisWeek: "Diese Woche",
        nextWeek: "Nächste Woche",
        laterThisMonth: "Später diesen Monat",
        futureClasses: "Zukünftige Kurse",
        recentClasses: "Vergangene Kurse",
        back: "Zurück",
        next: "Weiter",
        billingInformation: "Rechnungsinformation",
        billingReceiptInfo: "Diese Information wird auf den Rechnungsbelegen aufgeführt werden.",
        billingReceiptInfo2: "Wenn der Rechnungsbeleg oder der Bezahllink an eine andere E-Mail gesendet werden soll, können Sie diese hier eingeben. (Sie erhalten immer einen Beleg an Ihre eigene E-Mail-Adresse.) Mehrere Empfänger können per Komma getrennt eingegeben werden. Beispiel: jane@doe.de,john@doe.de",
        viewModule: "Modul ansehen",
        enterAValidEmail: "Bitte geben Sie eine gültige E-Mail ein",
        invalidResetLink: "Der Link zum Zurücksetzen ist ungültig.",
        passwordReset: "Passwort zurücksetzen",
        returnToLogin: "Zurück zum Login",
        success: "Erfolg",
        confirmRemoveClass: "Kursentfernung bestätigen",
        areYouSureRemoveClass: "Sind Sie sich sicher, dass Sie diesen Kurs entfernen wollen? Das wird den Zugangscode unbrauchbar machen und den Zugang aller momentan registrieren Schüler entfernen.",
        cancel: "Abbrechen",
        delete: 'Löschen',
        associatedCourses: 'verbundene Kurse',
        content: 'Content',
        addContent: 'Content hinzufügen',
        name: 'Name',
        filesAndResources:'Dateien und Materialien',
        classStartDate: 'Kurs startet am',
        classEndDate: 'Kurs endet am',
        clients: 'Kunden',
        selectClient: 'Kunde auswählen...',
        copy: 'kopieren',
        courseRegistrationUrl: 'Kurs-Registrierungslink',
        courseWebpageLink: "Link zur Kursseite eintragen",
        selfSignupUrl: "Anmeldelink",
        inviteCodeExpiry: "Ablaufdatum des Zugangscodes",
        instructors: 'Trainer',
        location: 'Ort',
        maxUsers: 'Maximale Anzahl Schüler',
        privateSession: 'Privatunterricht',
        removeClass: 'Kurs entfernen',
        viewUser: 'Benutzer ansehen',
        virtualSession: 'Online Kurs',
        virtualClassroomLink: 'Link zum virtuellen Klassenzimmer',
    }
