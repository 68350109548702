import providesTagsHelper from './helpers/providesTags';

export default function htmlModules(builder) {
  return {
    getHTMLModule: builder.query({
      query: (id) => `/HTMLModule/${id}`,
      providesTags: (result) =>
        providesTagsHelper({ type: 'HTMLModule', result }),
    }),
    updateHTMLModule: builder.mutation({
      query: (HTMLModule) => ({
        url: `/HTMLModule/${HTMLModule._id}`,
        method: 'PUT',
        body: HTMLModule,
      }),
      invaldatesTags: ['HTMLModule'],
    }),
  };
}
