import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  search: '',
  page: 0,
  rowsPerPage: 15,
  order: 'asc',
  orderBy: 'full_name',
  selected: [],
  mergeUsers: {
    isOpen: false,
    preferredUser: null,
    preferredCompanyUsers: [],
    step: 0,
    isConfirmOpen: false,
  },
  addAchievement: {
    isOpen: false,
    userId: null,
    achievement: {
      name: '',
      date: moment().toISOString(),
      date_since: moment().toISOString(),
      date_expires: moment().add(1, 'year').toISOString(),
      cert_path: '',
      session: null,
      sessionName: null,
      subachievements: [],
      renewal_stage: 1,
    },
  },
  editAchievement: {
    isOpen: false,
    isModified: false,
    userId: null,
    achievement: {
      name: '',
      date: moment().toISOString(),
      date_since: moment().toISOString(),
      date_expires: moment().add(1, 'year').toISOString(),
      cert_path: '',
      session: null,
      sessionName: null,
      subachievements: [],
      renewal_stage: 1,
    },
  },
  resetPasswordDialog: false,
  activeTab: 'courses',
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      if (typeof action.payload === 'string') {
        state.search = action.payload;
      }
    },
    setPage: (state, action) => {
      if (typeof action.payload === 'number') {
        state.page = action.payload;
      }
    },
    setRowsPerPage: (state, action) => {
      if (typeof action.payload === 'number') {
        state.rowsPerPage = action.payload;
      }
    },
    setOrder: (state, action) => {
      if (['asc', 'desc'].includes(action.payload)) {
        state.order = action.payload;
      }
    },
    setOrderBy: (state, action) => {
      if (typeof action.payload === 'string') {
        state.orderBy = action.payload;
      }
    },
    setSelected: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selected = action.payload;
      }
    },
    addSelected: (state, { payload: user }) => {
      if (typeof user?._id === 'string') {
        const selectedIndex = state.selected.findIndex(
          ({ _id }) => _id === user._id
        );
        if (selectedIndex === -1) {
          state.selected.push({
            _id: user._id,
            full_name: user.full_name,
            username: user.username,
            achievements: user.achievements,
            role: user.role,
            sessions: user.sessions,
          });
        }
      }
    },
    removeSelected: (state, { payload: id }) => {
      if (typeof id === 'string') {
        const selectedIndex = state.selected.findIndex(({ _id }) => _id === id);
        if (selectedIndex >= 0) {
          state.selected.splice(selectedIndex, 1);
        }
      }
    },
    toggleMergeDialog: (state, { payload: isOpen = false }) => {
      state.mergeUsers.isOpen = typeof isOpen === 'boolean' ? isOpen : false;
    },
    toggleMergeConfirm: (state, { payload: isConfirmOpen = false }) => {
      state.mergeUsers.isConfirmOpen =
        typeof isConfirmOpen === 'boolean' ? isConfirmOpen : false;
    },
    setMergeStep: (state, { payload: step = 0 }) => {
      state.mergeUsers.step = typeof step === 'number' ? step : 0;
    },
    setMergePreferredUser: (state, { payload: user = null }) => {
      state.mergeUsers.preferredUser = user;
    },
    setMergePreferredCompanyUsers: (state, { payload: users = [] }) => {
      state.mergeUsers.preferredCompanyUsers = users;
    },
    resetMerge: (state) => {
      state.mergeUsers = {
        ...initialState.mergeUsers,
      };
    },
    setAddAchievement: (
      state,
      { payload: { isOpen = false, userId, achievement } = {} }
    ) => {
      state.addAchievement.isOpen = isOpen;
      if (isOpen) {
        state.addAchievement.userId = userId ? userId : null;
        state.addAchievement.achievement = achievement
          ? achievement
          : initialState.addAchievement.achievement;
      } else {
        state.addAchievement.userId = null;
        state.addAchievement.achievement =
          initialState.addAchievement.achievement;
      }
    },
    setAddAchievementDate: (state, { payload: { date, name } }) => {
      if (state.addAchievement.isOpen) {
        state.addAchievement.achievement[name] = date;
      }
    },
    setEditAchievement: (
      state,
      {
        payload: {
          isOpen = false,
          isModified = false,
          userId,
          achievement,
        } = {},
      }
    ) => {
      state.editAchievement.isOpen = isOpen;
      if (isOpen) {
        state.editAchievement.isModified = isModified;
        state.editAchievement.userId = userId ? userId : null;
        state.editAchievement.achievement = achievement
          ? achievement
          : initialState.editAchievement.achievement;
      } else {
        state.editAchievement.isModified = false;
        state.editAchievement.userId = null;
        state.editAchievement.achievement =
          initialState.editAchievement.achievement;
      }
    },
    setEditAchievementDate: (state, { payload: { date, name } }) => {
      if (state.editAchievement.isOpen) {
        if (state.editAchievement.achievement[name] !== date) {
          state.editAchievement.isModified = true;
        }
        state.editAchievement.achievement[name] = date;
      }
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    toggleResetPasswordDialog: (state, { payload: dialogOpen = false }) => {
      state.resetPasswordDialog = dialogOpen;
    },
  },
});

export const {
  setSearch,
  setPage,
  setRowsPerPage,
  setOrder,
  setOrderBy,
  setSelected,
  addSelected,
  removeSelected,
  toggleMergeUsers,
  setAddAchievement,
  setAddAchievementDate,
  setEditAchievement,
  setEditAchievementDate,
  toggleResetPasswordDialog,
} = usersSlice.actions;

export const actions = usersSlice.actions;

export default usersSlice.reducer;

export const selectUsers = (state) => state.users;
export const selectSearch = (state) => state.users.search;
export const selectPage = (state) => state.users.page;
export const selectRowsPerPage = (state) => state.users.rowsPerPage;
export const selectOrder = (state) => state.users.order;
export const selectOrderBy = (state) => state.users.orderBy;
export const selectSelected = (state) => state.users.selected;
export const selectMergeUsers = (state) => state.users.mergeUsers;
export const selectAddAchievement = (state) => state.users.addAchievement;
export const selectEditAchievement = (state) => state.users.editAchievement;
export const selectResetPasswordDialog = (state) =>
  state.users.resetPasswordDialog;
