import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const isProduction = process.env.REACT_APP_ENV === 'production';

export function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    if (isProduction) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  const trackEvent = (category, action, label) => {
    if (isProduction) {
      ReactGA.event({
        category,
        action,
        label,
      });
    }
  };

  return { trackEvent };
}

export default useAnalytics;
